import { Suspense, useMemo } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import { TenantProvider } from 'src/layouts/dashboard/tenant-switch/tenant-provider';
import { TRANSACATIONS_PATH } from 'src/paths';
import { OrdersReport } from 'src/pages/transactions/orders';
import { OrderDetail } from 'src/pages/transactions/orders/details/order-detail';
import { TransactionsPayments } from 'src/pages/transactions/payments';
import { TransactionsGuests } from 'src/pages/transactions/guests';
import { RoleBasedGuard } from 'src/guards/role-based-guard';

export const useTransactionRoutes = () => {
  const routes: RouteObject[] & { disabled?: boolean } = useMemo(
    () => [
      {
        path: 'transactions',
        element: (
          <TenantProvider>
            <DashboardLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          </TenantProvider>
        ),
        children: [
          {
            path: '/transactions',
            element: <Navigate to={TRANSACATIONS_PATH.orders.index} replace />
          },
          {
            path: '/transactions/orders',
            children: [
              {
                path: TRANSACATIONS_PATH.orders.index,
                element: <OrdersReport />
              },
              {
                path: TRANSACATIONS_PATH.orders.orderDetails,
                element: <OrderDetail />
              }
            ]
          },
          {
            path: TRANSACATIONS_PATH.payments.index,
            element: (
              <RoleBasedGuard permissions="admin" showIllustration>
                <TransactionsPayments />
              </RoleBasedGuard>
            )
          },
          // {
          //   path: TRANSACATIONS_PATH.buyers.index,
          //   element: (
          //     <RoleBasedGuard permissions="viwer" showIllustration>
          //       <TransactionsBuyers />
          //     </RoleBasedGuard>
          //   )
          // },
          {
            path: TRANSACATIONS_PATH.guests.index,
            element: <TransactionsGuests />
          }
        ]
      }
    ],
    []
  );

  return routes;
};
