import { Page as PageType } from 'types/template/page';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { OrderStatusType } from 'types/order';
import { LoadingContainer } from 'src/components/loading';
import { useState } from 'react';
import { Table } from 'src/@common/components/table';
import { Page } from 'src/components/page';
import { Pagination } from 'src/@common/components/pagination';
import { usePagination } from 'src/@common/components/hooks/usePagination';
import { TableFilter } from 'src/@common/components/table-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { EmptyState } from 'src/components/empty-state';
import { IPayment, IPaymentStatus } from 'types/payment';
import { useTransactionsPaymentsColumns } from 'src/pages/transactions/payments/table-columns';
import CsvDownloadButton from 'react-json-to-csv';

export const TransactionsPayments: PageType = () => {
  const [filters, setFilters] = useState<{
    search?: string;
    paymentStatus?: IPaymentStatus[];
    orderStatus?: OrderStatusType[];
  }>({
    search: undefined
  });
  const { columns } = useTransactionsPaymentsColumns();

  // force commit

  const { isLoading, data } = useQuery<IPayment[]>(['TransactionsPayments'], () => {
    return apiFetch(API_ENDPOINT.Transactions.Payments).get();
  });

  const filteredData = data?.filter((payment) => {
    if (filters.search) {
      const search = filters.search.toLowerCase();
      return (
        payment.payerEmail.toLowerCase().includes(search) ||
        payment.orderConfirmationCode?.toLowerCase().includes(search) ||
        payment.gateway.toLowerCase().includes(search) ||
        payment.cardBrand.toLowerCase().includes(search) ||
        payment.cardLast4.toLowerCase().includes(search)
      );
    }
    if (filters?.orderStatus && payment.orderStatus && filters.orderStatus.length > 0) {
      return filters.orderStatus.includes(payment.orderStatus);
    }
    if (filters?.paymentStatus && filters.paymentStatus.length > 0) {
      return filters.paymentStatus.includes(payment.status);
    }
    return true;
  });

  const { paginatedData, onNextPage, onPreviousPage, setPaginationPage, pagination } =
    usePagination<IPayment>({
      data: filteredData || [],
      rowsPerPage: 20
    });

  return (
    <>
      <Page
        title="Payments"
        description=""
        cta={
          <CsvDownloadButton
            filename={`transactions-payment_${new Date().toISOString()}.csv`}
            data={filteredData?.map((data) => ({
              'Payment ID': data.id,
              'Order ID': data.orderId,
              'Confirmation Code': data.orderConfirmationCode,
              'Payer Email': data.payerEmail,
              Status: data.status,
              Gateway: data.gateway,
              'Price (R$)': data.itemsPrice,
              'Shipping (R$)': data.shippingPrice,
              'Charge Amount (R$)': data.amount,
              'Fee (R$)': data.fee,
              'Net (R$)': data.amount - data.fee - data.shippingPrice,
              Installments: data.installments,
              'Card Brand': data.cardBrand,
              'Card Last 4': data.cardLast4
            }))}
            style={{ all: 'unset' }}>
            <div
              className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 items-center"
              onClick={() => null}>
              Export table
              <FontAwesomeIcon icon={faArrowDownToLine} />
            </div>
          </CsvDownloadButton>
        }>
        <TableFilter
          filters={[
            {
              id: 'paymentStatus',
              name: 'Payment Status',
              options: [
                { value: 'paid', label: 'Paid' },
                { value: 'pending', label: 'Pending' },
                { value: 'refunded', label: 'Refunded' },
                { value: 'failed', label: 'Failed' },
                { value: 'canceled', label: 'Canceled' }
              ]
            },
            {
              id: 'orderStatus',
              name: 'Order Status',
              options: [
                { value: 'confirmed', label: 'Confirmed' },
                { value: 'expired', label: 'Expired' },
                { value: 'pending', label: 'Pending' },
                { value: 'cancelled', label: 'Canceled' }
              ]
            }
          ]}
          onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
          onFilterChange={setFilters}
        />

        {isLoading ? (
          <LoadingContainer />
        ) : paginatedData?.length > 0 ? (
          <>
            <Table<IPayment> className="mt-8" data={paginatedData || []} rows={columns} />
            <Pagination
              page={pagination.page}
              onPageChange={(page) => setPaginationPage(page)}
              perPage={pagination.perPage}
              total={filteredData?.length || 0}
              onNext={onNextPage}
              onPrevious={onPreviousPage}
            />
          </>
        ) : (
          <EmptyState
            img="/assets/illustrations/no-data-found.svg"
            header={'No payments fund'}
            description={'It looks like there are no payments'}
          />
        )}
      </Page>
    </>
  );
};
