import { TableRow } from 'src/@common/components/table';
import { IPayment } from 'types/payment';
import { priceToCurrency } from 'src/utils/price-to-currency';
import { Badge } from 'src/@design-system/feedback/badge';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { getGatewayTransacationUrl } from 'src/@common/helpers/get-gateway-transacation-url';
import { useEnv } from 'src/hooks/use-env';
import { paymewntStatusBadge } from 'src/pages/transactions/common/helpers';

export const useTransactionsPaymentsColumns = () => {
  const { isProd } = useEnv();

  const columns: TableRow<IPayment>[] = [
    {
      columnName: 'Order',
      render: (data) => (
        <div className="flex gap-1 cursor-pointer font-semibold items-center">
          {data.orderConfirmationCode}
          <a href={`/transactions/orders/details/${data.orderId}`} target="_blank">
            <ArrowTopRightOnSquareIcon className="w-4 text-indigo-500  " />
          </a>
        </div>
      )
    },
    {
      column: 'gateway',
      columnName: 'Gateway',
      render: (data) => <div className="flex gap-1 items-center uppercase">{data.gateway}</div>
    },
    {
      columnName: 'Status',
      render: (data) => (
        <div className="flex gap-1 items-center">
          <Badge
            color={paymewntStatusBadge[data.status]}
            value={data.status.toUpperCase() || ''}
            size="sm"
          />
        </div>
      )
    },

    {
      column: 'payerEmail',
      columnName: 'Payer Email'
    },
    {
      columnName: 'Price',
      render: (row) => <div>{priceToCurrency(row.itemsPrice || 0, 'BRL')}</div>
    },
    {
      columnName: 'Shipping ',
      render: (row) => <div>{priceToCurrency(row.shippingPrice || 0, 'BRL')}</div>
    },
    {
      columnName: 'Payment ',
      render: (row) => <div>{priceToCurrency(row.amount, 'BRL')}</div>
    },
    {
      columnName: 'Fee',
      render: (row) => <div>{priceToCurrency(row.fee, 'BRL')}</div>
    },
    {
      columnName: 'Net',
      render: (row) => <div>{priceToCurrency(row.amount - row.fee - row.shippingPrice, 'BRL')}</div>
    },
    {
      columnName: 'EMI',
      render: (row) => <div>{row.installments}</div>
    },
    {
      columnName: 'Brand',
      render: (row) => <div className="capitalize">{row.cardBrand}</div>
    },
    {
      columnName: 'Actions',
      render: (row) => (
        <a
          rel="noreferrer"
          aria-label="View transaction"
          className="text-indigo-500 font-bold"
          target="_blank"
          href={getGatewayTransacationUrl(row.gateway, row.gatewayIntentId, isProd)}>
          View
        </a>
      )
    }
  ];

  return { columns };
};
