import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { TableRow } from 'src/@common/components/table';
import { OrderDetails } from 'types/order-detail';
import { Badge } from 'src/@design-system/feedback/badge';
import { formatDate } from '@fullcalendar/core';
import { orderStatusBadge } from 'src/pages/transactions/common/helpers';

export const useOrderColumns = () => {
  const navigate = useNavigate();

  const columns: TableRow<OrderDetails>[] = [
    {
      column: 'confirmationCode',
      columnName: 'Order',
      className: 'font-semibold'
    },
    {
      column: 'customer',
      columnName: 'Buyer',
      render: (data) => (
        <div className="flex gap-1 cursor-pointer lowercase">
          {data.customer.email}
          <ArrowTopRightOnSquareIcon className="w-4 text-indigo-500  " />
        </div>
      )
    },
    {
      column: 'status',
      columnName: 'Status',
      render: (row) => (
        <Badge color={orderStatusBadge[row.status]} value={row.status.toUpperCase() || ''} />
      )
    },
    {
      columnName: 'Type',
      render: (row) => (
        <div className="capitalize">
          {row.items[0].journey ? row.items[0].journey.type : 'hotel'}
        </div>
      )
    },
    {
      columnName: 'Accommodation',
      render: (row) => <div>{row.items[0].accommodation?.name}</div>
    },
    {
      columnName: 'Quantity',
      render: (row) => <div>{row.items.length}</div>
    },
    {
      columnName: 'PAX',
      render: (row) => (
        <div>
          {row.items.reduce((acc, item) => acc + item.accommodation?.room?.capacity || 0, 0)}
        </div>
      )
    },
    {
      columnName: 'Gateway',
      render: (row) => <div className="uppercase">{row.payment?.gateway}</div>
    },
    {
      columnName: 'Creared At',
      render: (row) => (
        <div className="flex gap-1 cursor-pointer">
          {formatDate(row.createdAt, {
            locale: 'pt-BR',
            day: '2-digit',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          })}
        </div>
      )
    },
    {
      columnName: 'Actions',
      render: (row) => (
        <div
          role="button"
          className="text-indigo-500 font-bold"
          onClick={() => navigate(`/transactions/orders/details/${row.id}`)}>
          View
        </div>
      )
    }
  ];

  return { columns };
};
