import { EmptyState } from "src/components/empty-state";

export const NoDataEmptyState = ({ type }: { type?: string }) => (
  <EmptyState
    img="/assets/illustrations/no-data.svg"
    header={type ? `You have no ${type}` : "No data found"}
    description={
      type
        ? `It seems you have no ${type} yet, go create your first one!`
        : "Nothing here yet!"
    }
  />
);
