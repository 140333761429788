import { Controller, UseFormReturn } from 'react-hook-form';
import { Divider, FormControl, MenuItem, TextField, Typography } from '@mui/material';
import React, { useId } from 'react';
import { Journey } from 'types/journeys';
import { Operators, TicketFareOptions } from 'src/pages/inventory/journeys/constants';
import { format } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { TimezoneSelect } from 'src/components/timezone-select';

export function JourneyDetailForm({
  name,
  form,
  title,
  disabled
}: {
  name: 'outbound' | 'return';
  form: UseFormReturn<Journey>;
  title: string;
  disabled?: boolean;
}) {
  const id = useId();
  const formatDate = (date: Date) => format(date, 'yyyy-MM-dd HH:mm');

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex gap-4">
        <Controller
          name={`${name}.operator`}
          control={form.control}
          defaultValue=""
          render={({ field }) => (
            <FormControl fullWidth>
              <TextField
                disabled={disabled}
                select
                {...field}
                id={`operator_${id}_${name}`}
                label="Operator">
                <MenuItem key="none" value="none" disabled>
                  Select an operator
                </MenuItem>
                {Object.values(Operators).map((operator) => (
                  <MenuItem key={operator} value={operator}>
                    {operator}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
        />
        <Controller
          name={`${name}.ticketFare`}
          control={form.control}
          defaultValue="economy_class"
          render={({ field }) => (
            <FormControl fullWidth>
              <TextField
                disabled={disabled}
                select
                {...field}
                id={`ticket_fare_${id}_${name}`}
                label="Ticket fare">
                <MenuItem key="none" value="none" disabled>
                  Select an ticket fare
                </MenuItem>
                {Object.values(TicketFareOptions).map((fare) => (
                  <MenuItem key={fare} value={fare}>
                    {fare}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
        />
      </div>

      <div>
        <Typography variant="subtitle2">Departure</Typography>
        <Divider sx={{ mb: 2 }} />
        <div className="flex flex-col gap-4">
          <div className="flex-1">
            <div className="flex gap-2">
              <Controller
                name={`${name}.departure.name`}
                control={form.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={disabled}
                    fullWidth
                    label="Departure Name"
                    InputLabelProps={{ shrink: !!field.value }}
                  />
                )}
              />
              <Controller
                name={`${name}.departure.address`}
                control={form.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={disabled}
                    fullWidth
                    label="Departure Address"
                    InputLabelProps={{ shrink: !!field.value }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex gap-2">
            <Controller
              name={`${name}.departure.date`}
              control={form.control}
              defaultValue={new Date().toISOString()}
              render={({ field }) => (
                <DateTimePicker
                  disabled={disabled}
                  className="w-full"
                  format="yyyy-MM-dd hh:mm a"
                  label="Departure Date"
                  value={new Date(field.value)}
                  onChange={(date) => date && field.onChange(formatDate(new Date(date)))}
                />
              )}
            />
            <Controller
              name={`${name}.departure.timeZone`}
              control={form.control}
              render={({ field }) => (
                <TimezoneSelect
                  disabled={disabled}
                  className="w-full"
                  onChange={(tz) => field.onChange(tz as string)}
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="">
        <Typography variant="subtitle2">Arrival</Typography>
        <Divider sx={{ mb: 2 }} />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex-1">
              <div className="flex gap-2">
                <Controller
                  name={`${name}.arrival.name`}
                  control={form.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={disabled}
                      fullWidth
                      label="Arrival Location"
                      InputLabelProps={{ shrink: !!field.value }}
                    />
                  )}
                />
                <Controller
                  name={`${name}.arrival.address`}
                  control={form.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={disabled}
                      fullWidth
                      label="Arrival Address"
                      InputLabelProps={{ shrink: !!field.value }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <Controller
              name={`${name}.arrival.date`}
              control={form.control}
              defaultValue={new Date().toISOString()}
              render={({ field }) => (
                <DateTimePicker
                  disabled={disabled}
                  className="w-full"
                  format="yyyy-MM-dd hh:mm a"
                  label="Arrival Date"
                  value={new Date(field.value)}
                  onChange={(date) => date && field.onChange(formatDate(new Date(date)))}
                />
              )}
            />
            <Controller
              name={`${name}.arrival.timeZone`}
              control={form.control}
              render={({ field }) => (
                <TimezoneSelect
                  disabled={disabled}
                  className="w-full"
                  onChange={(tz) => field.onChange(tz as string)}
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
