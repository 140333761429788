import { Table, TableRow } from 'src/@common/components/table';
import { AccommodationRoomPriceType } from 'types/accommodation';
import { priceToCurrency } from 'src/utils/price-to-currency';
import React from 'react';
import { EmptyState } from 'src/components/empty-state';
import { DialogConfirmPriceUpdate } from 'src/pages/inventory/accommodation-rooms/components/add-accomm-price/dialog-confirm-price-update';
import { formatDate } from '@fullcalendar/core';

export const PricesTable = ({
  prices,
  onDeletePrice
}: {
  prices: AccommodationRoomPriceType[];
  onDeletePrice: (pricedId: string) => void;
}) => {
  const [priceToDelete, setPriceToDelete] = React.useState<AccommodationRoomPriceType | null>(null);

  const columns: TableRow<AccommodationRoomPriceType>[] = [
    {
      columnName: 'Check-in',
      render: (price) =>
        formatDate(price.checkin, {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          locale: 'pt-BR'
        })
    },
    {
      columnName: 'Check-out',
      render: (price) =>
        formatDate(price.checkout, {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          locale: 'pt-BR'
        })
    },
    {
      columnName: 'Price',
      render: (price) => priceToCurrency(price.price, 'BRL')
    },
    {
      columnName: 'Actions',
      render: (price) => {
        return (
          <div
            role="button"
            className="text-red-600 font-semibold flex gap-1 items-center"
            onClick={(e) => {
              e.preventDefault();
              setPriceToDelete(price);
            }}>
            Delete
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <DialogConfirmPriceUpdate
        onConfirm={() => {
          priceToDelete && onDeletePrice(priceToDelete.uuid);
          setPriceToDelete(null);
        }}
        isOpen={Boolean(priceToDelete)}
        onCancel={() => {
          setPriceToDelete(null);
        }}
      />
      {prices?.length > 0 ? (
        <Table<AccommodationRoomPriceType> className="mt-8" data={prices || []} rows={columns} />
      ) : (
        <EmptyState
          img="/assets/illustrations/no-data-found.svg"
          header="You have no prices yet"
          description="Add a price to start booking this room"
        />
      )}
    </div>
  );
};
