import { FC, useState } from 'react';
import type { CountryContext, Data } from 'react-svg-worldmap';
import WorldMap from 'react-svg-worldmap';
import { useTheme } from '@mui/material/styles';
import { LoadingContainer } from 'src/components/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { DashboardSummaryByCountry } from 'types/dashboard/summary';
import { Table } from 'src/@common/components/table';
import { Dialog, DialogTitle } from 'src/@design-system/dialog';

interface EcommerceWorldMapProps {
  markerColor: string;
  data: Data;
}

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const Map: FC<EcommerceWorldMapProps> = (props) => {
  const { data } = props;
  const theme = useTheme();

  const getStyle = ({ countryValue, countryCode, minValue, maxValue, color }: CountryContext) => ({
    fill: color,
    fillOpacity: countryValue ? 0.1 + (1.5 * (countryValue - minValue)) / (maxValue - minValue) : 0,
    stroke: 'black',
    strokeWidth: 1,
    strokeOpacity: 0.5,
    cursor: 'pointer'
  });

  return (
    <WorldMap
      size="md"
      backgroundColor={theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.50'}
      color="rgb(99 102 241)"
      valueSuffix="sales"
      data={data}
      styleFunction={getStyle}
    />
  );
};

const AllCountriesModal = ({ data }: { data: DashboardSummaryByCountry[] }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} size="lg">
        <DialogTitle onClose={onClose}>All sales per country</DialogTitle>
        <div className="max-h-[400px] w-[500px]">
          <Table<DashboardSummaryByCountry>
            sticky={false}
            data={data}
            className=""
            rows={[
              {
                column: 'country',
                columnName: 'Country',
                render: (row) => (
                  <div className="flex gap-2 items-center">
                    <img
                      alt="country flag"
                      src={`https://flagsapi.com/${row.country}/flat/16.png`}
                    />
                    {regionNames.of(row.country)}
                  </div>
                )
              },
              {
                column: 'value',
                columnName: 'Sales'
              }
            ]}
          />
        </div>
      </Dialog>
      <div
        role="button"
        onClick={() => setIsOpen(true)}
        className="text-indigo-600 hover:text-indigo-900 font-semibold pt-2 cursor-pointer">
        See all <FontAwesomeIcon className="w-4" icon={faArrowRight} />
      </div>
    </div>
  );
};

export const SalesMap = ({
  data,
  isLoading
}: {
  data: DashboardSummaryByCountry[];
  isLoading: boolean;
}) => {
  return isLoading ? (
    <LoadingContainer />
  ) : (
    <div className="flex gap-12">
      <Map markerColor="red" data={data || []} />
      <div className="text-sm font-medium divide-y divide-gray-200 flex flex-col">
        <div className="font-semibold text-sm mb-4">Top 5 countries</div>
        {data?.slice(0, 4).map((d) => {
          return (
            <div className="flex gap-2 items-center py-1">
              <div className="flex gap-2 items-center text-gray-500">
                <img alt="country flag" src={`https://flagsapi.com/${d.country}/flat/16.png`} />
                {regionNames.of(d.country)}{' '}
              </div>
              <div className="py-0 px-1.5 bg-gray-100 rounded-full text-xs">{d.value}</div>
            </div>
          );
        })}
        <AllCountriesModal data={data} />
      </div>
    </div>
  );
};
