export const JoyrneyTypeOptions = {
  Flight: 'flight',
  Bus: 'bus'
} as const;

//   ticketFare: 'economy_class' | 'business_class' | 'first_classs' | 'executive_bus';
export const TicketFareOptions = {
  EconomyClass: 'economy_class',
  BusinessClass: 'business_class',
  FirstClass: 'first_classs',
  ExecutiveBus: 'executive_bus'
} as const;

export const Operators = {
  SHIFT_BUS: 'shift',
  Azul: 'azul',
  KLM: 'klm',
  LATAM: 'latam'
} as const;
