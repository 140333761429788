import type { FC } from "react";
import { useTheme } from "@mui/material/styles";

export const Logo: FC = () => {
  const theme = useTheme();

  return (
    <img
      src="/assets/logos/LOGO_LOTUS_COLOR.png"
      style={{
        maxHeight: 48,
        maxWidth: 48,
      }}
    />
  );
};
