import * as React from 'react';
import { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { AccommodationPerksTypes } from 'src/constants';
import { useTranslation } from 'react-i18next';

type AccommParksProps = {
  disabled?: boolean;
  onChange: (perks: string[]) => void;
  selectedPerks?: string[];
};

export const AccommParks: FC<AccommParksProps> = ({ onChange, selectedPerks, disabled }) => {
  const { t } = useTranslation();
  const perks = Object.values(AccommodationPerksTypes);
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value }
    } = event;
    const list = typeof value === 'string' ? value.split(',') : value;
    onChange(list);
    setPersonName(list);
  };

  useEffect(() => {
    setPersonName(selectedPerks || []);
  }, [selectedPerks, setPersonName]);

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label">Perks</InputLabel>
        <Select
          disabled={disabled}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={t(`accommodation.perks.${value}`)} />
              ))}
            </Box>
          )}>
          {perks.map((name) => (
            <MenuItem key={name} value={name}>
              {t(`accommodation.perks.${name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
