import { Dialog, DialogTitle } from 'src/@design-system/dialog';
import React from 'react';

export const DialogDuplicatedDate = ({
  onConfirm,
  onCancel,
  isOpen
}: {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}) => {
  return (
    <Dialog onClose={onCancel} open={isOpen} className="text-white">
      <DialogTitle onClose={onCancel}>Duplicated price</DialogTitle>
      <div className="mt-2 text-white overflow-scroll">
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            The price for the check-in and check-out you are trying to add already exists. Do you
            want to update the price?
          </p>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:col-start-2"
              onClick={onConfirm}>
              Confirm
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
