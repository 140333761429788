import React, { Fragment } from 'react';
import cn from 'classnames';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { Dialog as DialogHS, Transition } from '@headlessui/react';

export const DialogTitle = (props: {
  className?: string;
  children: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <DialogHS.Title
      as="h2"
      className={cn(
        'text-xl sticky flex justify-between items-center font-semibold leading-6 mb-3 pb-3 border-b-2 border-gray-200',
        props.className
      )}>
      <div className="px-2">{props.children}</div>
      <div onClick={props.onClose} className="hover:bg-gray-200 p-1 rounded-md cursor-pointer">
        <XMarkIcon className="w-6" />
      </div>
    </DialogHS.Title>
  );
};

export type Dialog = {
  open: boolean;
  onClose: (open: boolean) => void;
  className?: string;
  children: React.ReactNode;
  title?: string;
  size?: 'sm' | 'md' | 'lg';
};

export function Dialog({ size = 'md', open, onClose, children, className, title }: Dialog) {
  return (
    <Transition.Root show={open}>
      <DialogHS as="div" className={cn('relative', className)} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="z-40 fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen">
          <div className="flex min-h-full justify-center md:p-4 sm:items-center sm:p-0 ">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogHS.Panel
                className={cn(
                  'overflow-hidden relative transform rounded-lg bg-white text-black px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  sm:p-6',
                  {
                    'w-[600px]': size === 'lg',
                    'w-[500px]': size === 'md',
                    'w-[400px]': size === 'sm'
                  }
                )}>
                {title ? <DialogTitle onClose={() => onClose(open)}>{title}</DialogTitle> : null}
                <div className="overflow-scroll">{children}</div>
              </DialogHS.Panel>
            </Transition.Child>
          </div>
        </div>
      </DialogHS>
    </Transition.Root>
  );
}
