import { Page } from 'src/components/page';
// import { EcommerceWorldMap } from 'src/sections/dashboard/ecommerce/ecommerce-world-map';
import { OrderLastHours } from 'src/pages/dashboard/summary/widgets/order-last-hours';
import { OrdersConsolidated } from 'src/pages/dashboard/summary/widgets/orders-consolidated';
import classNames from 'classnames';
import cn from 'classnames';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { LoadingContainer } from 'src/components/loading';
import { useSummaryData } from 'src/pages/dashboard/summary/hooks/use-summary-data';
import { SalesMap } from 'src/pages/dashboard/summary/components/sales-map';
import { ButtonGroupItem, ButtonsGroup } from 'src/@design-system/button-group';
import { useState } from 'react';

export type OrderPerHour = {
  hour: string;
  confirmed: number;
  cancelled: number;
  expired: number;
  totalOrders: number;
};

export type OrderPerDay = {
  day: string;
  confirmed: number;
  cancelled: number;
  expired: number;
  totalOrders: number;
};

export const DashboardSummary = () => {
  const [type, setType] = useState<'hour' | 'day'>('hour');
  const { perHour, summary, perCountry, refetchAll } = useSummaryData(type);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <Page
      title="Dashboard Summary"
      cta={
        <div role="button" onClick={() => refetchAll()}>
          <FontAwesomeIcon
            className={cn('text-indigo-600', {
              'animate-spin': perHour.isFetching || perCountry.isFetching || summary.isFetching
            })}
            icon={faArrowsRotate}
          />
        </div>
      }>
      <div className="flex justify-between">
        <dl className="w-full grid grid-cols-2 gap-px divide-x divide-gray-200 pb-4">
          <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white pl-4 first:pl-0 ">
            <dt className="text-sm font-medium leading-6 text-gray-500">
              Sales per country{' '}
              <Tooltip title="Total of sales per country (top 10)">
                <FontAwesomeIcon icon={faCircleQuestion} />
              </Tooltip>
            </dt>
            <dd className={classNames('text-xs font-medium')}></dd>
            <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
              <SalesMap data={perCountry.data || []} isLoading={perCountry.isLoading} />
            </dd>
          </div>
          <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white pl-4 first:pl-0 ">
            <dt className="text-sm font-medium leading-6 text-gray-500 px-12">
              Sales <div className="text-xs text-gray-400">{timezone} timezone</div>
            </dt>
            <dd className={classNames('text-xs font-medium')}></dd>
            <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
              <ButtonsGroup className="px-12">
                <ButtonGroupItem active={type === 'hour'} onClick={() => setType('hour')}>
                  By hour
                </ButtonGroupItem>
                <ButtonGroupItem active={type === 'day'} onClick={() => setType('day')}>
                  By day
                </ButtonGroupItem>
              </ButtonsGroup>
              {perHour.isLoading ? (
                <div className="h-[350px]">
                  <LoadingContainer />
                </div>
              ) : (
                <OrderLastHours
                  type={type}
                  data={perHour.data || []}
                  isLoading={perHour.isLoading}
                />
              )}
            </dd>
          </div>
        </dl>
      </div>
      <div>
        <div className="sm:flex-auto mb-8">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Summary</h1>
          <p className="mt-2 text-sm text-gray-700">
            A consolidated view of the sales and orders for accommodation packages, journey packages
            and tickets
          </p>
        </div>
        <OrdersConsolidated data={summary.data} isLoading={summary.isLoading} />
      </div>
    </Page>
  );
};
