import cn from 'classnames';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { ItemSkeleton } from 'src/pages/dashboard/common/summary-widget';

export type TransactionStatus = {
  key: string;
  name: string | ReactNode;
  value: string | number | ReactNode;
};

export const TransactionsStatusWidget = ({
  stats,
  isLoading
}: {
  stats: TransactionStatus[];
  isLoading: boolean;
}) => {
  return (
    <dl className={cn(`px-2 mx-auto grid gap-px divide-x divide-gray-200 pb-4 grid-cols-4`)}>
      {stats.map((stat) => (
        <div
          key={stat.key}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white py-10 pl-4 first:pl-0 ">
          <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
          <dd className={classNames('text-gray-700', 'text-xs font-medium')}></dd>
          <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
            {isLoading ? <ItemSkeleton /> : stat.value}
          </dd>
        </div>
      ))}
    </dl>
  );
};
