import cn from 'classnames';
import { Path, useFormContext } from 'react-hook-form';
import { InputHTMLAttributes } from 'react';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { getPropertyByString } from 'src/@common/helpers/get-property-by-string';

export type InputProps<T> = {
  label: string;
  name: Path<T>;
  className?: string;
  rules?: RegisterOptions;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = <T,>({ label, name, className, rules, ...props }: InputProps<T>) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const error = getPropertyByString(errors, name as string);

  return (
    <div className={cn('sm:col-span-3', className)}>
      <label htmlFor={name as string} className={cn('block text-sm font-medium text-gray-900 ')}>
        {label}
      </label>
      <input
        {...props}
        {...register(name as string, rules)}
        className="disabled:bg-gray-100 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600"
      />
      {error && (
        <div className="pt-1 text-red-500 text-sm">
          {(error.message as string) || 'This field is required'}
        </div>
      )}
    </div>
  );
};
