import { Storage } from 'aws-amplify';
import { File } from 'src/components/file-dropzone';
import { generateRandomHash } from 'src/utils/generate-random-hash';

const uploadImage = async (file: File, name: string, folder: string) => {
  const hash = generateRandomHash();
  const extension = file.name.split('.').pop();
  const fileName = `${folder}/${name}_${hash}.${extension}`;

  await Storage.put(fileName, file, {
    contentType: file.type,
    level: 'public'
  });

  return fileName;
};

export default uploadImage;
