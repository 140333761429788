import { FC, Fragment, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withAuthGuard } from 'src/hocs/with-auth-guard';
import { Bars3Icon, HomeIcon, UsersIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/use-auth';
import type { AuthContextType as AmplifyAuthContextType } from 'src/contexts/auth/amplify-context';
import { analyticsLeftNav, dashboardLeftNav, inventoryLeftNav } from 'src/layouts/dashboard/config';
import { trackLeftNavClick, trackTopNavClick } from 'src/@common/analytics/events';
import { useTransactionsNav } from 'src/layouts/dashboard/navigation/left-nav/use-transactions-nav';

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const auth: AmplifyAuthContextType = useAuth();
  const transactionsLeftNav = useTransactionsNav();

  const navigation = useMemo(
    () =>
      user?.role === 'frontdesk'
        ? [
            {
              name: 'Transactions',
              href: '/transactions',
              icon: UsersIcon,
              current: false,
              enabled: true,
              leftNav: transactionsLeftNav
            }
          ]
        : [
            {
              name: 'Dashboard',
              href: '/dashboard',
              icon: HomeIcon,
              current: true,
              enabled: true,
              leftNav: dashboardLeftNav
            },
            {
              name: 'Inventory',
              href: '/inventory',
              icon: UsersIcon,
              current: false,
              enabled: true,
              leftNav: inventoryLeftNav
            },
            {
              name: 'Transactions',
              href: '/transactions',
              icon: UsersIcon,
              current: false,
              enabled: true,
              leftNav: transactionsLeftNav
            },
            {
              name: 'Analytics',
              href: '/analytics',
              icon: UsersIcon,
              current: false,
              enabled: true,
              leftNav: analyticsLeftNav
            }
          ],
    [transactionsLeftNav, user?.role]
  );

  const currentNavigation = navigation.find((nav) => location.pathname.includes(nav.href));

  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:overflow-hidden w-[65px] hover:w-[270px] lg:bg-gray-900  transition-width duration-300 ease-in-out">
          <div className="flex h-16 items-center justify-left bg-indigo-900/70 w-full">
            <img
              className="h-8 w-auto pl-4"
              src="/assets/logos/LOGO_LOTUS_COLOR.png"
              alt="Insidefy"
            />
            <div className="ml-6 text-white border-b-2 border-indigo-500 uppercase tracking-wide font-bold">
              Inside<span className="font-bold text-indigo-400">fy</span>.io
            </div>
          </div>
          <nav className="pt-6 mt-0 ml-2  pr-3 ">
            <ul className="flex flex-col items-left space-y-2">
              {currentNavigation?.leftNav.map((item) => {
                const isCurrent = item.href === location.pathname;
                return (
                  <li
                    role="button"
                    onClick={() => navigate(item.href)}
                    key={item.name}
                    className="group w-full flex gap-4 items-center mr-6 cursor-pointer">
                    <div
                      onClick={() => trackLeftNavClick(item.name)}
                      className={classNames(
                        isCurrent
                          ? 'bg-gray-800 text-white'
                          : 'text-gray-400 group-hover:text-white group-hover:bg-gray-800',
                        'w-full group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold '
                      )}>
                      {item.icon}
                      <div
                        className={classNames(
                          'pl-4 text-gray-400 font-bold overflow-hidde group-hover:text-white',
                          {
                            'text-indigo-400/70 group-hover:text-indigo-300/90 ': isCurrent
                          }
                        )}>
                        <div className="text-sm">{item.name}</div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>

        <div className="lg:pl-16">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-between">
              <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                {navigation
                  .filter((nav) => nav.enabled)
                  .map((item) => {
                    const isCurrent = location.pathname.includes(item.href);
                    return (
                      <div
                        role="button"
                        key={item.name}
                        onClick={() => {
                          trackTopNavClick(item.name);
                          navigate(item.href);
                        }}
                        className={classNames(
                          isCurrent
                            ? 'border-indigo-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                        )}
                        aria-current={isCurrent ? 'page' : undefined}>
                        {item.name}
                      </div>
                    );
                  })}
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/*<a*/}
                {/*  target="_blank"*/}
                {/*  role="button"*/}
                {/*  className="py-1 px-2 bg-indigo-600 rounded-md flex items-center gap-2 text-white text-sm"*/}
                {/*  href={`${SHOP_URL}?pld=${user?.shopToken}`}>*/}
                {/*  <span>Go to shop</span>*/}
                {/*  <FontAwesomeIcon className="h-4 w-4 shrink-0" aria-hidden="true" icon={faShop} />*/}
                {/*</a>*/}

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    {user?.avatar ? (
                      <img className="h-8 w-8 rounded-full bg-gray-50" src={user?.avatar} alt="" />
                    ) : null}
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true">
                        {user?.name}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={async () => {
                              await auth.signOut();
                              navigate('/auth/amplify/login');
                            }}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                            )}>
                            Sign out
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="px-8">{props.children}</main>
        </div>
      </div>
    </>
  );
});

Layout.propTypes = {
  children: PropTypes.node
};
