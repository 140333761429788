import { ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';

export type RadioGroupOption = { key: string; name: string; description: ReactNode };

type RadioGroupProps = {
  selected?: RadioGroupOption;
  className?: string;
  options: RadioGroupOption[];
  onSelect: (option: RadioGroupOption) => void;
};

export const RadioGroup = ({
  options,
  onSelect,
  className,
  selected: selectedOption
}: RadioGroupProps) => {
  const [selected, setSelected] = useState<RadioGroupOption | null>(null);

  useEffect(() => {
    if (!selected && selectedOption) {
      setSelected(selectedOption);
    }
  }, [selected, selectedOption]);

  return (
    <fieldset className={cn(className)}>
      <legend className="sr-only">Bank account</legend>
      <div className="divide-y divide-gray-200">
        {options.map((account, accountIdx) => (
          <div
            role="button"
            key={accountIdx}
            className="cursor-pointer relative flex items-center pb-4 pt-3.5 "
            onClick={() => {
              setSelected(account);
              onSelect(account);
            }}>
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label htmlFor={`account-${account.key}`} className="font-medium text-gray-900">
                {account.name}
              </label>
              <p id={`account-${account.key}-description`} className="text-gray-500">
                {account.description}
              </p>
            </div>
            <div className="ml-3 flex h-6 items-center">
              <input
                id={`account-${account.key}`}
                aria-describedby={`account-${account.key}-description`}
                name="account"
                type="radio"
                checked={account.key === selected?.key}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};
