import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { File, FileDropzone } from 'src/components/file-dropzone';
import { useCallback, useEffect, useState } from 'react';
import { fileToBase64 } from 'src/utils/file-to-base64';

type CoverImgUploadProps = {
  disabled?: boolean;
  onChange: (file: File) => void;
  initialCover: string;
};

const CoverImgUpload: React.FC<CoverImgUploadProps> = (props) => {
  console.log('initialCover', props.initialCover);
  const [cover, setCover] = useState<string | null>(props.initialCover);

  useEffect(() => setCover(props.initialCover), [props.initialCover]);

  const handleCoverDrop = useCallback(
    async ([file]: File[]) => {
      const data = (await fileToBase64(file)) as string;
      setCover(data);
      props.onChange(file);
      return file;
    },
    [props]
  );

  const handleCoverRemove = useCallback((): void => {
    setCover(null);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <Stack spacing={1}>
          <Typography variant="h6">Page Cover</Typography>
          <Typography color="text.secondary" variant="body2">
            Page Cover will appear as main image
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={8}>
        <Stack spacing={3}>
          {cover ? (
            <Box
              sx={{
                backgroundImage: `url(${cover})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: 1,
                height: 230,
                mt: 3
              }}
            />
          ) : (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                border: 1,
                borderRadius: 1,
                borderStyle: 'dashed',
                borderColor: 'divider',
                height: 230,
                mt: 3,
                p: 3
              }}>
              <Typography align="center" color="text.secondary" variant="h6">
                Select a cover image
              </Typography>
              <Typography align="center" color="text.secondary" sx={{ mt: 1 }} variant="subtitle1">
                Image used for the blog post cover and also for Open Graph meta
              </Typography>
            </Box>
          )}
          {!props.disabled ? (
            <>
              <div>
                <Button color="inherit" disabled={!cover} onClick={handleCoverRemove}>
                  Remove photo
                </Button>
              </div>
              <FileDropzone
                accept={{ 'image/*': [] }}
                maxFiles={1}
                onDrop={handleCoverDrop}
                caption="(SVG, JPG, PNG, or gif maximum 1900x1400)"
              />
            </>
          ) : null}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CoverImgUpload;
