import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';

type Filter = {
  id: string;
  name: string;
  options: {
    value: string;
    label: string;
  }[];
};

type SelectedFilters = Record<string, Set<string>>;

export const TableFilter = ({
  disabled,
  filters,
  onFilterChange,
  onSearch,
  defaultSelectedFilters
}: {
  disabled?: boolean;
  filters: Filter[];
  onSearch?: (search: string) => void;
  defaultSelectedFilters?: SelectedFilters;
  onFilterChange: (filters: Record<string, string[]>) => void;
}) => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});

  useEffect(() => {
    if (Object.entries(selectedFilters).length === 0 && defaultSelectedFilters)
      setSelectedFilters(defaultSelectedFilters);
  }, [defaultSelectedFilters, selectedFilters]);

  const handleFilterChange = (sectionId: string, optionValue: string) => {
    setSelectedFilters((prev) => {
      const selected = prev[sectionId] || new Set();
      if (selected.has(optionValue)) {
        selected.delete(optionValue);
      } else {
        selected.add(optionValue);
      }
      const newFilters = {
        ...prev,
        [sectionId]: selected
      };

      onFilterChange(
        Object.fromEntries(
          Object.entries(newFilters).map(([key, value]) => [key, Array.from(value)])
        )
      );

      return newFilters;
    });
  };

  return (
    <div className="bg-white mb-8">
      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>

        <div className="border-b border-gray-200 bg-white pb-4">
          <div className="mx-auto flex items-center justify-between ">
            <div>
              <div>
                <div className="relative flex items-center">
                  <input
                    onChange={(e) => onSearch?.(e.target.value)}
                    type="text"
                    name="search"
                    id="search"
                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                    <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                      <MagnifyingGlassIcon className="w-4" />
                    </kbd>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden sm:block">
              <div className="flow-root">
                <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                  {filters.map((section, sectionIdx) => (
                    <Popover key={section.name} className="relative inline-block px-4 text-left">
                      <Popover.Button
                        disabled={disabled}
                        className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        <span>{section.name}</span>
                        <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                          {selectedFilters[section.id]?.size || 0}
                        </span>
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Popover.Panel className="z-50 absolute right-0 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <form className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <label
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleFilterChange(section.id, option.value);
                                }}
                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                className="ml-1 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 flex gap-2">
                                <input
                                  checked={selectedFilters[section.id]?.has(option.value)}
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  defaultChecked={selectedFilters[section.id]?.has(option.value)}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                {option.label}
                              </label>
                            ))}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
