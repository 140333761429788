import { FC, useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RTL } from './components/rtl';
import { SplashScreen } from './components/splash-screen';
import { Toaster } from './components/toaster';
import { SettingsConsumer, SettingsProvider } from './contexts/settings-context';
import { AuthConsumer } from './contexts/auth/amplify-context';
import { gtmConfig } from './config';
import { useNprogress } from './hooks/use-nprogress';
import { useAnalytics } from './hooks/use-analytics';
import { createTheme } from './theme';
import 'handsontable/dist/handsontable.full.min.css';
import { enableMapSet } from 'immer';

// Remove if locales are not used
import './locales/i18n';

import awsConfig from './aws-exports';
import { API, Auth, Storage } from 'aws-amplify';
import { RoleBasedGuard, ROLES } from 'src/guards/role-based-guard';
import { authRoutes } from 'src/routes/auth';
import { inventoryRoutes } from 'src/routes/inventory';
import { useTransactionRoutes } from 'src/routes/reports';
import { analyticsRoutes } from 'src/routes/analystics';
import { dashboardRoutes } from 'src/routes/dashboard';
import type { RouteObject } from 'react-router';
import { useAuth } from 'src/hooks/use-auth';

API.configure({
  ...awsConfig,
  aws_cloud_logic_custom: [
    {
      name: 'storefrontAdminApi',
      endpoint: process.env.REACT_APP_API_BASE_URL,
      region: 'us-east-2'
    }
  ]
});

Auth.configure({
  identityPoolId: awsConfig.aws_cognito_identity_pool_id,
  region: awsConfig.aws_cognito_region,
  userPoolId: awsConfig.aws_user_pools_id,
  userPoolWebClientId: awsConfig.aws_user_pools_web_client_id
});

Storage.configure({
  AWSS3: {
    bucket: awsConfig.aws_user_files_s3_bucket,
    region: awsConfig.aws_user_files_s3_bucket_region
  }
});

enableMapSet();

export const App: FC = () => {
  useAnalytics(gtmConfig);
  useNprogress();
  const transactionRoutes = useTransactionRoutes();

  const { user } = useAuth();

  const routes: RouteObject[] = useMemo(() => {
    return [
      {
        path: '/',
        element: (
          <Navigate
            to={user?.role === 'frontdesk' ? '/transactions/guests' : '/dashboard'}
            replace
          />
        )
      },
      ...authRoutes,
      ...inventoryRoutes,
      ...analyticsRoutes,
      ...transactionRoutes,
      ...dashboardRoutes
    ];
  }, [transactionRoutes, user?.role]);

  const element = useRoutes(routes);
  //
  return (
    <AuthConsumer>
      {(auth) => (
        <SettingsProvider>
          <SettingsConsumer>
            {(settings) => {
              // Prevent theme flicker when restoring custom settings from browser storage
              if (!settings.isInitialized) {
                // return null;
              }

              const theme = createTheme({
                colorPreset: settings.colorPreset,
                contrast: settings.contrast,
                direction: settings.direction,
                paletteMode: settings.paletteMode,
                responsiveFontSizes: settings.responsiveFontSizes
              });

              // Prevent guards from redirecting
              const showSlashScreen = !auth.isInitialized;

              return (
                <ThemeProvider theme={theme}>
                  <RTL direction={settings.direction}>
                    <CssBaseline />
                    {showSlashScreen ? (
                      <SplashScreen />
                    ) : (
                      <>
                        {element}
                        <RoleBasedGuard permissions={ROLES.Viewer}>
                          {/*<SettingsButton*/}
                          {/*  onClick={settings.handleDrawerOpen}*/}
                          {/*/>*/}
                        </RoleBasedGuard>
                      </>
                    )}
                    <Toaster />
                  </RTL>
                </ThemeProvider>
              );
            }}
          </SettingsConsumer>
        </SettingsProvider>
      )}
    </AuthConsumer>
  );
};
