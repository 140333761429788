import * as React from 'react';
import { FC, useId, useMemo } from 'react';
import { State } from 'country-state-city';
import { Autocomplete, TextField } from '@mui/material';

type AccTypeSelecInputProps = {
  disabled?: boolean;
  countryCode?: string;
  value: string;
  onChange: (countryIso: string) => void;
};

export const LocationStateSelector: FC<AccTypeSelecInputProps> = ({
  onChange,
  value,
  countryCode,
  disabled
}) => {
  const id = useId();

  const states = State.getStatesOfCountry(countryCode).map((state) => ({
    label: state.name,
    id: state.isoCode
  }));

  const valueToCountry = useMemo(
    () => states.find((state) => state.id === value) || null,
    [states, value]
  );

  return (
    <Autocomplete
      disabled={!countryCode || disabled}
      fullWidth
      id={id}
      options={states}
      defaultValue={valueToCountry}
      onChange={(e, v) => v && onChange(v.id)}
      value={valueToCountry}
      renderInput={(params) => <TextField {...params} fullWidth label="States" />}
    />
  );
};
