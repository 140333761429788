import { create } from 'zustand';
import { AccommodationRoomPriceType } from 'types/accommodation';
import { produce } from 'immer';

interface StoreState {
  checkin: Date | null;
  checkout: Date | null;
  price: string;
  live: boolean;
  prices: AccommodationRoomPriceType[];
}

interface StoreActions {
  setCheckin: (checkin: Date | null) => void;
  setCheckout: (checkout: Date | null) => void;
  setPrice: (price: string) => void;
  setLive: (live: boolean) => void;
  addPrice: (newPrice: AccommodationRoomPriceType) => void;
  addPrices: (newPrice: AccommodationRoomPriceType[]) => void;
  resetPrices: () => void;
}

export const usePriceStore = create<StoreState & StoreActions>((set) => ({
  checkin: null,
  setCheckin: (checkin) => set(() => ({ checkin })),
  checkout: null,
  setCheckout: (checkout) => set(() => ({ checkout })),
  price: '',
  setPrice: (price) => set(() => ({ price })),
  live: false,
  setLive: (live) => set(() => ({ live })),
  prices: [],
  addPrice: (newPrice) => set((state) => ({ prices: [...state.prices, newPrice] })),
  addPrices: (prices) => set((state) => ({ prices })),
  resetPrices: () =>
    set(
      produce<StoreState>((state) => {
        state.prices = [];
      })
    )
}));
