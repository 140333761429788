import { apiFetch } from 'src/api/helper/fetch';

export const exportReportHandler = async (type: string) => {
  try {
    const response: string = await apiFetch(`/export/transactions/${type}`).get({
      from: '2020-01-01'
    });

    // Automatically trigger the download
    const link = document.createElement('a');
    link.href = response;
    link.download = 'data.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    throw new Error('Failed to export report');
  }
};
