import React, { FC, useCallback } from 'react';
import { Button, FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AccommodationRoomPriceType } from 'types/accommodation';
import { Controller, useForm } from 'react-hook-form';
import { endOfDay, format } from 'date-fns';
import { DialogDuplicatedDate } from 'src/pages/inventory/accommodation-rooms/components/add-accomm-price/dialog-duplicated-date';

type AddAccommPriceProps = {
  disabled?: boolean;
  prices: AccommodationRoomPriceType[];
  onChange: (prices: AccommodationRoomPriceType[]) => void;
};

export const AddAccommPrice: FC<AddAccommPriceProps> = (props) => {
  const [duplicatePrice, setDuplicatePrice] = React.useState<AccommodationRoomPriceType | null>(
    null
  );
  const form = useForm<AccommodationRoomPriceType>({
    defaultValues: {
      uuid: crypto.randomUUID()
    }
  });

  const handleAddPrice = useCallback(
    (price: AccommodationRoomPriceType) => {
      const newPrice = {
        price: price.price,
        uuid: crypto.randomUUID(),
        checkin: format(endOfDay(new Date(price.checkin)), 'yyyy-MM-dd'),
        checkout: format(new Date(new Date(price.checkout)), 'yyyy-MM-dd')
      };

      if (
        props.prices.some((p) => p.checkin === newPrice.checkin && p.checkout === newPrice.checkout)
      ) {
        setDuplicatePrice(price);
        return;
      }

      props.onChange([...props.prices, newPrice]);
    },
    [props]
  );

  const handleUpdateDate = useCallback(() => {
    if (duplicatePrice) {
      const newPrice = {
        price: duplicatePrice.price,
        uuid: crypto.randomUUID(),
        checkin: format(endOfDay(new Date(duplicatePrice.checkin)), 'yyyy-MM-dd'),
        checkout: format(new Date(new Date(duplicatePrice.checkout)), 'yyyy-MM-dd')
      };

      props.onChange([
        ...props.prices.filter(
          (p) => p.checkin !== newPrice.checkin && p.checkout !== newPrice.checkout
        ),
        newPrice
      ]);
    }
    setDuplicatePrice(null);
  }, [duplicatePrice, props]);

  return (
    <div>
      <DialogDuplicatedDate
        onCancel={() => setDuplicatePrice(null)}
        isOpen={Boolean(duplicatePrice)}
        onConfirm={handleUpdateDate}
      />
      {props.disabled ? null : (
        <div className="flex gap-2">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="checkin"
              control={form.control}
              rules={{ required: true }}
              render={({ field }) => (
                <DesktopDatePicker
                  timezone="ISO"
                  label="Check-in Date"
                  format="dd/MM/yyyy"
                  value={field.value}
                  onChange={(date) => date && field.onChange(date)}
                  slotProps={{ textField: { size: 'small' } }}
                />
              )}
            />
            <Controller
              name="checkout"
              control={form.control}
              rules={{ required: true }}
              render={({ field }) => (
                <DesktopDatePicker
                  timezone="ISO"
                  label="Check-out Date"
                  format="dd/MM/yyyy"
                  value={field.value}
                  onChange={(date) => date && field.onChange(date)}
                  slotProps={{ textField: { size: 'small' } }}
                />
              )}
            />
          </LocalizationProvider>
          <FormControl>
            <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
            <div className="h-4">
              <OutlinedInput
                size="medium"
                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                label="Price"
                {...form.register('price')}
                type="number"
              />
            </div>
          </FormControl>

          <div className="h-full">
            <Button
              variant="outlined"
              onClick={form.handleSubmit(handleAddPrice)}
              color="warning"
              sx={{ height: 'max-content' }}>
              Add Price
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
