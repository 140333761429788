import { TRANSACATIONS_PATH } from 'src/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping, faMoneyCheckDollar, faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { LeftNavigationItem } from 'src/layouts/dashboard/config';
import { useAuth } from 'src/hooks/use-auth';

export const useTransactionsNav = () => {
  const { user } = useAuth();
  const reportsLeftNav: LeftNavigationItem[] = [
    {
      name: 'Orders',
      href: TRANSACATIONS_PATH.orders.index,
      icon: (
        <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faBagShopping} />
      ),
      current: true
    },
    {
      disabled: !user?.isAdmin,
      name: 'Payments',
      href: TRANSACATIONS_PATH.payments.index,
      icon: (
        <FontAwesomeIcon
          className="h-5 w-5 shrink-0"
          aria-hidden="true"
          icon={faMoneyCheckDollar}
        />
      ),
      current: true
    },
    {
      name: 'Guests',
      href: TRANSACATIONS_PATH.guests.index,
      icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faUsers} />,
      current: true
    }
  ];

  return reportsLeftNav.filter((nav) => !nav.disabled);
};
