import { useFormDrawer } from 'src/@design-system/form/container';
import { OrderDetailsAddress } from 'types/order-detail';
import { countryList, getStateList } from 'src/@common/country-state';
import { useCallback, useState } from 'react';
import { apiFetch } from 'src/api/helper/fetch';
import toast from 'react-hot-toast';
import { useOrderDetails } from 'src/pages/transactions/orders/hooks/use-order-details';
import { useAuth } from 'src/hooks/use-auth';

type BuyerAddressFormProps = {
  addressDetails: OrderDetailsAddress;
  customerId: string;
  open: boolean;
  orderId: string;
  onClose: () => void;
};

export const BuyerAddressForm = ({
  orderId,
  addressDetails,
  open,
  onClose,
  customerId
}: BuyerAddressFormProps) => {
  const { user } = useAuth();
  const { refetch } = useOrderDetails();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: OrderDetailsAddress, dirtyFields: string[]) => {
      setIsLoading(true);

      const sanitizedData: OrderDetailsAddress = {
        ...data,
        address1: data.address1.trim(),
        address2: data.address2.trim(),
        city: data.city.trim(),
        postalCode: data.postalCode.trim()
      };

      try {
        await apiFetch(`/order/customer/${orderId}/${customerId}`).put(
          {
            address: sanitizedData,
            dirtyFields,
            updatedFields: Object.keys(data)
          },
          {
            type: 'address'
          }
        );
        onClose();
        toast.success('Main buyer address has been saved!');
        await refetch();
      } catch (error) {
        toast.error('An error occurred while saving your changes. Please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [customerId, onClose, orderId, refetch]
  );

  const { FormDrawer, form } = useFormDrawer<Omit<OrderDetailsAddress, 'address'>>({
    defaultValues: addressDetails,
    disabled: isLoading || !user?.isAdmin,
    rules: {
      required: true
    }
  });

  const country = form.watch('country');

  return (
    <FormDrawer
      form={form}
      disabled={isLoading || !user?.isAdmin}
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      title="Buyer Billing Address"
      description="Edit billing address of the main buyer"
      onSaved={onSubmit}>
      <>
        <FormDrawer.Input
          label="Address 1"
          name="address1"
          rules={{
            pattern: {
              value: /^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/i,
              message: 'Invalid address'
            }
          }}
        />
        <FormDrawer.Input
          label="Address 2"
          name="address2"
          rules={{
            required: false,
            pattern: {
              value: /^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/i,
              message: 'Invalid address'
            }
          }}
        />
        <FormDrawer.Combobox label="Country" name="country" options={countryList} />
        <FormDrawer.Combobox
          label="State"
          name="state"
          options={getStateList(country)}
          rules={{ required: true }}
        />
        <FormDrawer.Input
          label="City"
          rules={{
            pattern: {
              value: /^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/i,
              message: 'Invalid city'
            }
          }}
          name="city"
        />
        <FormDrawer.Input
          label="Postal Code"
          name="postalCode"
          rules={{
            pattern: {
              value: /^[a-zA-Z0-9]+$/i,
              message: 'Invalid postal code'
            }
          }}
        />
      </>
    </FormDrawer>
  );
};
