import { FC, useMemo } from 'react';
import { NoDataEmptyState } from 'src/components/dropdown/no-data-empty-state';
import { Journey } from 'types/journeys';
import { priceToCurrency } from 'src/utils/price-to-currency';
import { useNavigate } from 'react-router-dom';
import { Table, TableRow } from 'src/@common/components/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

type Props = {
  data: Journey[];
};

const JourneysTable: FC<Props> = (props) => {
  const naviage = useNavigate();

  // rooms by accommodation
  const journeysByType = useMemo(() => {
    return props.data?.reduce(
      (acc, journey) => {
        acc[journey.type] = acc[journey.type] || [];
        acc[journey.type].push(journey);
        return acc;
      },
      {} as Record<string, Journey[]>
    );
  }, [props.data]);

  if (props.data.length === 0) {
    return <NoDataEmptyState type={'Journeys'} />;
  }

  const columns: TableRow<Journey>[] = [
    {
      columnName: 'From',
      className: 'w-[350px]',
      render: (row) => {
        return <span className="">{row.outbound.departure.name}</span>;
      }
    },
    {
      columnName: 'To',
      className: 'w-[350px]',
      render: (row) => {
        return row.outbound.arrival.name;
      }
    },
    // {
    //   flex: 1,
    //   field: 'to',
    //   headerName: 'To',
    //   renderCell: ({ row }) => {
    //     return row.outbound.arrival.name;
    //   }
    // },
    {
      columnName: 'Price',
      render: (row) => {
        return priceToCurrency(row.price.price, 'BRL');
      }
    },
    {
      columnName: 'Availability',
      render: (row) => {
        return row.availability;
      }
    },
    {
      columnName: 'Return',
      className: 'text-center',
      render: (row) => {
        return row?.return ? (
          <FontAwesomeIcon className="text-green-400" icon={faCircleCheck} />
        ) : null;
      }
    },
    {
      columnName: 'Actions',
      className: 'text-right',
      render: (row) => {
        return (
          <div className="flex gap-1 justify-end">
            <div
              className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 font-semibold"
              onClick={() => naviage(`/inventory/journeys/edit/${row.uuid}`)}>
              View
            </div>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <div className="mt-8 space-y-8">
        {Object.entries(journeysByType).map(([type, journeys]) => {
          return (
            <div key={type}>
              <h2 className="font-bold capitalize"># {type}</h2>
              <Table<Journey> data={journeys} rows={columns} className="table-fixed" />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default JourneysTable;
