import { SegmentsSummaryWidget } from './sales-detailed';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { apiFetch } from 'src/api/helper/fetch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DashboardJourney } from 'types/dashboard/journey';
import { JourneyType } from 'types/journeys';
import { SalesSummaryWidget } from 'src/pages/dashboard/common/summary-widget';
import { TableFilter } from 'src/@common/components/table-filter';
import { Page } from 'src/components/page';
import { trackFilterApplied } from 'src/@common/analytics/events';

const DASHBOARD_REFETCH_INTERVAL = 30000;

const DashboardJourneys = () => {
  const [filters, setFilters] = useState<{
    origin?: string[];
    type?: JourneyType[];
    search?: string;
  }>({});

  const {
    data: detailedData,
    isLoading: isLoadingDetailed,
    isFetching: isFetchingDetailed,
    refetch: refetchDetailed
  } = useQuery<DashboardJourney[]>(
    ['dashboardAccommodationDetailed'],
    async () => {
      return apiFetch('/dashboard/journeys').get();
    },
    {
      refetchInterval: DASHBOARD_REFETCH_INTERVAL
    }
  );

  const onTriggerRefetch = useCallback(async () => {
    await refetchDetailed();
  }, [refetchDetailed]);

  useEffect(() => {
    trackFilterApplied(filters, 'dashboard', 'journeys');
  }, [filters]);

  const filteredData = detailedData?.filter((data) => {
    if (filters.origin && filters.origin.length > 0) {
      return filters.origin.includes(data.id);
    }

    if (filters.type && filters.type.length > 0) {
      return filters.type.includes(data.journeyType);
    }

    if (filters.search) {
      const search = filters.search.toLowerCase();
      return (
        data.journeyName.toLowerCase().includes(search) ||
        data.journeyCountry.toLowerCase().includes(search) ||
        data.journeyType.toLowerCase().includes(search)
      );
    }

    return true;
  });

  const filers = useMemo(() => {
    return [
      {
        id: 'type',
        name: 'Type',
        options: [
          { label: 'Flight', value: 'flight' },
          { label: 'Bus', value: 'bus' }
        ]
      },
      {
        id: 'origin',
        name: 'Origin',
        options: detailedData?.map((d) => ({ label: d.journeyName, value: d.id })) || []
      }
    ];
  }, [detailedData]);

  const summary = filteredData?.reduce(
    (acc, journey) => {
      return {
        totalSold: acc.totalSold + Number(journey.sold),
        totalOnHold: acc.totalOnHold + Number(journey.onHold),
        totalAvailable: acc.totalAvailable + Number(journey.available),
        salesGrossRevenue: acc.salesGrossRevenue + Number(journey.grossRevenue || 0),
        totalCharges: acc.salesGrossRevenue + Number(journey.totalCharges || 0)
      };
    },
    {
      totalSold: 0,
      totalOnHold: 0,
      totalAvailable: 0,
      totalCharges: 0,
      salesGrossRevenue: 0
    }
  );

  return (
    <Page
      title="Journeys"
      cta={
        <div role="button" onClick={onTriggerRefetch}>
          <FontAwesomeIcon
            className={cn('text-indigo-600', {
              'animate-spin': isFetchingDetailed
            })}
            icon={faArrowsRotate}
          />
        </div>
      }>
      <TableFilter
        onFilterChange={setFilters}
        filters={filers}
        disabled={isLoadingDetailed}
        onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
      />
      <SalesSummaryWidget data={summary} isLoading={isLoadingDetailed} />
      <SegmentsSummaryWidget data={filteredData} isLoading={isLoadingDetailed} />
    </Page>
  );
};

export default DashboardJourneys;
