import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { App } from './app';
import './index.css';
import { store } from 'src/store';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AuthProvider } from './contexts/auth/amplify-context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <ReduxProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </ReduxProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
