import { Box, Container, Typography } from '@mui/material';

export interface EmptyStateProps {
  img: string;
  header: string;
  description: string;
  size?: 'sm' | 'md' | 'lg';
}

export const EmptyState = (props: EmptyStateProps) => {
  const imageBySize = {
    sm: 100,
    md: 200,
    lg: 300
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
      <Box sx={{ p: 10 }}>
        <div style={{ display: 'grid', gap: 8, justifyItems: 'center' }}>
          <img src={props.img} width={imageBySize[props.size || 'md']} />
          <Typography variant="h5">{props.header}</Typography>
          <Typography variant="body1">{props.description}</Typography>
        </div>
      </Box>
    </Container>
  );
};
