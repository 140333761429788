import { Dialog, DialogTitle } from 'src/@design-system/dialog';
import React, { useEffect, useMemo } from 'react';
import CsvDownload from 'react-json-to-csv';
import { OrderDetails } from 'types/order-detail';
import { getExportConsolidatedColumns } from 'src/pages/transactions/orders/export/export-consolidated-columns';
import { getExportDetailedColumns } from 'src/pages/transactions/orders/export/export-detailed-columns';
import { useAuth } from 'src/hooks/use-auth';
import { RadioGroup } from 'src/@design-system/radio-group';

export type ExportType = 'consolidated' | 'detailed';

export type ExportTypeModalProps = {
  onCancel: () => void;
  isOpen: boolean;
  orders: OrderDetails[];
};

export const ExportTypeModal = ({ onCancel, isOpen, orders }: ExportTypeModalProps) => {
  const { user } = useAuth();
  const [open, setOpen] = React.useState(isOpen);
  const [exportType, setExportType] = React.useState<ExportType>('consolidated');

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setExportType('consolidated');
      setOpen(false);
    };
  }, []);

  const exportData = useMemo(() => {
    switch (exportType) {
      case 'consolidated':
        return getExportConsolidatedColumns(orders || [], user?.isAdmin);
      case 'detailed':
        return getExportDetailedColumns(orders || [], user?.isAdmin);

      default:
        throw new Error('Invalid export type');
    }
  }, [exportType, orders, user?.isAdmin]);

  const exportOptions = [
    {
      name: 'Consolidated',
      description: 'Export all orders with all items consolidated in one line',
      key: 'consolidated'
    },
    {
      name: 'Detailed',
      description: 'Export all items of an order in a separate lines',
      key: 'detailed'
    }
  ];

  return (
    <Dialog onClose={onCancel} open={open} size="lg">
      <DialogTitle onClose={onCancel}>Export Orders</DialogTitle>
      <div className="mt-2 text-white overflow-scroll text-sm min-w-lg w-full px-2">
        <div className="mt-2">
          <div className="text-gray-700">
            <h2 className="text-sm font-semibold">Select the report type you want to export</h2>
            <RadioGroup
              className="py-4"
              onSelect={(option) => setExportType(option.key as ExportType)}
              selected={exportOptions.find((opt) => opt.key === exportType) || exportOptions[0]}
              options={exportOptions}
            />
          </div>
          <div className="mt-5 grid grid-cols-2 gap-2 ">
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={onCancel}>
              Cancel
            </button>
            <div className="w-full">
              <CsvDownload
                filename={`orders-${exportType}_${new Date().toISOString()}.csv`}
                data={exportData || []}
                style={{ width: '100%' }}>
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 sm:col-start-2"
                  onClick={() => {
                    setOpen(false);
                    onCancel();
                  }}>
                  Download
                </button>
              </CsvDownload>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
