import { OrderDetails } from 'types/order-detail';
import { Country, State } from 'country-state-city';
import { formatDate, FormatDateOptions } from '@fullcalendar/core';
import { Badge } from 'src/@design-system/feedback/badge';
import cn from 'classnames';
import { faTicket } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { priceToCurrency } from 'src/utils/price-to-currency';
import { getOrderAmount } from 'src/@common/order/get-item-amout';
import { OrderEditGuestsForm } from 'src/pages/transactions/orders/details/edit/order/guests/order-guests';
import { useOrderDetails } from 'src/pages/transactions/orders/hooks/use-order-details';

export const formatDateOptions: FormatDateOptions = {
  locale: 'pt-BR',
  day: 'numeric',
  month: 'numeric',
  year: 'numeric'
};

export const OrderItems = ({ order }: { order?: OrderDetails }) => {
  const { isLoading, refetch, forms } = useOrderDetails();

  if (!order?.items) return <div>no items</div>;

  const { items } = order;

  const hasJourney = items.some((item) => item.journey);

  return (
    <>
      <OrderEditGuestsForm
        orderDetails={order}
        open={forms.guests.editGuests}
        orderId={order.id}
        onClose={() => forms.guests.setEditGuests(false)}
      />
      <div className="text-base font-semibold leading-6 text-gray-900 pt-4">Items</div>
      <div className="pb-2 divide-y divide-gray-200">
        {items.map((item, index) => {
          const journeyOutbound = item.journey?.segments?.find(
            (segment) => segment.type === 'outbound'
          );

          return (
            <div className="py-4 flex gap-2 items-start" key={item.id}>
              <div className="w-full">
                <div
                  className={cn(`grid w-full`, {
                    'grid-cols-2': !hasJourney,
                    'grid-cols-3': hasJourney
                  })}>
                  <div className="flex flex-col gap-1">
                    <div className="pb-2 font-semibold text-sm">Accommodation</div>
                    <div className="items-center text-sm leading-6 text-gray-900">
                      <span>{item.accommodation?.name}</span>
                    </div>
                    <div className="text-sm text-gray-500 flex gap-1  align-middle items-center">
                      {item.accommodation.room.name}{' '}
                      <span className="text-gray-500">({item.accommodation.room.capacity}P)</span>
                    </div>
                    <div className="text-sm text-gray-500 flex gap-1  align-middle items-center">
                      {formatDate(
                        `${item.accommodation.checkIn}T15:00:00-03:00`,
                        formatDateOptions
                      )}{' '}
                      -{' '}
                      {formatDate(
                        `${item.accommodation.checkOut}T12:00:00-03:00`,
                        formatDateOptions
                      )}
                    </div>
                  </div>
                  <div className={cn(hasJourney ? `justify-self-center text-center` : ``)}>
                    <div className="pb-2 font-semibold text-sm flex items-baseline gap-1">
                      <div>Guests</div>
                    </div>
                    <div className="flex flex-col gap-1">
                      {item.accommodation.room.guests?.map((guest) => (
                        <div
                          className="text-sm text-gray-900 flex gap-1 align-middle items-center text-right"
                          key={guest.id}>
                          {guest.firstName} {guest.lastName}
                        </div>
                      ))}
                      {/*<EditDetailsCTA*/}
                      {/*  onClick={() => forms.guests.setEditGuests(true)}*/}
                      {/*  text="Edit guests"*/}
                      {/*/>*/}
                    </div>
                  </div>
                  {item.journey ? (
                    <div className={cn(`justify-self-end`)}>
                      <div className="pb-2 font-semibold capitalize text-sm text-end">Journey</div>
                      <div className="flex flex-col justify-end">
                        <div className="text-sm text-gray-900">
                          {journeyOutbound?.departure.name}
                        </div>
                        <div className="mt-1 text-sm leading-5 text-gray-500">
                          <span className="text-gray-900 flex gap-1 justify-end">
                            <Badge
                              color={item.journey.type === 'flight' ? 'pink' : 'purple'}
                              value={item.journey.type}
                              size="sm"
                            />
                            <div className="truncate text-gray-500">
                              {
                                State.getStateByCodeAndCountry(
                                  item.journey.origin.state,
                                  item.journey.origin.country
                                )?.name
                              }
                              , {Country.getCountryByCode(item.journey.origin.country)?.name}
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="flex mt-3 justify-between text-sm text-gray-700 p-2 bg-gray-100 rounded-md">
                  <div className="flex gap-1 items-center">
                    <FontAwesomeIcon
                      className="h-5 w-5 shrink-0"
                      aria-hidden="true"
                      icon={faTicket}
                    />
                    {item.ticket.name}
                  </div>
                  <div className="flex gap-1">
                    Total price: {priceToCurrency(getOrderAmount(item), 'BRL')}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
