import { priceToCurrency } from 'src/utils/price-to-currency';
import CsvDownloadButton from 'react-json-to-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Table, TableRow } from 'src/@common/components/table';
import { DashboardJourney } from 'types/dashboard/journey';
import { LoadingContainer } from 'src/components/loading';
import { useMemo } from 'react';
import { StatusLabel, StatusLabelWidget } from 'src/pages/dashboard/common/status-label-widget';
import { Tooltip } from '@mui/material';

export const SegmentsSummaryWidget = ({
  data,
  isLoading
}: {
  data?: DashboardJourney[];
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  const regionNames = new Intl.DisplayNames(['US'], {
    type: 'region'
  });

  const columns: TableRow<DashboardJourney>[] = [
    {
      column: 'journeyName',
      columnName: 'Origin',
      className: 'font-semibold w-[400px] overflow-ellipsis',
      render: (data) => {
        const isSoldOut = data.inventory - data.sold <= 0;
        const isOnHold = !isSoldOut && data.available <= 0;
        return (
          <div className="flex gap-1 items-center font-semibold text-center">
            {data.journeyName}
            <StatusLabel isOnHold={isOnHold} isSoldOut={isSoldOut} />
          </div>
        );
      }
    },
    {
      column: 'journeyCountry',
      columnName: 'Country',
      className: 'w-[200px] overflow-ellipsis',
      render: (row) => regionNames.of(row.journeyCountry)
    },
    {
      column: 'journeyType',
      columnName: 'Type'
    },
    {
      column: 'inventory',
      columnName: 'Inventory'
    },
    {
      column: 'sold',
      columnName: 'Sold'
    },
    {
      column: 'available',
      columnName: 'Available'
    },
    {
      column: 'onHold',
      columnName: 'On Hold'
    },
    {
      column: 'grossRevenue',
      columnName: (
        <div className="flex gap-1 items-center">
          Total Sales
          <Tooltip title="Total of sales of bus/flight packages with total price including accommodation and ticket(s)">
            <FontAwesomeIcon className="text-gray-500" icon={faCircleQuestion} />
          </Tooltip>
        </div>
      ),
      render: (data) => priceToCurrency(data.grossRevenue, 'BRL')
    },
    {
      column: 'id',
      columnName: 'Actions',
      className: 'flex justify-end',
      render: (data) => (
        <div className="flex justify-end">
          <a href="#" className="text-indigo-600 hover:text-indigo-900 font-semibold">
            View<span className="sr-only">, {data.id}</span>
          </a>
        </div>
      )
    }
  ];

  // rooms by accommodation
  const journeysByType = useMemo(() => {
    return data?.reduce(
      (acc, journey) => {
        acc[journey.journeyType] = acc[journey.journeyType] || [];
        acc[journey.journeyType].push(journey);
        return acc;
      },
      {} as Record<string, DashboardJourney[]>
    );
  }, [data]);

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Summary</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of journeys and their respective types, inventory, sold, available, on hold and
            total sale gross.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <CsvDownloadButton
            filename={`jouneys-summary_${new Date().toISOString()}.csv`}
            data={data?.map((d) => {
              return {
                Origin: d.journeyName,
                Type: d.journeyType,
                Inventory: d.inventory,
                Sold: d.sold,
                Available: d.available,
                'On Hold': d.onHold,
                'Gross Sale (R$)': d.grossRevenue || 0
              };
            })}
            style={{ all: 'unset' }}>
            <div
              role="button"
              className="text-indigo-500 font-bold text-sm flex items-center gap-2">
              Export data
              <FontAwesomeIcon icon={faArrowDownToLine} />
            </div>
          </CsvDownloadButton>
        </div>
      </div>
      <div className="mt-4">
        <StatusLabelWidget />
      </div>
      <div className="mt-6 space-y-8">
        {isLoading ? (
          <LoadingContainer />
        ) : (
          Object.entries(journeysByType || {}).map(([accommodationUUID, journeys]) => {
            return (
              <div key={accommodationUUID}>
                <h2 className="font-bold capitalize flex items-center gap-1">
                  # {journeys[0].journeyType}
                </h2>
                <Table<DashboardJourney> className="" data={journeys || []} rows={columns} />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
