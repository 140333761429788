import type { FC } from 'react';
import { NoDataEmptyState } from 'src/components/dropdown/no-data-empty-state';
import { AccommodationRoomType } from 'types/accommodation';
import { useNavigate } from 'react-router-dom';
import { LocalizeButton } from 'src/components/localize-button';
import { useTranslation } from 'react-i18next';
import { Table, TableRow } from 'src/@common/components/table';

type Props = {
  data: AccommodationRoomType[];
};

const AccommodationTable: FC<Props> = (props) => {
  const { t } = useTranslation();
  const naviage = useNavigate();

  if (props.data.length === 0) {
    return <NoDataEmptyState type={'Rooms'} />;
  }

  const columns: TableRow<AccommodationRoomType>[] = [
    {
      columnName: 'Room Name',
      className: 'w-[400px]',
      render: (row) => {
        return (
          <div className="flex gap-1 items-center">
            <span>{row.name}</span>
            <LocalizeButton search={row.uuid} />
          </div>
        );
      }
    },
    {
      columnName: 'Category',
      render: (row) => {
        return <div className="max-w-md">{t(`accommodation.roomCategories.${row.category}`)}</div>;
      }
    },
    {
      columnName: 'Capacity',
      render: (row) => {
        return `${row.capacity}P`;
      }
    },

    {
      columnName: 'Availability',
      render: (row) => {
        return row.availability;
      }
    },
    {
      columnName: 'Meal Plan',
      render: (row) => {
        return t(`accommodation.mealPlans.${row.mealPlan}`);
      }
    },
    {
      columnName: 'Actions',
      className: 'justify-end text-right',
      render: (row) => {
        return (
          <div className="flex gap-1 justify-end">
            <button
              className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 items-center font-semibold"
              onClick={() => naviage(`/inventory/accommodation-rooms/edit/${row.uuid}`)}>
              View
            </button>
          </div>
        );
      }
    }
  ];

  return (
    <Table<AccommodationRoomType> className="table-fixed" data={props.data || []} rows={columns} />
  );
};

export default AccommodationTable;
