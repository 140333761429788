import { formatDate, FormatDateOptions } from '@fullcalendar/core';
import { LoadingContainer } from 'src/components/loading';
import { OrderLogs } from 'src/pages/transactions/orders/details/order-logs';
import { OrderItems } from 'src/pages/transactions/orders/details/order-items';
import { regionNames } from 'src/pages/transactions/common/helpers';
import { RoleBasedGuard } from 'src/guards/role-based-guard';
import { OrderDetailActions } from 'src/pages/transactions/orders/details/order-detail-actions';
import { useOrderDetails } from 'src/pages/transactions/orders/hooks/use-order-details';
import { OrdersContainer } from 'src/pages/transactions/orders/components/container';
import { BuyerAddressForm } from 'src/pages/transactions/orders/details/edit/buyer/buyer-address';
import { State } from 'country-state-city';
import { OrderLog } from 'src/@common/types';
import { OrderShippingDetailsForm } from 'src/pages/transactions/orders/details/edit/order/order-shipping';
import { ShippingOptionsProvider } from 'src/pages/transactions/orders/details/edit/order/providers/shipping-options-prodiver';

export const formatDateOptions: FormatDateOptions = {
  locale: 'pt-BR',
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};

export const EditDetailsCTA = ({
  onClick,
  text = 'Edit details'
}: {
  onClick: () => void;
  text?: string;
}) => {
  return (
    <div
      onClick={onClick}
      role="button"
      className="py-1 text-indigo-600 text-xs hover:text-indigo-500 cursor-pointer">
      {text}
    </div>
  );
};

export const OrderDetail = () => {
  const { data, isLoading, refetch, forms } = useOrderDetails();

  const orderDetials = data?.[0];

  if (isLoading) return <LoadingContainer />;

  if (!data || !orderDetials) return null;

  return (
    <div>
      <BuyerAddressForm
        customerId={orderDetials.customer.id}
        addressDetails={orderDetials.customer.address}
        open={forms.address.editAddress}
        orderId={orderDetials.id}
        onClose={() => forms.address.setEditAddress(false)}
      />
      <ShippingOptionsProvider>
        <OrderShippingDetailsForm
          orderDetials={orderDetials}
          open={forms.shipping.editShipping}
          orderId={orderDetials.id}
          onClose={() => forms.shipping.setEditShipping(false)}
        />
      </ShippingOptionsProvider>

      <OrdersContainer orderDetials={orderDetials} showOrderDetails showPayment>
        <div className="p-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 rounded-lg lg:col-span-2 w-full lg:row-end-2 pb-2">
          <div className="flex justify-between w-full">
            <div className="text-base font-semibold leading-6 text-gray-900">Order</div>
            <RoleBasedGuard permissions="admin">
              <OrderDetailActions
                orderId={orderDetials.id}
                confirmationCode={orderDetials.confirmationCode}
                orderStatus={orderDetials.status}
                callback={refetch}
              />
            </RoleBasedGuard>
          </div>
          <dl className="grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
            <div className="sm:pr-4 leading-7 ">
              <dt className="inline text-gray-500">Order placed on</dt>{' '}
              <dd className="inline text-gray-700">
                <time dateTime="2023-23-01">
                  {formatDate(orderDetials.createdAt, formatDateOptions)}
                </time>
              </dd>
            </div>
            <div className="sm:mt-0 sm:pl-4"></div>
            <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
              <dt className="font-semibold text-gray-900">Billing Address</dt>
              <dd className="mt-2 text-gray-500">
                <span className="font-medium text-gray-900">
                  {orderDetials.customer.address.address1}, {orderDetials.customer.address.address2}
                </span>
                <br />
                {orderDetials.customer.address.city},{' '}
                {
                  State.getStateByCodeAndCountry(
                    orderDetials.customer.address.state,
                    orderDetials.customer.address.country
                  )?.name
                }
                , {regionNames.of(orderDetials.customer.address.country)}
                <br />
                {orderDetials.customer.address.postalCode}
              </dd>
              <EditDetailsCTA onClick={() => forms.address.setEditAddress(true)} />
            </div>
            <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 -4 sm:pt-6">
              <dt className="font-semibold text-gray-900">Shipping Address</dt>
              {orderDetials.shipping && orderDetials.shipping?.method === 'collect' ? (
                <dd>Collect at {orderDetials.items[0].accommodation?.name}</dd>
              ) : orderDetials.shipping ? (
                <dd className="mt-2 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {orderDetials.shipping?.address?.address1},{' '}
                    {orderDetials.shipping?.address?.address2}
                  </span>
                  <br />
                  {orderDetials.shipping?.address?.city},{' '}
                  {
                    State.getStateByCodeAndCountry(
                      orderDetials.shipping?.address?.state,
                      orderDetials.shipping?.address?.country
                    )?.name
                  }
                  , {regionNames.of(orderDetials.shipping?.address?.country)}
                  <br />
                  {orderDetials.shipping?.address?.postalCode}
                </dd>
              ) : (
                '--'
              )}
              <EditDetailsCTA onClick={() => forms.shipping.setEditShipping(true)} />
            </div>
          </dl>
          <hr className="mt-4" />
          <OrderItems order={orderDetials} />

          {orderDetials.logs ? (
            <OrderLogs
              logs={(orderDetials.logs as OrderLog[])?.sort(
                (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
              )}
            />
          ) : null}
        </div>
      </OrdersContainer>
    </div>
  );
};
