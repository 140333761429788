import type { FC } from 'react';
import { NoDataEmptyState } from 'src/components/dropdown/no-data-empty-state';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocalizeButton } from 'src/components/localize-button';
import { AssetType } from 'types/assets';
import { Table, TableRow } from 'src/@common/components/table';

type Props = {
  data: AssetType[];
};

const AssetsTable: FC<Props> = (props) => {
  const naviage = useNavigate();

  if (props.data.length === 0) {
    return <NoDataEmptyState type={'Rooms'} />;
  }

  const columns: TableRow<AssetType>[] = [
    {
      columnName: 'Name',
      render: (row) => {
        return (
          <div className="flex gap-1 items-center font-semibold">
            <span>{row.name}</span>
            <LocalizeButton search={row.uuid} />
          </div>
        );
      }
    },
    {
      column: 'description',
      columnName: 'Description'
    },
    {
      columnName: 'Actions',
      render: (row) => {
        return (
          <div className="flex gap-1">
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={() => naviage(`/inventory/assets/edit/${row.uuid}`)}>
              View
            </Button>
            {/*<Button variant="text" color="error" size="small" onClick={console.log}>*/}
            {/*  Delete*/}
            {/*</Button>*/}
          </div>
        );
      }
    }
  ];

  return <Table<AssetType> className="mt-8" data={props.data || []} rows={columns} />;
};

export default AssetsTable;
