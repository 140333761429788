import { create } from 'zustand';
import { produce } from 'immer';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import toast from 'react-hot-toast';
import { TicketType } from 'types/ticket';
import { TicketRequest } from 'src/pages/inventory/tickets/add-new';

export const ImageType = {
  Image: 'image',
  Background: 'background'
} as const;

type useTicketsStoreProps = {
  isLoading: boolean;
  onSubmit: (data: TicketType, uuid?: string) => Promise<void>;
  setLoading: (isLoading: boolean) => void;
};

export const useTicketsStore = create<useTicketsStoreProps>((set, get) => ({
  isLoading: false,
  setLoading: (isLoading) =>
    set(
      produce<useTicketsStoreProps>((state) => {
        state.isLoading = isLoading;
      })
    ),
  onSubmit: async (data, uuid) => {
    get().setLoading(true);

    try {
      const verb = uuid ? 'put' : 'post';
      await apiFetch<TicketRequest>(API_ENDPOINT.Inventory.Tickets)[verb](data);
    } catch (error) {
      console.error('Error submitting accommodation request:', error);
      toast.error('Ops! An error ocurred, try again!');
    } finally {
      get().setLoading(false);
      toast.success(uuid ? 'Accomm. room updated sucessfully' : 'Accomm. room created sucessfully');
    }
  }
}));
