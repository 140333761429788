import { Page as PageType } from 'types/template/page';
import Table from './table';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { AccommodationRoomType, AccommodationType } from 'types/accommodation';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_PATHS } from 'src/paths';
import { RoleBasedGuard, ROLES } from 'src/guards/role-based-guard';
import { Page } from 'src/components/page';
import { TableFilter } from 'src/@common/components/table-filter';
import { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { trackFilterApplied } from 'src/@common/analytics/events';
import { LoadingContainer } from 'src/components/loading';

const HotelsList: PageType = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<{
    accommodation?: string[];
    type?: AccommodationType[];
    search?: string;
  }>({});
  const { isLoading, data } = useQuery<AccommodationRoomType[]>(['AccommodationRooms'], () => {
    return apiFetch(API_ENDPOINT.Inventory.AccommodationRooms).get();
  });

  const onAddNew = () => navigate(INVENTORY_PATHS.accommodation_rooms.new);

  useEffect(() => {
    trackFilterApplied(filters, 'inventory', 'accommodation-rooms');
  }, [filters]);

  const accommodationFilter = useMemo(() => {
    const uniqueIds = new Set();
    const options =
      data
        ?.filter((accommodation) => {
          const isDuplicate = uniqueIds.has(accommodation.accommodationUUID);
          uniqueIds.add(accommodation.accommodationUUID);
          return !isDuplicate;
        })
        .map((accommodation) => ({
          label: accommodation.accommodationName,
          value: accommodation.accommodationUUID
        })) || [];

    return {
      id: 'accommodation',
      name: 'Filter by Accommodation',
      options: options
    };
  }, [data]);

  const filteredData = useMemo(
    () =>
      data?.filter((data) => {
        if (filters.accommodation && filters.accommodation.length > 0) {
          return filters.accommodation.includes(data.accommodationUUID);
        }

        if (filters.search) {
          console.log('filters.search', filters.search);
          const search = filters.search.toLowerCase();
          return (
            data.accommodationName.toLowerCase().includes(search) ||
            data.name.toLowerCase().includes(search) ||
            data.category.toLowerCase().includes(search)
          );
        }

        return true;
      }),
    [data, filters.accommodation, filters.search]
  );

  // rooms by accommodation
  const accommodationRooms = useMemo(() => {
    return filteredData?.reduce(
      (acc, room) => {
        acc[room.accommodationUUID] = acc[room.accommodationUUID] || [];
        acc[room.accommodationUUID].push(room);
        return acc;
      },
      {} as Record<string, AccommodationRoomType[]>
    );
  }, [filteredData]);

  return (
    <>
      <Page
        title="Accomm. Rooms"
        description=""
        cta={
          <RoleBasedGuard permissions={ROLES.Admin}>
            <button
              className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 items-center"
              onClick={onAddNew}>
              Add new room
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </RoleBasedGuard>
        }>
        <TableFilter
          onFilterChange={setFilters}
          filters={[accommodationFilter]}
          onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
        />
        <div className="space-y-8">
          {isLoading ? (
            <LoadingContainer />
          ) : (
            Object.entries(accommodationRooms || {}).map(([accommodationUUID, rooms]) => {
              return (
                <div key={accommodationUUID}>
                  <h2 className="font-bold"># {rooms[0].accommodationName}</h2>
                  <Table data={rooms} />
                </div>
              );
            })
          )}
        </div>
      </Page>
    </>
  );
};

export default HotelsList;
