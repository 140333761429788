import type { FC } from 'react';
import { NoDataEmptyState } from 'src/components/dropdown/no-data-empty-state';
import { Accommodation } from 'types/accommodation';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { Button } from '@mui/material';
import { AccommodationCategoryChips } from './components/accomm-category-chip';
import { LocalizeButton } from 'src/components/localize-button';
import { useTranslation } from 'react-i18next';
import { Table, TableRow } from 'src/@common/components/table';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as faCircleCheckLight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  data: Accommodation[];
};

const AccommodationsTable: FC<Props> = (props) => {
  const { t } = useTranslation();
  const naviage = useNavigate();

  if (props.data.length === 0) {
    return <NoDataEmptyState type={'Rooms'} />;
  }

  const columns: TableRow<Accommodation>[] = [
    {
      columnName: 'Name',
      render: (row) => {
        return (
          <div className="flex gap-1 items-center font-semibold">
            <span>{row.name}</span>
            <LocalizeButton search={row.uuid} />
          </div>
        );
      }
    },
    {
      columnName: 'Type',
      render: (row) => {
        return t(`accommodation.type.${row.type}`);
      }
    },
    {
      columnName: 'Category',
      render: (row) => {
        return <AccommodationCategoryChips category={row.category} />;
      }
    },
    {
      column: 'mealPlan',
      columnName: 'Meal Plan'
    },
    {
      columnName: 'Address',
      render: (row) => {
        return (
          <div className="max-w-sm overflow-hidden text-ellipsis ">{row.location.address}</div>
        );
      }
    },
    {
      columnName: 'Live',
      render: (row) => {
        return (
          <FontAwesomeIcon
            className={cx(row?.live ? 'text-green-400' : '')}
            icon={row.live ? faCircleCheck : faCircleCheckLight}
          />
        );
      }
    },
    {
      columnName: 'Actions',
      render: (row) => {
        return (
          <div className="flex gap-1">
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={() => naviage(`/inventory/accommodations/edit/${row.uuid}`)}>
              View
            </Button>
            {/*<Button variant="text" color="error" size="small" onClick={console.log}>*/}
            {/*  Delete*/}
            {/*</Button>*/}
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Table<Accommodation> className="mt-8" data={props.data || []} rows={columns} />
    </>
  );
};

export default AccommodationsTable;
