import { Page as PageType } from 'types/template/page';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { Page } from 'src/components/page';
import React, { useMemo, useState } from 'react';
import { OrderStatusType } from 'types/order';
import { useTransactionsGuestsColumns } from './use-buyer-columns';
import { usePagination } from 'src/@common/components/hooks/usePagination';
import { TableFilter } from 'src/@common/components/table-filter';
import { LoadingContainer } from 'src/components/loading';
import { Table } from 'src/@common/components/table';
import { Pagination } from 'src/@common/components/pagination';
import { EmptyState } from 'src/components/empty-state';
import { GuestsFromList } from 'types/guests-list';
import { ExportGuestsButton } from 'src/pages/transactions/guests/export-button';
import { DashboardJourney } from 'types/dashboard/journey';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import LoadingButton from '@mui/lab/LoadingButton';
import { RoleBasedGuard } from 'src/guards/role-based-guard';

type TransactionsBuyerFilters = {
  search?: string;
  orderStatus?: OrderStatusType[];
  origin?: string[];
  accommodation?: string[];
};

const defaultOrdersFilters: TransactionsBuyerFilters = {
  search: undefined,
  orderStatus: ['confirmed']
};

export const TransactionsGuests: PageType = () => {
  const [filters, setFilters] = useState<TransactionsBuyerFilters>(defaultOrdersFilters);
  const [isUpdating, setIsUpdating] = useState(false);
  const [checkInGuestModal, updateCheckInGuestModal] = useState<{
    isOpen: boolean;
    guest?: GuestsFromList;
  }>({ isOpen: false, guest: undefined });

  const { data: journeyList } = useQuery<DashboardJourney[]>(['guestJourneysList'], async () => {
    return apiFetch('/dashboard/journeys').get();
  });

  const { isLoading, data, refetch } = useQuery<GuestsFromList[]>(['TransactionsGuests'], () => {
    return apiFetch(API_ENDPOINT.Transactions.Guests).get();
  });

  const submitCheckIn = async (guest: GuestsFromList) => {
    try {
      setIsUpdating(true);
      await apiFetch(`/order/guest/checkin/${guest.id}`).put({
        checkedIn: !guest.checkedIn,
        name: `${guest.firstName} ${guest.lastName}`,
        orderId: guest.orderId
      });
      await refetch();
      toast.success('Guest updated successfully');
    } catch (err) {
      toast.error('Error checking in guest');
    } finally {
      setIsUpdating(false);
      updateCheckInGuestModal({ isOpen: false, guest: undefined });
    }
  };

  const onCheckIn = async (guest: GuestsFromList) => {
    try {
      updateCheckInGuestModal({ isOpen: true, guest });
    } catch (err) {
      toast.error('Error checking in guest');
    }
  };

  const { columns } = useTransactionsGuestsColumns({
    isLoading: isLoading || isUpdating,
    onCheckIn
  });

  console.log('filters', filters);

  const filteredData = data?.filter((f) => {
    const search = filters.search ? filters.search.toLowerCase() : '';
    const origin = filters.origin && filters.origin.length > 0;
    const accommodation = filters.accommodation && filters.accommodation.length > 0;
    const guestName = `${f.firstName} ${f.lastName}`.toLowerCase();
    const mainBuyerName = f.customerName.toLowerCase();

    // Apply all filters with AND logic
    return (
      (!filters.search ||
        guestName.toLowerCase().includes(search) ||
        mainBuyerName.toLowerCase().includes(search) ||
        f.confirmationCode.toLowerCase().includes(search)) &&
      (!origin || filters?.origin?.includes(f.journeyName)) &&
      (!accommodation || filters?.accommodation?.includes(f.accommodationName))
    );
  });

  const { paginatedData, onNextPage, onPreviousPage, setPaginationPage, pagination } =
    usePagination<GuestsFromList>({
      data: filteredData || [],
      rowsPerPage: 20
    });

  const filtersDropdown = useMemo(() => {
    return [
      {
        id: 'accommodation',
        name: 'Accommodation',
        options: Array.from(new Set(data?.map((d) => d.accommodationName)) || []).map((name) => ({
          label: name,
          value: name
        }))
      },
      {
        id: 'origin',
        name: 'Flight/Bus',
        options: journeyList?.map((d) => ({ label: d.journeyName, value: d.journeyName })) || []
      }
    ];
  }, [data, journeyList]);

  return (
    <>
      <Dialog
        open={checkInGuestModal.isOpen}
        onClose={console.log}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {`${checkInGuestModal?.guest?.checkedIn ? 'Desfazer check-in para' : 'Check in'} ${checkInGuestModal.guest?.firstName} ${checkInGuestModal.guest?.lastName}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {checkInGuestModal?.guest?.checkedIn ? (
              <div>
                <p>
                  Certifique-se que nenhum bracelete, good bag ou qualquer outro item do festival
                  foi entregue ao cliente.
                </p>
              </div>
            ) : (
              <ul className="list-decimal px-6 text-sm">
                <li>Verifique o resumo da compra e os dados do cliente.</li>
                <li>Entregue os envelopes de acordo com a escolha de entrega do bracelete.</li>
                <li>
                  Explique as informações da carta de boas-vindas e peça que o cliente coloque o
                  bracelete do transfer.
                </li>
                <li>Entregue o goodie bag.</li>
              </ul>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isUpdating}
            onClick={async () => {
              updateCheckInGuestModal({ isOpen: false, guest: undefined });
            }}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isUpdating}
            disabled={isUpdating}
            variant="contained"
            color={checkInGuestModal?.guest?.checkedIn ? 'error' : 'success'}
            onClick={async () => {
              if (checkInGuestModal.guest) {
                await submitCheckIn(checkInGuestModal.guest);
              }
            }}
            autoFocus>
            {checkInGuestModal?.guest?.checkedIn ? 'Desfazer check-in' : 'Check-in'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Page
        title="Guests"
        description=""
        cta={
          <RoleBasedGuard permissions={'viwer'}>
            <ExportGuestsButton data={filteredData || []} />
          </RoleBasedGuard>
        }>
        <TableFilter
          defaultSelectedFilters={{ orderStatus: new Set(defaultOrdersFilters.orderStatus) }}
          filters={filtersDropdown}
          onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
          onFilterChange={(filters) => setFilters((prev) => ({ ...prev, ...filters }))}
        />

        {isLoading ? (
          <LoadingContainer />
        ) : paginatedData?.length > 0 ? (
          <>
            <Table<GuestsFromList> className="mt-8" data={paginatedData || []} rows={columns} />
            <Pagination
              page={pagination.page}
              onPageChange={(page) => setPaginationPage(page)}
              perPage={pagination.perPage}
              total={filteredData?.length || 0}
              onNext={onNextPage}
              onPrevious={onPreviousPage}
            />
          </>
        ) : (
          <EmptyState
            img="/assets/illustrations/no-data-found.svg"
            header={'No payments fund'}
            description={'It looks like there are no payments'}
          />
        )}
      </Page>
    </>
  );
};
