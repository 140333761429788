import { API } from 'aws-amplify';
import Cookies from 'js-cookie';
import { TENANT_COOKIE_ID } from 'src/layouts/dashboard/tenant-switch/tenant-provider';

export const API_URLS = {
  HOTELS: '/inventory/hotels',
  HOTELS_CATEGORY: '/inventory/hotels-category',
  HOTEL_ROOMS: '/inventory/hotel-rooms',
  EVENT_TICKETS: '/inventory/tickets',
  INVENTORY_ADDONS: '/inventory/add-ons',
  INVENTORY_BUSES: '/inventory/bus-tickets',
  EVENT_PACKAGES: '/event-packages',
  EVENT_TICKETS_TYPES: '/event/ticket/types',
  PACKAGE_GROUPS: '/packages-group',
  EVENT_PACKAGES_METADATA: '/event-packages-metadata'
};

export const API_ENDPOINT = {
  Inventory: {
    Accommodation: '/inventory/accommodation', // done
    AccommodationRooms: '/inventory/accommodation-rooms', // done
    Journeys: '/inventory/journeys',
    Tickets: '/inventory/tickets',
    Shipping: '/inventory/shipping',
    Assets: '/inventory/assets',
    AddOns: '/inventory/add-ons'
  },
  Transactions: {
    Orders: '/reports/orders',
    Payments: '/transactions/payments',
    Buyers: '/transactions/buyers',
    Guests: '/transactions/guests'
  },
  Orders: {
    Update: '/order/update'
  }
};

export const apiFetch = <T>(path: string) => {
  const tenant = Cookies.get(TENANT_COOKIE_ID);

  return {
    get: async (queryParams?: Record<string, string>, params: { [key: string]: any } = {}) => {
      return await API.get('storefrontAdminApi', `${path}?${new URLSearchParams(queryParams)}`, {
        queryStringParameters: {
          tenant: tenant
        },
        responseType: params?.responseType || 'json'
      });
    },
    post: async (body: T) =>
      await API.post('storefrontAdminApi', path, {
        body,
        queryStringParameters: {
          tenant: tenant
        }
      }),
    put: async (body: T, queryParams?: Record<string, string>) =>
      await API.put('storefrontAdminApi', `${path}?${new URLSearchParams(queryParams)}`, {
        body,
        queryStringParameters: {
          tenant: tenant
        }
      })
  };
};
