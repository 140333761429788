import { AnalyticsBrowser } from '@segment/analytics-next';
import { User } from 'types/template/user';

const isLocalDev = !process.env?.REACT_APP_BUILD_ENV;

export const analytics = AnalyticsBrowser.load({
  writeKey: 'WS3F4cwSzYt1SwFMmTEAEGsL5wo8nDhG'
});

export const indentify = (user?: User) => {
  if (user) {
    return analytics.identify(user.id, {
      name: user.name,
      email: user.email
    });
  }
};

export const track = (event: string, type: 'track' | 'page' = 'track', properties?: any) => {
  if (isLocalDev) return;

  try {
    void analytics.track(event, {
      type,
      page: {
        path: window.location.pathname,
        url: window.location.href,
        referrer: document.referrer,
        title: document.title,
        search: window.location.search
      },
      values: {
        env: process.env?.REACT_APP_BUILD_ENV,
        ...properties
      }
    });
  } catch (e) {
    console.error('Error tracking event', e);
  }
};
