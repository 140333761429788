export const useEnv = () => {
  const isProd = process.env?.NODE_ENV === "production";

  const baseUrlTicketshop = isProd
    ? "https://tomorrowlandbrasil.insidemusicland.com"
    : "http://tomorrowlandbrasil.insidemusicland.localhost:3006";

  return {
    env: process.env?.NODE_ENV,
    isProd,
    baseUrlTicketshop,
  };
};
