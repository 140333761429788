import { DashboardAccommodation } from 'types/dashboard/accommodation';
import { priceToCurrency } from 'src/utils/price-to-currency';
import { Table, TableRow } from 'src/@common/components/table';
import { LoadingContainer } from 'src/components/loading';
import { StatusLabel } from 'src/pages/dashboard/common/status-label-widget';
import { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';

export const SegmentsSummaryWidget = ({
  data,
  isLoading
}: {
  data?: DashboardAccommodation[];
  isLoading: boolean;
}) => {
  const columns: TableRow<DashboardAccommodation>[] = useMemo(
    () => [
      {
        column: 'roomName',
        columnName: 'Room Name',
        className: 'w-[400px] overflow-ellipsis',
        render: (data) => {
          const isSoldOut = data.inventory - data.sold <= 0;
          const isOnHold = !isSoldOut && data.available <= 0;

          return (
            <div className="flex gap-1 items-center">
              {data.roomName}
              <span className="pl-1 text-gray-500">({data.roomCapacity}P)</span>

              <StatusLabel isOnHold={isOnHold} isSoldOut={isSoldOut} />
            </div>
          );
        }
      },
      {
        column: 'inventory',
        columnName: 'Inventory'
      },
      {
        column: 'sold',
        columnName: 'Sold'
      },
      {
        column: 'available',
        columnName: 'Available'
      },
      {
        column: 'onHold',
        columnName: 'On Hold'
      },
      {
        column: 'grossRevenue',
        columnName: (
          <div className="flex gap-1 items-center">
            Total Sales
            <Tooltip title="Total of sales of accommodation packages with total price including flights/bus and ticket(s)">
              <FontAwesomeIcon className="text-gray-500" icon={faCircleQuestion} />
            </Tooltip>
          </div>
        ),
        render: (data) => priceToCurrency(data.grossRevenue, 'BRL')
      },
      {
        column: 'id',
        columnName: 'Actions',
        className: 'flex justify-end',
        render: (data) => (
          <div className="flex justify-end">
            <a href="#" className="text-indigo-600 hover:text-indigo-900 font-semibold">
              View<span className="sr-only">, {data.id}</span>
            </a>
          </div>
        )
      }
    ],
    []
  );

  return (
    <div className="px-0">
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <Table<DashboardAccommodation> className="table-auto" data={data || []} rows={columns} />
      )}
    </div>
  );
};
