export const getGatewayTransacationUrl = (gateway: string, intentId?: string, isProd?: boolean) => {
  if (!intentId) return '#';

  switch (gateway) {
    case 'stripe':
      return `${isProd ? 'https://dashboard.stripe.com/payments' : 'https://dashboard.stripe.com/test/payments'}/${intentId}`;
    case 'paypal':
      return `${isProd ? 'https://www.paypal.com' : 'https://www.sandbox.paypal.com'}/activity/payment/${intentId}`;

    case 'pagar.me':
      return `${isProd ? 'https://dash.pagar.me/merch_j1z35xwIYLtrp0kY/acc_BlkQYoS0ZUdvy5W0/orders' : 'https://dash.pagar.me/merch_j1z35xwIYLtrp0kY/acc_n3Ea7aACVfOr7prv/orders'}/${intentId}`;
    default:
      return '#';
  }
};
