import PageContainer from 'src/components/page-container';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { useParams } from 'react-router';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Journey } from 'types/journeys';
import { useJourneysStore } from 'src/pages/inventory/journeys/hooks/use-journeys-store';
import { AddNewForm } from 'src/pages/inventory/journeys/components/add-new-form';
import { INVENTORY_PATHS } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';
import { ROLES } from 'src/guards/role-based-guard';

export type JourneyRequest = Exclude<Journey, ''>;

const AddNew = () => {
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid?: string }>();
  const { user } = useAuth();

  const canEdit = user?.role === ROLES.Admin;

  /**
   * Load accomm. room by uuid
   */
  const { isLoading: isFetching, data } = useQuery<Journey>(
    ['JourneyDetails', uuid],
    () => {
      return apiFetch(API_ENDPOINT.Inventory.Journeys).get({ uuid: String(uuid) });
    },
    { enabled: Boolean(uuid) }
  );

  const { isLoading, onSubmit } = useJourneysStore((state) => ({
    onSubmit: state.onSubmit,
    isLoading: state.isLoading
  }));

  console.log(data);

  const form = useForm<Journey>({
    values: data,
    defaultValues: {
      uuid: crypto.randomUUID(),
      type: 'flight',
      price: { price: undefined, live: true, id: crypto.randomUUID() },
      origin: { country: 'BR' }
    }
  });

  console.log('form.error', form.formState.errors);

  const handleSubmit = useCallback(
    async (data: Journey) => {
      await onSubmit(data, uuid);
      navigate(INVENTORY_PATHS.journeys.list);
    },
    [navigate, onSubmit, uuid]
  );

  return (
    <PageContainer
      breadcrumbs={[
        { title: 'Inventory' },
        { title: 'Journey', href: INVENTORY_PATHS.journeys.list },
        { title: uuid ? 'Edit' : 'Add new' }
      ]}
      isLoading={isLoading || isFetching}
      seo="Inventory > Journey"
      title={uuid ? 'Edit journey' : 'New journey'}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={4}>
          <AddNewForm form={form} data={data} disabled={!canEdit} />
        </Stack>
        <div className="pt-6 flex justify-end">
          <Button variant="contained" type="submit" disabled={!canEdit}>
            Submit
          </Button>
        </div>
      </form>
    </PageContainer>
  );
};

export default AddNew;
