import { Menu, Transition } from '@headlessui/react';
import { Fragment, ReactElement } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

export type ActionsMenuProps = {
  ctaText?: string;
  items: Array<{ label: string; onClick: () => void; icon?: ReactElement; disabled?: boolean }>;
};

export const ActionsMenu = ({ items, ctaText = 'Actions' }: ActionsMenuProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md px-2 py-1 text-sm font-medium text-gray-700 hover:text-indigo-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
          {ctaText}
          <ChevronDownIcon
            className="-mr-1 ml-1 h-5 w-5 text-gray-700 hover:text-indigo-600 "
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="px-1 py-1 ">
            {items.map((item) => (
              <Menu.Item key={item.label}>
                {({ active }) => {
                  return (
                    <button
                      disabled={item.disabled}
                      onClick={item.onClick}
                      className={`${
                        active ? 'bg-violet-500 text-white' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm disabled:text-gray-400`}>
                      {item.icon ? <span className="mr-2 h-5 w-5">{item.icon}</span> : null}
                      {item.label}
                    </button>
                  );
                }}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
