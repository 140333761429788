import { useQuery } from 'react-query';
import { apiFetch } from 'src/api/helper/fetch';
import { DashboardSummaryByCountry, DashboardSummaryResponse } from 'types/dashboard/summary';
import { OrderPerDay, OrderPerHour } from 'src/pages/dashboard/summary/index';
import { useCallback } from 'react';

const SUMMARY_REFETCH_INTERVAL = 30000;

export const useSummaryData = (type: 'hour' | 'day') => {
  const {
    data: dataPerHour,
    isLoading: isLoadingPerHour,
    isFetching: isFetchingPerHour,
    refetch: refetchPerHour
  } = useQuery<OrderPerHour[] | OrderPerDay[]>(
    ['dashboard-summary', type],
    () =>
      apiFetch(`/dashboard/summary/${type === 'hour' ? 'orders-by-hour' : 'orders-by-day'}`).get({
        status: 'confirmed'
      }),
    {
      refetchInterval: SUMMARY_REFETCH_INTERVAL
    }
  );

  const {
    data: dataPerCountry,
    isLoading: isLoadingPerCountry,
    isFetching: isFetchingPerCountry,
    refetch: refetchPerCountry
  } = useQuery<DashboardSummaryByCountry[]>(
    ['dashboard-by-country'],
    () => apiFetch('/dashboard/summary/orders-by-country').get({ status: 'all' }),
    {
      refetchInterval: SUMMARY_REFETCH_INTERVAL
    }
  );

  const {
    data: summaryData,
    isLoading: isLoadingSummary,
    isFetching: isFetchingSummary,
    refetch: refetchSummary
  } = useQuery<DashboardSummaryResponse>(
    ['consolidated-sales'],
    () => apiFetch('/dashboard/summary/consolidated-sales').get({ status: 'confirmed' }),
    {
      refetchInterval: SUMMARY_REFETCH_INTERVAL
    }
  );

  const refetch = useCallback(async () => {
    await Promise.all([refetchPerHour(), refetchSummary(), refetchPerCountry()]);
  }, [refetchPerHour, refetchSummary, refetchPerCountry]);

  return {
    refetchAll: refetch,
    perHour: {
      data: dataPerHour,
      isLoading: isLoadingPerHour,
      isFetching: isFetchingPerHour,
      refetch: refetchPerHour
    },
    perCountry: {
      data: dataPerCountry,
      isLoading: isLoadingPerCountry,
      isFetching: isFetchingPerCountry,
      refetch: refetchPerCountry
    },
    summary: {
      data: summaryData,
      isLoading: isLoadingSummary,
      isFetching: isFetchingSummary,
      refetch: refetchSummary
    }
  };
};
