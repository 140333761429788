import { create } from 'zustand';
import { produce } from 'immer';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import toast from 'react-hot-toast';
import { Journey } from 'types/journeys';

type useJourneysStoreProps = {
  isLoading: boolean;
  onSubmit: (data: Journey, uuid?: string) => Promise<void>;
  setLoading: (isLoading: boolean) => void;
};

export const useJourneysStore = create<useJourneysStoreProps>((set, get) => ({
  isLoading: false,
  setLoading: (isLoading) =>
    set(
      produce<useJourneysStoreProps>((state) => {
        state.isLoading = isLoading;
      })
    ),
  onSubmit: async (data, uuid) => {
    get().setLoading(true);

    try {
      const verb = uuid ? 'put' : 'post';
      await apiFetch<Journey>(API_ENDPOINT.Inventory.Journeys)[verb](data);
    } catch (error) {
      console.error('Error submitting journey request:', error);
      toast.error('Ops! An error ocurred, try again!');
    } finally {
      get().setLoading(false);
      toast.success(uuid ? 'Journey updated sucessfully' : 'Journey created sucessfully');
    }
  }
}));
