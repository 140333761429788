import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { faCircleCheck, faCircleMinus } from '@fortawesome/pro-solid-svg-icons';

export const StatusLabelWidget = () => {
  return (
    <div className="text-xs grid grid-cols-2 divide-x divide-gray-200 px-1 py-1 bg-gray-100 rounded-md w-fit uppercase">
      <div className="flex items-center gap-1 px-2">
        <FontAwesomeIcon className={cx('text-red-400')} icon={faCircleCheck} /> Sold out
      </div>
      <div className="flex gap-1 items-center  px-2">
        <FontAwesomeIcon className={cx('text-yellow-400')} icon={faCircleMinus} /> On Hold
      </div>
    </div>
  );
};

export const StatusLabel = ({ isSoldOut, isOnHold }: { isSoldOut: boolean; isOnHold: boolean }) => {
  if (!isOnHold && !isSoldOut) return null;

  return (
    <FontAwesomeIcon
      className={cx(isSoldOut ? 'text-red-400' : 'text-yellow-400')}
      icon={isSoldOut ? faCircleCheck : faCircleMinus}
    />
  );
};
