import { Country, State } from 'country-state-city';

export const countryList = Country.getAllCountries().map((country) => ({
  value: country.isoCode,
  label: country.name
}));

export const getStateList = (countryCode: string) => {
  return State.getStatesOfCountry(countryCode).map((state) => ({
    value: state.isoCode,
    label: state.name
  }));
};
