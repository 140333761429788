import * as React from 'react';
import { ChangeEvent, FC, useId } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { JoyrneyTypeOptions } from 'src/pages/inventory/journeys/constants';

type AccTypeSelecInputProps = {
  disabled?: boolean;
  value?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
};

export const JourneyTypeSelectInput: FC<AccTypeSelecInputProps> = ({
  onChange,
  value,
  disabled
}) => {
  const id = useId();

  return (
    <FormControl>
      <FormLabel id={id}>Journey Type</FormLabel>
      <RadioGroup row value={value || null} onChange={onChange} aria-labelledby={id}>
        <FormControlLabel
          disabled={disabled}
          value={JoyrneyTypeOptions.Flight}
          control={<Radio size="small" />}
          label="Flight"
        />
        <FormControlLabel
          disabled={disabled}
          value={JoyrneyTypeOptions.Bus}
          control={<Radio size="small" />}
          label="Bus"
        />
      </RadioGroup>
    </FormControl>
  );
};
