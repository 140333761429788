import { OrderDetailsItem } from 'types/order-detail';

export const getOrderAmount = (
  orderItem: OrderDetailsItem,
  type: 'decimal' | 'cents' = 'decimal'
): number => {
  if (!orderItem?.accommodation?.room) return 0;

  const roomCapacity = orderItem.accommodation.room.capacity;

  const roomPrice = orderItem.accommodation.room.price;

  // Calculate total journey price, considering the room capacity
  const journeyPrice = (orderItem.journey?.price || 0) * roomCapacity;

  // Calculate total ticket price, considering the room capacity
  const ticketPrice = (orderItem.ticket?.price || 0) * roomCapacity;

  const total = roomPrice + journeyPrice + ticketPrice;

  return type === 'cents' ? total * 100 : total;
};
