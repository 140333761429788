import { Page as PageType } from 'types/template/page';
import { LoadingContainer } from 'src/components/loading';
import Table from './table';
import { Page } from 'src/components/page';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { useNavigate } from 'react-router-dom';
import { AssetType } from 'types/assets';
import { INVENTORY_PATHS } from 'src/paths';
import { RoleBasedGuard, ROLES } from 'src/guards/role-based-guard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { TableFilter } from 'src/@common/components/table-filter';
import { trackFilterApplied } from 'src/@common/analytics/events';

const AssetsList: PageType = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<{
    search?: string;
  }>({});
  const { isLoading, data } = useQuery<AssetType[]>(['AssetsList'], () => {
    return apiFetch(API_ENDPOINT.Inventory.Assets).get();
  });

  const onAddNew = () => navigate(INVENTORY_PATHS.assets.new);

  useEffect(() => {
    trackFilterApplied(filters, 'inventory', 'assets');
  }, [filters]);

  const filteredData = useMemo(
    () =>
      data?.filter((data) => {
        if (filters.search) {
          const search = filters.search.toLowerCase();
          return data.name.toLowerCase().includes(search);
        }

        return true;
      }),
    [data, filters.search]
  );

  return (
    <>
      <Page
        title="Assets"
        description=""
        cta={
          <RoleBasedGuard permissions={ROLES.Admin}>
            <button
              className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 items-center"
              onClick={onAddNew}>
              Add new asset
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </RoleBasedGuard>
        }>
        <TableFilter
          onFilterChange={setFilters}
          filters={[]}
          onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
        />
        {isLoading ? <LoadingContainer /> : <Table data={filteredData || []} />}
      </Page>
    </>
  );
};

export default AssetsList;
