import { LogDetails, OrderLog } from 'src/@common/types';
import { LogTypesEnum } from 'src/@common/constants';
import { Badge, BadgeColor } from 'src/@design-system/feedback/badge';
import { TFunction } from 'i18next';
import { ConfirmationEmailViwer } from 'src/@common/logs/component/confirmation-email-viwer';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderLogsDetails } from 'types/order-logs';

export const LogBadgeColor: Partial<Record<keyof LogDetails, BadgeColor>> = {
  [LogTypesEnum.CheckoutCreated]: 'blue',
  [LogTypesEnum.CheckoutStatusChanged]: 'pink',
  [LogTypesEnum.OrderCreated]: 'indigo',
  [LogTypesEnum.OrderStatusChanged]: 'warning',
  [LogTypesEnum.PaymentStatusChanged]: 'warning',
  [LogTypesEnum.PaymentLinkCreated]: 'neutral',
  [LogTypesEnum.PaymentLinkCreated]: 'neutral',
  OrderStatusUpdatedManual: 'danger',
  OrderPaymentUpdatedManual: 'danger'
};

const Container = ({
  text,
  badge,
  log
}: {
  text: string | ReactNode;
  badge?: string | null;
  log: OrderLog;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <span className="font-medium text-gray-900 pr-1">{text}</span>
      {badge ? (
        <Badge size="sm" value={badge} color={LogBadgeColor[log.logType] || 'indigo'} />
      ) : null}
    </>
  );
};

export const getOrderLogText = (log: OrderLog, t: TFunction) => {
  switch (log.logType) {
    case 'CheckoutCreated':
      return (
        <Container
          text="Checkout created"
          badge={t(`order.checkoutStatus.${log.details?.status}`)}
          log={log}
        />
      );
    case LogTypesEnum.CheckoutStatusChanged:
      return (
        <Container
          text="Checkout status changed to:"
          badge={t(`order.checkoutStatus.${log.details?.status}`)}
          log={log}
        />
      );
    case LogTypesEnum.OrderCreated:
      return (
        <Container text="Order created with LOC:" badge={log.details?.confirmationCode} log={log} />
      );
    case LogTypesEnum.OrderStatusChanged:
      return (
        <Container
          text="Order status changed to:"
          badge={t(`order.orderStatus.${log.details?.status}`)}
          log={log}
        />
      );
    case 'OrderStatusUpdatedManual':
      return (
        <Container
          text={`${log.details?.user.name} changed order status to:`}
          badge={t(`order.orderStatus.${log.details?.status}`)}
          log={log}
        />
      );
    case 'OrderGuestCheckin':
      const details = log.details as OrderLogsDetails['OrderGuestCheckin'];
      return (
        <Container
          text={`${details.user.name} changed checkin status  for ${details.name}:`}
          badge={t(details.checkedIn ? 'Checked in' : 'Unchecked')}
          log={log}
        />
      );
    case 'OrderPaymentUpdatedManual':
      return (
        <Container
          text={`${log.details?.user.name} changed payment status to:`}
          badge={t(`order.paymentStatus.${log.details?.status}`)}
          log={log}
        />
      );
    case LogTypesEnum.PaymentStatusChanged:
      return (
        <Container
          text={`${log.details?.user.name} changed payment status to:`}
          badge={t(`order.paymentStatus.${log.details?.status}`)}
          log={log}
        />
      );
    case LogTypesEnum.OrderConfirmationEmailSent:
      return <ConfirmationEmailViwer html={log?.details.email} />;
    case 'OrderCustomerUpdate':
      return (
        <Container
          text={
            <>
              {log.details?.user.name} updated customer details:{' '}
              {log.details.dirtyFields?.length > 0 ? (
                <>
                  {log.details.dirtyFields.map((f: string, index: number) => (
                    <span className="text-gray-500" key={`${f}`}>
                      {t(`formFields.customer.${f}`)}
                      {index < log.details.dirtyFields.length - 1 && ', '}
                    </span>
                  ))}
                </>
              ) : null}
            </>
          }
          log={log}
        />
      );
    case 'OrderAddressUpdate':
      return (
        <Container
          text={
            <>
              {log.details?.user.name} updated address details{' '}
              {log.details.dirtyFields?.length > 0 ? (
                <>
                  {log.details.dirtyFields.map((f: string, index: number) => (
                    <span className="text-gray-500">
                      {t(`formFields.address.${f}`)}
                      {index < log.details.dirtyFields.length - 1 && ', '}
                    </span>
                  ))}
                </>
              ) : null}
            </>
          }
          log={log}
        />
      );
    case 'OrderShippindUpdated':
      return (
        <Container
          text={
            <>
              {log.details?.user.name} updated shipping address{' '}
              {log.details.dirtyFields?.length > 0 ? (
                <>
                  {log.details.dirtyFields.map((f: string, index: number) => (
                    <span className="text-gray-500">
                      {t(`formFields.shipping.${f}`)}
                      {index < log.details.dirtyFields.length - 1 && ', '}
                    </span>
                  ))}
                </>
              ) : null}
            </>
          }
          log={log}
        />
      );
    case LogTypesEnum.PaymentLinkCreated:
      return null;
    default:
      return null;
  }
};
