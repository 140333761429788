import { OrderDetails } from 'types/order-detail';
import { getOrderAmount } from 'src/@common/order/get-item-amout';
import { Country, State } from 'country-state-city';

export const getExportDetailedColumns = (orders: OrderDetails[], isAdmin: boolean = false) => {
  const exportData: Record<string, string | number>[] = [];

  for (const order of orders) {
    //   let isFirstItem = true; // Flag to track whether we are processing the first item of the order

    const isShippingCollect = order.shipping.method === 'collect';

    const handleShippingFields = (field: string) => {
      if (isShippingCollect) {
        return '';
      }
      return field;
    };

    order.items.forEach((item) => {
      item.accommodation.room.guests.forEach((guest) => {
        const row: Record<string, string | number> = {
          'Order ID': order.id,
          Status: order.status,
          'Confirmation Code': order.confirmationCode,
          'Payment Gateway': order.payment?.gateway || '',
          'Main Buyer': `${order.customer.firstName} ${order.customer.lastName}`,
          'Main Buyer Email': order.customer.email,
          'Document Type': order.customer.documentType,
          'Document Value': order.customer.documentValue,
          'Shipping Method': order.shipping.method,
          'Shipping Address': handleShippingFields(
            `${order.shipping.address.address1 || ''}, ${order.shipping.address.address2 || ''}`
          ),
          'Shipping City': handleShippingFields(order.shipping.address.city || ''),
          'Shipping State': handleShippingFields(
            State.getStateByCodeAndCountry(
              order.shipping.address.state,
              order.shipping.address.country
            )?.name ||
              order.shipping.address.state ||
              ''
          ),
          'Shipping Country': handleShippingFields(
            Country.getCountryByCode(order.shipping.address.country)?.name ||
              order.shipping.address.country ||
              ''
          ),
          'Shipping Postal Code': handleShippingFields(order.shipping.address.postalCode || ''),
          Type: item.journey ? item.journey.type : 'hotel',
          Accommodation: item.accommodation?.name,
          Room: item.accommodation.room.name,
          'Room Capacity': item.accommodation.room.capacity,
          Ticket: item.ticket.name,
          Journey: item.journey?.segments[0].departure.name ?? 'N/A',
          'Check-in': item.accommodation?.checkIn,
          'Check-out': item.accommodation?.checkOut,
          'Package Price (R$)': getOrderAmount(item),
          'Created At': new Date(order.createdAt).toLocaleString()
        };

        exportData.push(row);
      });

      //  isFirstItem = false;
    });
  }

  return exportData;
};
