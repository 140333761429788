import { Seo } from 'src/components/seo';
import { Box, Breadcrumbs, Card, Container, Link, Stack, Typography } from '@mui/material';
import { BreadcrumbsSeparator } from 'src/components/breadcrumbs-separator';
import { RouterLink } from 'src/components/router-link';
import React, { FC } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

type PageContainerProps = {
  seo: string;
  title: string;
  children: React.ReactNode;
  breadcrumbs?: { title: string; href?: string }[];
  cta?: React.ReactNode;
  wrapped?: boolean;
  isLoading?: boolean;
  loadingText?: string;
};

const renderBreadcrumbs = (breadcrumbs: PageContainerProps['breadcrumbs']) => {
  if (breadcrumbs) {
    return (
      <Breadcrumbs separator={<BreadcrumbsSeparator />}>
        {breadcrumbs.map((breadcrumb) => {
          if (breadcrumb.href) {
            return (
              <Link
                color="text.primary"
                component={RouterLink}
                href={breadcrumb.href}
                variant="subtitle2">
                {breadcrumb.title}
              </Link>
            );
          }
          return (
            <Typography color="text.secondary" variant="subtitle2">
              {breadcrumb.title}
            </Typography>
          );
        })}
      </Breadcrumbs>
    );
  }

  return null;
};

const PageContainer: FC<PageContainerProps> = ({
  seo,
  title,
  breadcrumbs,
  children,
  cta,
  wrapped = false,
  isLoading = false,
  loadingText = 'Loading...'
}) => {
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <Stack direction="column" alignItems="center" gap={1}>
          <CircularProgress color="inherit" />
          <Typography>{loadingText}</Typography>
        </Stack>
      </Backdrop>
      <Seo title={seo} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth="xl">
          <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <div>
                  <Typography variant="h4">{title}</Typography>
                </div>
                {renderBreadcrumbs(breadcrumbs)}
              </Stack>
              <Stack alignItems="center" direction="row" spacing={3}>
                {cta || null}
              </Stack>
            </Stack>
            {wrapped ? <Card sx={{ p: 1 }}>{children}</Card> : children}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default PageContainer;
