import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine, faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { GuestsFromList } from 'types/guests-list';
import { exportReportHandler } from 'src/utils/export-report-handler';
import { useCallback, useState } from 'react';
import cn from 'classnames';
import toast from 'react-hot-toast';

export const ExportGuestsButton = ({ data }: { data: GuestsFromList[] }) => {
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = useCallback(async () => {
    try {
      setIsExporting(true);
      await exportReportHandler('guests');
      toast.success('Report exported successfully');
    } catch (error) {
      toast.error('There was an error exporting the report');
    } finally {
      setIsExporting(false);
    }
  }, []);

  return (
    <button
      disabled={isExporting}
      className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 items-center"
      onClick={handleExport}>
      Export table
      <FontAwesomeIcon
        icon={isExporting ? faArrowsRotate : faArrowDownToLine}
        className={cn({
          'animate-spin': isExporting,
          'text-indigo-500': isExporting
        })}
      />
    </button>
  );
};
