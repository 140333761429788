import cn from 'classnames';

type ButtonGroup = {
  children: ReturnType<typeof ButtonGroupItem>[];
  className?: string;
};

export const ButtonsGroup = ({ children, className }: ButtonGroup) => {
  return (
    <span className={cn('inline-flex rounded-md', className)}>
      <div>{children}</div>
      <div className="relative right-4 top-2 bg-amber-200 rounded-xl text-xs h-fit px-1 font-light z-20">
        new
      </div>
    </span>
  );
};

type ButtonGroupItemProps = {
  onClick: () => void;
  children: React.ReactNode | string;
  active?: boolean;
};
export const ButtonGroupItem = ({ children, onClick, active }: ButtonGroupItemProps) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={cn(
        'text-xs relative inline-flex items-center px-3 py-1  font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10',
        'first:rounded-l-lg last:rounded-r-lg',
        active ? 'bg-indigo-100' : 'bg-white'
      )}>
      {children}
    </button>
  );
};
