import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { ActionsMenu } from 'src/@design-system/actions-menu';
import { create } from 'zustand';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OrderActionModal } from 'src/pages/transactions/orders/details/order-detail-actions/modal';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import toast from 'react-hot-toast';
import { OrderUpdateRequest } from 'types/order-update';
import { OrderStatusType } from 'types/order';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

type ActionModals = 'cancelOrder';
type OrderActionModalType = {
  id: ActionModals;
  type: 'error' | 'info';
  callback: () => void;
} | null;

const useOrderDetailActions = create<{
  isLoading: boolean;
  openedModal: OrderActionModalType;
  setModalOpen: (modal: OrderActionModalType) => void;
}>((set) => ({
  isLoading: false,
  openedModal: null,
  setModalOpen: (modal) => set({ openedModal: modal })
}));

export const OrderDetailActions = ({
  orderId,
  confirmationCode,
  orderStatus,
  callback
}: {
  orderId: string;
  confirmationCode: string;
  orderStatus: OrderStatusType;
  callback?: () => void;
}) => {
  const naviagte = useNavigate();
  const { t } = useTranslation();
  const { openedModal, setModalOpen, isLoading } = useOrderDetailActions();

  const handleOnCancelOrder = useCallback(async () => {
    try {
      useOrderDetailActions.setState({ isLoading: true });
      await apiFetch<OrderUpdateRequest>(`${API_ENDPOINT.Orders.Update}/${orderId}`).put({
        status: 'cancelled'
      });
      toast.success('Order updated successfully!');
    } catch (error) {
      toast.error('Something went wrong!');
      console.error('Error while cancelling order:', error);
    } finally {
      setModalOpen(null);
      callback?.();
      useOrderDetailActions.setState({ isLoading: false });
    }
  }, [callback, orderId, setModalOpen]);

  return (
    <>
      <OrderActionModal
        title={t(`orderActions.${openedModal?.id}.title`, { confirmationCode })}
        isOpen={Boolean(openedModal)}
        onClose={() => setModalOpen(null)}>
        <div className="text-sm py-2">
          <Trans i18nKey={`orderActions.${openedModal?.id}.message`} />
        </div>
        <div className="flex justify-end gap-2 pt-4">
          <button
            disabled={isLoading}
            className="px-3 py-1 bg-gray-200 rounded-md text-black hover:bg-gray-300 disabled:bg-gray-100 disabled:cursor-not-allowed"
            onClick={() => setModalOpen(null)}>
            Close
          </button>
          <button
            disabled={isLoading}
            className="px-3 py-1 bg-red-500 rounded-md text-white hover:bg-red-600 disabled:bg-red-400 disabled:cursor-not-allowed flex items-center gap-1"
            onClick={openedModal?.callback}>
            {t(`orderActions.${openedModal?.id}.confirmCta`)}
            {isLoading ? (
              <FontAwesomeIcon
                className={cn('text-indigo-600', {
                  'animate-spin': isLoading
                })}
                icon={faSpinner}
              />
            ) : null}
          </button>
        </div>
      </OrderActionModal>
      <ActionsMenu
        items={[
          {
            disabled: orderStatus === 'cancelled',
            label: 'Cancel Order',
            icon: <FontAwesomeIcon icon={faBan} />,
            onClick: () =>
              setModalOpen({
                id: 'cancelOrder',
                type: 'error',
                callback: handleOnCancelOrder
              })
          }
        ]}
      />
    </>
  );
};
