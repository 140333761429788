import { Box, CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

export const LoadingContainer = ({
  children,
  isLoading = true
}: {
  children?: ReactNode;
  isLoading?: boolean;
}) => {
  return isLoading ? (
    <div className="flex items-center align-middle justify-center">
      <Box sx={{ p: 12 }}>
        <CircularProgress />
      </Box>
    </div>
  ) : (
    <div>{children}</div>
  );
};
