import { OrderSummary } from 'src/pages/transactions/orders/components/order-summary';
import { OrderDetails } from 'types/order-detail';
import { RoleBasedGuard } from 'src/guards/role-based-guard';
import { OrderPayment } from 'src/pages/transactions/orders/details/order-payment';

type OrdersContainerProps = {
  children?: React.ReactNode;
  showOrderDetails?: boolean;
  showPayment?: boolean;
  orderDetials?: OrderDetails;
};

export const OrdersContainer = ({
  children,
  orderDetials,
  showPayment,
  showOrderDetails
}: OrdersContainerProps) => {
  return (
    <main>
      <div className="mx-auto my-12 px-8">
        <div className="mx-auto flex gap-12 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none">
          <div className="sticky top-20 h-screen  w-full max-w-md">
            {showOrderDetails && orderDetials ? <OrderSummary orderDetails={orderDetials} /> : null}
            {showPayment && orderDetials ? (
              <RoleBasedGuard permissions="admin">
                <div className="pt-4">
                  <OrderPayment order={orderDetials} />
                </div>
              </RoleBasedGuard>
            ) : null}
          </div>
          <div className="w-full lg:row-end-2 pb-2 flex flex-col gap-8 max-w-5xl">{children}</div>
        </div>
      </div>
    </main>
  );
};
