import { useFormDrawer } from 'src/@design-system/form/container';
import { OrderDetails, OrderDetailsShipping } from 'types/order-detail';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiFetch } from 'src/api/helper/fetch';
import toast from 'react-hot-toast';
import { useOrderDetails } from 'src/pages/transactions/orders/hooks/use-order-details';
import { useAuth } from 'src/hooks/use-auth';
import { RadioGroup, RadioGroupOption } from 'src/@design-system/radio-group';
import { countryList, getStateList } from 'src/@common/country-state';
import { Controller } from 'react-hook-form';
import { useShippingOptions } from 'src/pages/transactions/orders/details/edit/order/hooks/use-shipping-store';
import { Alert } from '@mui/material';
import { priceToCurrency } from 'src/utils/price-to-currency';
import { Badge } from 'src/@design-system/feedback/badge';

type BuyerDetailsFormProps = {
  orderDetials: OrderDetails;
  open: boolean;
  orderId: string;
  onClose: () => void;
};

export const OrderShippingDetailsForm = ({
  orderId,
  orderDetials,
  open,
  onClose
}: BuyerDetailsFormProps) => {
  const { options: shippingOptions } = useShippingOptions();
  const { shipping } = orderDetials;
  const { refetch } = useOrderDetails();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  const onSubmit = useCallback(
    async (data: OrderDetailsShipping, dirtyFields: string[]) => {
      setIsLoading(true);

      try {
        await apiFetch(`/order/shipping/${orderId}`).put({
          shipping: data,
          dirtyFields,
          updatedFields: Object.keys(data)
        });
        onClose();
        toast.success('Shipping details has been saved!');
        await refetch();
      } catch (error) {
        toast.error('An error occurred while saving your changes. Please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [onClose, orderId, refetch]
  );

  const { FormDrawer, form } = useFormDrawer<OrderDetailsShipping>({
    defaultValues: orderDetials.shipping,
    disabled: isLoading || !user?.isAdmin,
    rules: {
      required: true
    }
  });

  const shippingMethods: RadioGroupOption[] = [
    {
      name: `Collect at ${orderDetials.items[0].accommodation?.name}`,
      description: 'The order items will be collected at the accommodation',
      key: 'collect'
    },
    {
      name: 'Ship',
      description: 'The order items will be shipped to the address below',
      key: 'ship'
    }
  ];

  const country = form.watch('address.country');
  const state = form.watch('address.state');
  const shippingMethod = form.watch('method');

  const shippingOption = useMemo(() => {
    return shippingOptions.find((opt) => {
      if (opt.type === 'domestic') return opt.country === country && opt.state === state;
      return opt.country === country;
    });
  }, [country, shippingOptions, state]);

  useEffect(() => {
    const option = shippingOptions.find((opt) => {
      if (opt.type === 'domestic') return opt.country === country && opt.state === state;
      return opt.country === country;
    });

    if (option) {
      form.setValue('option', option);
    }
  }, [country, form, shippingOptions, state]);

  const shippingDif =
    shippingOption && shipping.option ? shippingOption?.price - shipping.option?.price : 0;

  return (
    <FormDrawer
      form={form}
      disabled={isLoading || !user?.isAdmin}
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      title="Order Shipping"
      description="Edit shipping details from the order"
      onSaved={onSubmit}
      className="block">
      <div>
        <div className="w-full">
          <Controller
            defaultValue={shipping.method}
            control={form.control}
            render={({ field }) => (
              <RadioGroup
                className="w-full"
                options={shippingMethods}
                onSelect={(option) => field.onChange(option.key)}
                selected={shippingMethods.find((opt) => opt.key === field.value)}
              />
            )}
            name="method"
          />
        </div>
        {shippingMethod === 'ship' ? (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-6 pb-8">
            <FormDrawer.Input
              label="Name"
              name="name"
              // rules={{
              //   validate: (value) =>
              //     new RegExp(/^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/i).test(value) ||
              //     'Only letters and ".", "-" are allowed'
              // }}
            />
            <FormDrawer.Input
              label="Address 1"
              name="address.address1"
              // rules={{
              //   validate: (value) =>
              //     new RegExp(/^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/i).test(value) ||
              //     'Only letters and ".", "-" are allowed'
              // }}
            />
            <FormDrawer.Input
              label="Address 2"
              name="address.address2"
              // rules={{
              //   required: false,
              //   validate: (value) =>
              //     new RegExp(/^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/i).test(value) ||
              //     'Only letters and ".", "-" are allowed'
              // }}
            />
            <FormDrawer.Combobox label="Country" name="address.country" options={countryList} />
            <FormDrawer.Combobox
              label="State"
              name="address.state"
              options={getStateList(country)}
              rules={{ required: true }}
            />
            <FormDrawer.Input
              label="City"
              name="address.city"
              // rules={{
              //   validate: (value) =>
              //     new RegExp(/^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/i).test(value) ||
              //     'Only letters and ".", "-" are allowed'
              // }}
            />
            <FormDrawer.Input
              label="Postal Code"
              name="address.postalCode"
              // rules={{
              //   validate: (value) =>
              //     new RegExp(/^[a-zA-Z0-9\-,.]+$/i).test(value) ||
              //     'Only letters and "-" are allowed'
              // }}
            />
          </div>
        ) : null}
        {shippingMethod === 'ship' && shippingOption && shippingDif !== 0 ? (
          <div className="p-3 bg-gray-100 space-y-2 rounded-md">
            <div className="flex justify-between text-sm">
              <div className="flex items-center gap-1">
                Shipping {shippingOption.courier}
                <Badge value={shippingOption.type} color="purple" size="sm" />
              </div>
              <div>{priceToCurrency(shippingOption.price, 'BRL')}</div>
            </div>
            <Alert severity="info" sx={{ py: 0 }}>
              Price is {priceToCurrency(Math.abs(shippingDif), 'BRL')}{' '}
              {shippingDif < 0 ? 'lower' : 'higher'} than the paid amount in the order.
            </Alert>
          </div>
        ) : null}
      </div>
    </FormDrawer>
  );
};
