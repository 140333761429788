export const INVENTORY_PATHS = {
  accommodation: {
    list: '/inventory/accommodations/list',
    new: '/inventory/accommodations/new',
    edit: '/inventory/accommodations/edit/:uuid'
  },
  accommodation_rooms: {
    list: '/inventory/accommodation-rooms/list',
    new: '/inventory/accommodation-rooms/new',
    edit: '/inventory/accommodation-rooms/edit/:uuid'
  },
  tickets: {
    list: '/inventory/tickets/list',
    new: '/inventory/tickets/new',
    edit: '/inventory/tickets/edit/:uuid'
  },
  assets: {
    list: '/inventory/assets/list',
    new: '/inventory/assets/new',
    edit: '/inventory/assets/edit/:uuid'
  },
  addOns: {
    list: '/inventory/add-ons/list',
    new: '/inventory/add-ons/new',
    edit: '/inventory/add-ons/edit/:uuid'
  },
  journeys: {
    list: '/inventory/journeys/list',
    new: '/inventory/journeys/new',
    edit: '/inventory/journeys/edit/:uuid'
  },
  shipping: {
    list: '/inventory/shipping/list',
    new: '/inventory/shipping/new',
    edit: '/inventory/shipping/edit/:uuid'
  }
};

export const TRANSACATIONS_PATH = {
  orders: {
    index: '/transactions/orders',
    orderDetails: '/transactions/orders/details/:uuid',
    orderEdit: '/transactions/orders/edit/:uuid'
  },
  payments: {
    index: '/transactions/payments'
  },
  buyers: {
    index: '/transactions/buyers'
  },
  guests: {
    index: '/transactions/guests'
  }
};

export const DASHBOARD_PATHS = {
  summary: {
    index: '/dashboard/summary'
  },
  accommodation: {
    index: '/dashboard/accommodations'
  },
  journeys: {
    index: '/dashboard/journeys'
  },
  tickets: {
    index: '/dashboard/tickets'
  }
};
