import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OrderPerDay, OrderPerHour } from 'src/pages/dashboard/summary/index';
import { LoadingContainer } from 'src/components/loading';
import { useMemo } from 'react';

const formatter = new Intl.DateTimeFormat('en', {
  hour: '2-digit',
  hour12: false, // Use 24-hour time without AM/PM
  timeZone: 'UTC' // Adjust this according to the relevant time zone if necessary
});

export const OrderLastHours = ({
  isLoading,
  data,
  type
}: {
  type: 'hour' | 'day';
  data: OrderPerHour[] | OrderPerDay[];
  isLoading: boolean;
}) => {
  const options: Highcharts.Options = useMemo(() => {
    return {
      credits: { enabled: false },
      plotOptions: {
        spline: {
          lineWidth: 1,
          marker: {
            radius: 3, // Increased marker radius
            enabled: true,
            symbol: 'circle'
          },
          states: {
            hover: {
              lineWidth: 4
            }
          },
          fillOpacity: 0.1
        }
      },
      title: {
        text: ''
      },
      chart: {
        height: 350,
        margin: [60, 50, 120, 80], // Adjusted for better spacing
        zoomType: 'x', // Enable zooming along the x-axis
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light background color for better readability
        style: {
          fontFamily: 'Arial, sans-serif' // Set a readable font
        }
      },
      xAxis: {
        categories: data.map((d) => {
          if (type === 'hour') {
            const date = new Date(`${(d as OrderPerHour).hour}Z`);
            return `${date.getHours()}h`;
          }

          const formatter = new Intl.DateTimeFormat('en', {
            day: '2-digit',
            month: 'short'
          });

          return formatter.format(new Date(`${(d as OrderPerDay).day}Z`));
        })
      },
      yAxis: {
        title: {
          text: null
        }
      },
      tooltip: {
        shared: true,
        valueSuffix: ' orders',
        backgroundColor: 'rgba(0,0,0,0.85)',
        style: {
          color: '#FFFFFF'
        }
      },
      series: [
        {
          name: 'Confirmed',
          color: '#4d7c0f',
          data: data.map((d) => Number(d.confirmed)),
          type: 'spline'
        },
        {
          name: 'Cancelled',
          color: '#ef4444',
          data: data.map((d) => Number(d.cancelled)),
          type: 'spline'
        },
        {
          name: 'Total orders',
          color: '#fde68a',
          data: data.map((d) => Number(d.totalOrders)),
          type: 'spline'
        }
      ]
    };
  }, [data]);

  return (
    <>
      <div className="">
        {isLoading ? (
          <LoadingContainer />
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </div>
    </>
  );
};
