import { SegmentsSummaryWidget } from 'src/pages/dashboard/accommodations/sales-detailed';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine, faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { DashboardAccommodation as DashboardAccommodationType } from 'types/dashboard/accommodation';
import { apiFetch } from 'src/api/helper/fetch';
import { useCallback, useMemo, useState } from 'react';
import { SalesSummaryWidget } from 'src/pages/dashboard/common/summary-widget';
import { AccommodationType } from 'types/accommodation';
import { useTranslation } from 'react-i18next';
import { TableFilter } from 'src/@common/components/table-filter';
import { Page } from 'src/components/page';
import { trackFilterApplied } from 'src/@common/analytics/events';
import CsvDownloadButton from 'react-json-to-csv';
import { LoadingContainer } from 'src/components/loading';
import { StatusLabelWidget } from 'src/pages/dashboard/common/status-label-widget';

const DASHBOARD_REFETCH_INTERVAL = 30000;

const DashboardAccommodation = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<{
    accommodation?: string[];
    type?: AccommodationType[];
    search?: string;
  }>({});

  const {
    data: detailedData,
    isLoading: isLoadingDetailed,
    isFetching: isFetchingDetailed,
    refetch: refetchDetailed
  } = useQuery<DashboardAccommodationType[]>(
    ['DashboardAccommodationTypeDetailed'],
    async () => {
      return apiFetch('/dashboard/accommodations').get();
    },
    {
      refetchInterval: DASHBOARD_REFETCH_INTERVAL
    }
  );

  const filteredData = detailedData?.filter((data) => {
    trackFilterApplied(filters, 'dashboard', 'accommodation');

    if (filters.accommodation && filters.accommodation.length > 0) {
      return filters.accommodation.includes(data.accommodationId);
    }
    if (filters.type && filters.type.length > 0) {
      return filters.type.includes(data.accommodationType);
    }

    if (filters.search) {
      const search = filters.search.toLowerCase();

      return (
        data.accommodationName.toLowerCase().includes(search) ||
        data.roomName.toLowerCase().includes(search)
      );
    }

    return true;
  });

  const onTriggerRefetch = useCallback(async () => {
    await refetchDetailed();
  }, [refetchDetailed]);

  const summary = filteredData?.reduce(
    (acc, journey) => {
      return {
        totalSold: acc.totalSold + Number(journey.sold),
        totalOnHold: acc.totalOnHold + Number(journey.onHold),
        totalAvailable: acc.totalAvailable + Number(journey.available),
        salesGrossRevenue: acc.salesGrossRevenue + Number(journey.grossRevenue || 0),
        totalCharges: acc.totalCharges + Number(journey.totalCharges || 0)
      };
    },
    {
      totalSold: 0,
      totalOnHold: 0,
      totalAvailable: 0,
      totalCharges: 0,
      salesGrossRevenue: 0
    }
  );

  const accommodationFilter = useMemo(() => {
    const uniqueIds = new Set();
    const options =
      detailedData
        ?.filter((accommodation) => {
          const isDuplicate = uniqueIds.has(accommodation.accommodationId);
          uniqueIds.add(accommodation.accommodationId);
          return !isDuplicate;
        })
        .map((accommodation) => ({
          label: accommodation.accommodationName,
          value: accommodation.accommodationId
        })) || [];

    return {
      id: 'accommodation',
      name: 'Accommodation',
      options: options
    };
  }, [detailedData]);

  const filterOptions = useMemo(() => {
    return [
      {
        id: 'type',
        name: 'Type',
        options: Array.from(new Set(detailedData?.map((d) => d.accommodationType)) || []).map(
          (type) => ({
            label: t(`accommodation.type.${type}`),
            value: type
          })
        )
      },
      accommodationFilter
    ];
  }, [accommodationFilter, detailedData, t]);

  // rooms by accommodation
  const accommodationRooms = useMemo(() => {
    return filteredData?.reduce(
      (acc, room) => {
        acc[room.accommodationId] = acc[room.accommodationId] || [];
        acc[room.accommodationId].push(room);
        return acc;
      },
      {} as Record<string, DashboardAccommodationType[]>
    );
  }, [filteredData]);

  return (
    <Page
      title="Accommodation"
      cta={
        <div role="button" onClick={onTriggerRefetch}>
          <FontAwesomeIcon
            className={cn('text-indigo-600', {
              'animate-spin': isFetchingDetailed
            })}
            icon={faArrowsRotate}
          />
        </div>
      }>
      <TableFilter
        onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
        onFilterChange={setFilters}
        filters={filterOptions}
        disabled={isLoadingDetailed}
      />
      <SalesSummaryWidget data={summary} isLoading={isLoadingDetailed} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Summary</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of accommodations and their respective rooms, inventory, sold, available, on hold
            and total sale gross.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <CsvDownloadButton
            filename={`accommodations-summary_${new Date().toISOString()}.csv`}
            data={filteredData?.map((d) => {
              return {
                Acommodation: d.accommodationName,
                Room: d.roomName,
                'Room Capacity': d.roomCapacity,
                Inventory: d.inventory,
                Sold: d.sold,
                Available: d.available,
                'On Hold': d.onHold,
                'Total Sales (R$)': d.grossRevenue || 0
              };
            })}
            style={{ all: 'unset' }}>
            <div
              role="button"
              className="text-indigo-500 font-bold text-sm flex items-center gap-2">
              Export data
              <FontAwesomeIcon icon={faArrowDownToLine} />
            </div>
          </CsvDownloadButton>
        </div>
      </div>
      <div className="mt-4">
        <StatusLabelWidget />
      </div>
      <div className=" mt-6 space-y-8">
        {isLoadingDetailed ? (
          <LoadingContainer />
        ) : (
          Object.entries(accommodationRooms || {}).map(([accommodationId, rooms]) => {
            return (
              <div key={accommodationId}>
                <h2 className="font-bold"># {rooms[0].accommodationName}</h2>
                <SegmentsSummaryWidget data={rooms || []} isLoading={isLoadingDetailed} />
              </div>
            );
          })
        )}
      </div>
    </Page>
  );
};

export default DashboardAccommodation;
