import * as React from 'react';
import { FC, useId, useMemo } from 'react';
import { Country } from 'country-state-city';
import { Autocomplete, TextField } from '@mui/material';

type AccTypeSelecInputProps = {
  disabled?: boolean;
  value: string;
  onChange: (countryIso: string) => void;
};

export const LocationCountrySelector: FC<AccTypeSelecInputProps> = ({
  onChange,
  value,
  disabled
}) => {
  const id = useId();

  const countries = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        label: country.name,
        id: country.isoCode
      })),
    []
  );

  const valueToCountry = useMemo(
    () => countries.find((country) => country.id === value) || null,
    [countries, value]
  );

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      id={id}
      options={countries}
      onChange={(e, v) => v && onChange(v.id)}
      value={valueToCountry}
      renderInput={(params) => <TextField {...params} label="Countries" />}
    />
  );
};
