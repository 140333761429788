import { DASHBOARD_PATHS, INVENTORY_PATHS } from 'src/paths';
import { PresentationChartBarIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBedFront,
  faBuilding,
  faGrid,
  faPuzzlePiece,
  faTicket,
  faTruckFast,
  faTruckPlane
} from '@fortawesome/pro-duotone-svg-icons';

export type LeftNavigationItem = {
  name: string;
  href: string;
  icon?: JSX.Element;
  current: boolean;
  disabled?: boolean;
};

export const inventoryLeftNav: LeftNavigationItem[] = [
  {
    name: 'Accommodation',
    href: INVENTORY_PATHS.accommodation.list,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faBuilding} />,
    current: true
  },
  {
    name: 'Rooms',
    href: INVENTORY_PATHS.accommodation_rooms.list,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faBedFront} />,

    current: true
  },
  {
    name: 'Journeys',
    href: INVENTORY_PATHS.journeys.list,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faTruckPlane} />,
    current: true
  },
  {
    name: 'Tickets',
    href: INVENTORY_PATHS.tickets.list,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faTicket} />,
    current: true
  },
  {
    name: 'Assets',
    href: INVENTORY_PATHS.assets.list,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faPuzzlePiece} />,
    current: true
  },
  {
    name: 'Shipping',
    href: INVENTORY_PATHS.shipping.list,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faTruckFast} />,
    current: true
  }
];

export const dashboardLeftNav: LeftNavigationItem[] = [
  {
    name: 'Summary',
    href: DASHBOARD_PATHS.summary.index,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faGrid} />,
    current: true
  },
  {
    name: 'Accommodation',
    href: DASHBOARD_PATHS.accommodation.index,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faBuilding} />,
    current: true
  },
  {
    name: 'Journeys',
    href: DASHBOARD_PATHS.journeys.index,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faTruckPlane} />,
    current: true
  },
  {
    name: 'Tickets',
    href: DASHBOARD_PATHS.tickets.index,
    icon: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faTicket} />,
    current: true
  }
];

export const analyticsLeftNav: LeftNavigationItem[] = [
  {
    name: 'General',
    href: '#',
    icon: <PresentationChartBarIcon className="h-5 w-5 shrink-0" aria-hidden="true" />,
    current: true
  }
];
