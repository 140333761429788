import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useAuth } from "src/hooks/use-auth";

export const TENANT_COOKIE_ID = '"inside-admin-tenant-id';

// TODO: get from events list
const TENANTS: { id: string; name: string }[] = [
  {
    id: "37ed2f3b-fa9f-427e-9aa3-10ac7627197a",
    name: "Tomorrowland Brasil 2023 🇧🇷",
  },
];

interface TenantProviderProps {
  currentTenant: string;
  switchTenant: (newTenant: string) => void | undefined;
  TENANTS: { id: string; name: string }[];
}

// Create a context for the tenant
const TenantContext = createContext<TenantProviderProps>({
  currentTenant: TENANTS[0].id,
  switchTenant: (newTenant: string) => undefined,
  TENANTS,
});

// Create a custom hook to access the tenant context
export function useTenant() {
  return useContext(TenantContext);
}

// Create a TenantProvider component
export function TenantProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  //
  // // Here check the user permissions
  // const canView = user?.role === ROLES.Admin;
  const inStoreTenant = Cookies.get(TENANT_COOKIE_ID);

  const [currentTenant, setCurrentTenant] = useState(
    inStoreTenant ?? TENANTS[0].id
  ); // Default tenant

  useEffect(() => {
    Cookies.set(TENANT_COOKIE_ID, currentTenant);
  }, [currentTenant]);

  // Function to switch the current tenant
  const switchTenant = (newTenant: string) => {
    setCurrentTenant(newTenant);
    window.location.reload();
  };

  const TLBETenant = {
    id: "8f5ac99c-529e-4abb-8e20-fbb91d137dd2",
    name: "Tomorrowland Belgium 2023 🇧🇪",
  };

  if (user?.isAdmin && !TENANTS.find((t) => t.id === TLBETenant.id)) {
    TENANTS.push(TLBETenant);
  }

  // Value to be provided by the context
  const contextValue = {
    currentTenant,
    switchTenant,
    TENANTS,
  };

  return (
    <TenantContext.Provider value={contextValue}>
      {children}
    </TenantContext.Provider>
  );
}
