import { ReactNode, SelectHTMLAttributes } from 'react';
import { Path, useFormContext } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

export type SelectProps<T = any> = {
  label: string;
  name: Path<T>;
  children: ReactNode;
  rules?: RegisterOptions;
} & SelectHTMLAttributes<HTMLSelectElement>;

export const Select = <T = any,>({ label, name, children, rules, ...props }: SelectProps<T>) => {
  const { register } = useFormContext();

  return (
    <div className="sm:col-span-3 max-w-md">
      <label htmlFor={name as string} className=" block text-sm font-medium text-gray-900">
        {label}
      </label>
      <select
        {...props}
        {...register(name as string, rules)}
        className="disabled:bg-gray-100 w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600">
        {children}
      </select>
    </div>
  );
};
