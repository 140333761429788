import { create } from 'zustand';
import { produce } from 'immer';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import toast from 'react-hot-toast';
import { AssetType } from 'types/assets';

type useAssetsStoreProps = {
  isLoading: boolean;
  onSubmit: (data: AssetType, uuid?: string) => Promise<void>;
  setLoading: (isLoading: boolean) => void;
};

export const useAssetsStore = create<useAssetsStoreProps>((set, get) => ({
  isLoading: false,
  setLoading: (isLoading) =>
    set(
      produce<useAssetsStoreProps>((state) => {
        state.isLoading = isLoading;
      })
    ),
  onSubmit: async (data, uuid) => {
    get().setLoading(true);

    try {
      const verb = uuid ? 'put' : 'post';
      await apiFetch<AssetType>(API_ENDPOINT.Inventory.Assets)[verb](data);
    } catch (error) {
      console.error('Error submitting asset request:', error);
      toast.error('Ops! An error ocurred, try again!');
    } finally {
      get().setLoading(false);
      toast.success(uuid ? 'Asset updated sucessfully' : 'Asset created sucessfully');
    }
  }
}));
