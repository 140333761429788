import { Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import { TenantProvider } from 'src/layouts/dashboard/tenant-switch/tenant-provider';
import styles from './analytics.module.css';

export const analyticsRoutes: RouteObject[] = [
  {
    path: 'analytics',
    element: (
      <TenantProvider>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </TenantProvider>
    ),
    children: [
      {
        path: '/analytics',
        element: (
          <div>
            <iframe
              title="Amplitude Dashboard"
              className={styles.analyticsIframe}
              src="https://app.amplitude.com/analytics/share/embed/52a3f08d-e2b2-447e-8dee-05417b8b89ee"
              width="100%"
              height="800"></iframe>
          </div>
        )
      }
    ]
  }
];
