import { OrderDetails } from 'types/order-detail';
import { useEnv } from 'src/hooks/use-env';
import { priceToCurrency } from 'src/utils/price-to-currency';
import { Badge } from 'src/@design-system/feedback/badge';
import { faUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getGatewayTransacationUrl } from 'src/@common/helpers/get-gateway-transacation-url';
import { getOrderAmount } from 'src/@common/order/get-item-amout';

export const OrderPayment = ({ order }: { order: OrderDetails }) => {
  const { payment, shipping } = order;
  const { isProd } = useEnv();
  if (!payment) return null;

  return (
    <ul className="w-full">
      <li key={payment.id} className="overflow-hidden rounded-xl border border-gray-200">
        <div className="flex justify-between items-center border-b border-gray-900/5 bg-gray-50 p-6">
          <div>
            <div className="text-sm font-medium leading-6 text-gray-900 flex items-center gap-1">
              Payment
              <Badge color={'indigo'} value={payment.status} size="sm" />
            </div>
            <div className="text-xs text-gray-600 pt-1">{payment.gatewayIntentId}</div>
          </div>
          <div>
            <a
              target="_blank"
              rel={'noreferrer'}
              href={getGatewayTransacationUrl(payment.gateway, payment.gatewayIntentId, isProd)}>
              <FontAwesomeIcon
                className="h-5 w-5 text-indigo-600"
                aria-hidden="true"
                icon={faUpRightFromSquare}
              />
            </a>
          </div>
        </div>
        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Total Package Price</dt>
            <dd className="text-gray-700">
              <div className="text-sm font-medium leading-6 text-gray-900 flex gap-2">
                {priceToCurrency(
                  order.items.reduce((acc, item) => acc + getOrderAmount(item), 0),
                  'BRL'
                )}
              </div>
            </dd>
          </div>
          {payment.discount ? (
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Discount Voucher</dt>
              <dd className="text-gray-700">
                <div className="text-sm font-medium leading-6 text-gray-900 flex gap-2">
                  {priceToCurrency(payment.discount, 'BRL')}
                </div>
              </dd>
            </div>
          ) : null}
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Amount charged</dt>
            <dd className="text-gray-700">
              <div className="text-sm font-medium leading-6 text-gray-900 flex gap-2">
                {priceToCurrency(payment.amount, 'BRL')}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Fee</dt>
            <dd className="text-gray-700">
              <div className="text-sm font-medium leading-6 text-gray-900 flex gap-2">
                {priceToCurrency(payment.fee, 'BRL')}
              </div>
            </dd>
          </div>
          {shipping.method === 'ship' ? (
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Shipping</dt>
              <dd className="text-gray-700">
                <div className="text-sm font-medium leading-6 text-gray-900 flex gap-2">
                  {shipping.option?.price ? priceToCurrency(shipping.option?.price, 'BRL') : 'N/A'}
                </div>
              </dd>
            </div>
          ) : null}
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Net</dt>
            <dd className="text-gray-700">
              <div className="text-sm font-medium leading-6 text-gray-900 flex gap-2">
                {priceToCurrency(
                  payment.amount - payment.fee - (shipping.option?.price || 0),
                  'BRL'
                )}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Gateway</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                <span className="text-sm leading-6 text-gray-900">
                  {payment.gateway.toUpperCase()}
                </span>
                <span className="ml-1 text-xs text-gray-500">
                  {payment.cardBrand.toUpperCase()}{' '}
                  {payment.cardLast4 ? `ending in ${payment.cardLast4}` : ''}
                </span>
              </div>
            </dd>
          </div>
        </dl>
      </li>
    </ul>
  );
};
