import { useState } from 'react';
import classNames from 'classnames';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox as ComboboxHS } from '@headlessui/react';
import { Controller, Path, useFormContext } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

export type ComboboxOption = { value: string; label: string };

export type ComboboxProps<T> = {
  label: string;
  name: Path<T>;
  options: ComboboxOption[];
  defaultValue?: ComboboxOption['value'];
  rules?: RegisterOptions;
  onChange?: (value: ComboboxOption) => void;
  disabled?: boolean;
};

export const Combobox = <T,>({
  label,
  name,
  options,
  defaultValue,
  rules,
  onChange,
  disabled = false
}: ComboboxProps<T>) => {
  const { control } = useFormContext();
  const [query, setQuery] = useState('');

  const filteredPeople =
    query === ''
      ? options
      : options.filter((opt) => {
          return opt.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Controller
      name={name as string}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange: onSelect, value } }) => (
        <div className="sm:col-span-3 max-w-md">
          <ComboboxHS
            disabled={disabled}
            as="div"
            name={name as string}
            value={options.find((opt) => opt.value === value)}
            onChange={(opt) => {
              console.log('opt', opt);
              setQuery('');
              onSelect(opt?.value);
              onChange?.(opt);
            }}>
            <ComboboxHS.Label className="block text-sm font-medium leading-6 text-gray-900">
              {label}
            </ComboboxHS.Label>
            <div className="relative mt-1">
              <ComboboxHS.Input
                className="disabled:bg-gray-100 w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(event) => setQuery(event.target.value)}
                onBlur={() => setQuery('')}
                displayValue={(opt: ComboboxOption) => opt?.label}
              />
              <ComboboxHS.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </ComboboxHS.Button>

              {filteredPeople.length > 0 && (
                <ComboboxHS.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredPeople.map((opt) => (
                    <ComboboxHS.Option
                      key={opt.value}
                      value={opt}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        )
                      }>
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames('block truncate', selected && 'font-semibold')}>
                            {opt.label}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600'
                              )}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxHS.Option>
                  ))}
                </ComboboxHS.Options>
              )}
            </div>
          </ComboboxHS>
        </div>
      )}
    />
  );
};
