import { Page as PageType } from 'types/template/page';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { OrderStatusType } from 'types/order';
import { useOrderColumns } from 'src/pages/transactions/orders/table-columns';
import { LoadingContainer } from 'src/components/loading';
import React, { useMemo, useState } from 'react';
import { OrderDetails } from 'types/order-detail';
import { Table } from 'src/@common/components/table';
import { Page } from 'src/components/page';
import { Pagination } from 'src/@common/components/pagination';
import { usePagination } from 'src/@common/components/hooks/usePagination';
import { TableFilter } from 'src/@common/components/table-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { EmptyState } from 'src/components/empty-state';
import { TransactionsStatusWidget } from 'src/pages/transactions/components/status-widget';
import { getOrderAmount } from 'src/@common/order/get-item-amout';
import { getOrderNetAmount } from 'src/@common/order/get-order-net-amount';
import { priceToCurrency } from 'src/utils/price-to-currency';
import { RoleBasedGuard } from 'src/guards/role-based-guard';
import { useAuth } from 'src/hooks/use-auth';
import { ExportTypeModal } from 'src/pages/transactions/orders/export/export-type-modal';

type OrdersFilters = { search?: string; orderStatus?: OrderStatusType[] };

const defaultOrdersFilters: OrdersFilters = {
  search: undefined,
  orderStatus: ['confirmed']
};

export const OrdersReport: PageType = () => {
  const [exportModalOpen, setExportModalOpen] = React.useState(false);

  const { user } = useAuth();
  const [filters, setFilters] = useState<OrdersFilters>({
    search: undefined
  });
  const { columns } = useOrderColumns();

  const { isLoading, data } = useQuery<OrderDetails[]>(
    ['OrdersDetails', filters?.orderStatus],
    () => {
      return apiFetch(API_ENDPOINT.Transactions.Orders).get({
        status: filters?.orderStatus?.join(',') || 'confirmed'
      });
    }
  );

  const filteredData = useMemo(
    () =>
      data?.filter((order) => {
        if (filters.search) {
          const search = filters.search.toLowerCase();
          return (
            order.customer.firstName.toLowerCase().includes(search) ||
            order.customer.lastName.toLowerCase().includes(search) ||
            order.customer.email.toLowerCase().includes(search) ||
            order.confirmationCode?.toLowerCase().includes(search)
          );
        }
        if (filters?.orderStatus && filters.orderStatus.length > 0) {
          return filters.orderStatus.includes(order.status);
        }
        return true;
      }),
    [data, filters]
  );

  const { paginatedData, onNextPage, onPreviousPage, setPaginationPage, pagination } =
    usePagination<OrderDetails>({
      data: filteredData || [],
      rowsPerPage: 20
    });

  return (
    <>
      <Page
        title="Orders"
        description=""
        cta={
          <RoleBasedGuard permissions={'viwer'}>
            <div
              className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 items-center cursor-pointer"
              onClick={() => setExportModalOpen(true)}>
              Export table
              <FontAwesomeIcon icon={faArrowDownToLine} />
            </div>
          </RoleBasedGuard>
        }>
        <ExportTypeModal
          orders={filteredData || []}
          isOpen={exportModalOpen}
          onCancel={() => setExportModalOpen(false)}
        />
        <TableFilter
          filters={[
            {
              id: 'orderStatus',
              name: 'Status',
              options: [
                { value: 'confirmed', label: 'Confirmed' },
                { value: 'expired', label: 'Expired' },
                { value: 'pending', label: 'Pending' },
                { value: 'cancelled', label: 'Canceled' }
              ]
            }
          ]}
          defaultSelectedFilters={{ orderStatus: new Set(defaultOrdersFilters.orderStatus) }}
          onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
          onFilterChange={setFilters}
        />

        <RoleBasedGuard permissions="admin">
          <TransactionsStatusWidget
            isLoading={isLoading}
            stats={[
              {
                key: 'totalConfirmed',
                name: 'Total Confirmed',
                value: filteredData?.filter((order) => order.status === 'confirmed').length
              },
              {
                key: 'totalPending',
                name: 'Total Packages Sale',
                value: priceToCurrency(
                  filteredData?.reduce(
                    (acc, order) =>
                      acc + order.items.reduce((acc, item) => acc + getOrderAmount(item), 0),
                    0
                  ) || 0,
                  'BRL'
                )
              },
              {
                key: 'totalCharges',
                name: 'Total Net Charges',
                value: priceToCurrency(
                  filteredData?.reduce((acc, order) => acc + getOrderNetAmount(order), 0) || 0,
                  'BRL'
                )
              }
            ]}
          />
        </RoleBasedGuard>

        {isLoading ? (
          <LoadingContainer />
        ) : paginatedData?.length > 0 ? (
          <>
            <Table<OrderDetails> className="mt-8" data={paginatedData || []} rows={columns} />
            <Pagination
              page={pagination.page}
              onPageChange={(page) => setPaginationPage(page)}
              perPage={pagination.perPage}
              total={filteredData?.length || 0}
              onNext={onNextPage}
              onPrevious={onPreviousPage}
            />
          </>
        ) : (
          <EmptyState
            img="/assets/illustrations/no-data-found.svg"
            header={'No orders fund'}
            description={'It looks like there are no orders'}
          />
        )}
      </Page>
    </>
  );
};
