import PageContainer from 'src/components/page-container';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { Accommodation } from 'types/accommodation';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Switch,
  TextField
} from '@mui/material';
import MxEditor from 'src/components/mx-editor';
import { AccommodationCategoryTypes, AccommodationTypes, MealPlanTypes } from 'src/constants';
import { AccTypeSelecInput } from 'src/pages/inventory/accommodations/components/acc-type-selec-inout';
import CoverImgUpload from 'src/pages/components/cover-img-upload';
import PageImgsUpload from 'src/pages/components/page-imgs-upload';
import {
  ImageType,
  useAccommodationStore
} from 'src/pages/inventory/accommodations/hooks/use-accommodation';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import React, { useCallback } from 'react';
import { AccommParks } from 'src/pages/inventory/accommodations/components/accomm-parks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AssetsSelector } from 'src/pages/inventory/accommodations/components/assets-selector';
import { INVENTORY_PATHS } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';
import { ROLES } from 'src/guards/role-based-guard';

export type AccommodationRequest = Exclude<Accommodation, 'roomsCapacity'>;

const AddNew = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid?: string }>();
  const { user } = useAuth();

  const canEdit = user?.role === ROLES.Admin;

  const { isFetching, data } = useQuery<Accommodation>(
    ['AccommodationDetails', uuid],
    () => {
      return apiFetch(API_ENDPOINT.Inventory.Accommodation).get({ uuid: String(uuid) });
    },
    { enabled: Boolean(uuid) }
  );

  const { onSubmit, setImage, isLoading } = useAccommodationStore((state) => ({
    onSubmit: state.onSubmit,
    setImage: state.setImage,
    isLoading: state.isLoading
  }));

  const form = useForm<AccommodationRequest>({
    values: data,
    defaultValues: {
      uuid: crypto.randomUUID(),
      location: { coordinates: { lat: 0, lng: 0 } }
    }
  });

  const handleSubmit = useCallback(
    async (data: Accommodation) => {
      console.log('data', data);
      await onSubmit(data, uuid);
      navigate(INVENTORY_PATHS.accommodation.list);
    },
    [navigate, onSubmit, uuid]
  );

  return (
    <PageContainer
      breadcrumbs={[
        { title: 'Inventory' },
        { title: 'Accommodation', href: INVENTORY_PATHS.accommodation.list },
        { title: uuid ? 'Edit' : 'Add new' }
      ]}
      isLoading={isLoading || isFetching}
      seo="Inventory > Accommodation"
      title="Add new accommodation">
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={4}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                  <Typography variant="h6">Basic details</Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack spacing={3}>
                    <Controller
                      name="live"
                      control={form.control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={!canEdit}
                              checked={field.value === 1}
                              onChange={(e) => {
                                field.onChange(e.target.checked ? 1 : 0);
                              }}
                            />
                          }
                          label="Live"
                        />
                      )}
                    />
                    <Controller
                      name="name"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          disabled={!canEdit}
                          label="Name"
                          InputLabelProps={{ shrink: !!field.value }}
                          {...field}
                        />
                      )}
                    />
                    <div>
                      <Typography color="text.secondary" sx={{ mb: 2 }} variant="subtitle2">
                        Description
                      </Typography>
                      <Controller
                        name="description"
                        control={form.control}
                        rules={{ required: true }}
                        render={(props) => (
                          <MxEditor
                            disabled={!canEdit}
                            value={props.field.value}
                            onChange={(data) => {
                              props.field.onChange(data);
                            }}
                          />
                        )}
                      />
                    </div>
                    <Controller
                      name="type"
                      control={form.control}
                      defaultValue={AccommodationTypes.Hotel}
                      render={({ field }) => (
                        <AccTypeSelecInput
                          disabled={!canEdit}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                    <Controller
                      name="category"
                      control={form.control}
                      defaultValue={AccommodationCategoryTypes.Bronze}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <TextField disabled={!canEdit} select {...field} label="Category">
                            {Object.values(AccommodationCategoryTypes).map((type) => (
                              <MenuItem key={type} value={type}>
                                {t(`accommodation.categories.${type}`)}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="mealPlan"
                      control={form.control}
                      defaultValue={MealPlanTypes.None}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <TextField disabled={!canEdit} select {...field} label="Meal Plan">
                            {Object.values(MealPlanTypes).map((type) => (
                              <MenuItem key={type} value={type}>
                                {t(`accommodation.mealPlans.${type}`)}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="amenities"
                      control={form.control}
                      rules={{ required: false }}
                      defaultValue={data?.amenities || []}
                      render={({ field }) => (
                        <AccommParks
                          disabled={!canEdit}
                          onChange={(perks) => field.onChange(perks as any)}
                          selectedPerks={field.value}
                        />
                      )}
                    />
                    <Controller
                      name="assets"
                      control={form.control}
                      rules={{ required: false }}
                      defaultValue={[]}
                      render={({ field }) => (
                        <AssetsSelector
                          disabled={!canEdit}
                          onChange={field.onChange}
                          selectItems={field.value}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                  <Typography variant="h6">Location</Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack direction="row" spacing={3}>
                    <TextField
                      disabled={!canEdit}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      label="Address"
                      {...form.register('location.address', { required: true })}
                    />

                    <FormControl fullWidth>
                      <TextField
                        disabled={!canEdit}
                        id="accommodation-location-distance"
                        label="Distance to festival"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">KM</InputAdornment>
                        }}
                        {...form.register('location.distance', { required: true })}
                        type="number"
                      />
                      <FormHelperText>If 0 it will show "inside the festival"</FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Controller
                name="mainImage"
                control={form.control}
                rules={{ required: false }}
                render={() => (
                  <CoverImgUpload
                    disabled={!canEdit}
                    initialCover={data?.mainImage || ''}
                    onChange={(file) => setImage([file], ImageType.Background)}
                  />
                )}
              />
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Controller
                name="images"
                control={form.control}
                rules={{ required: false }}
                render={() => (
                  <PageImgsUpload
                    disabled={!canEdit}
                    defaultImages={data?.images || []}
                    onFileDrop={(files) => {
                      setImage(files, ImageType.Image);
                    }}
                    files={[]}
                  />
                )}
              />
            </CardContent>
          </Card>
        </Stack>
        <div className="pt-6 flex justify-end">
          <Button variant="contained" type="submit" disabled={!canEdit}>
            Submit
          </Button>
        </div>
      </form>
    </PageContainer>
  );
};

export default AddNew;
