import { DashboardSummary, DashboardSummaryResponse } from 'types/dashboard/summary';
import { Table, TableRow } from 'src/@common/components/table';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { LoadingContainer } from 'src/components/loading';
import { useTranslation } from 'react-i18next';

export const OrdersConsolidated = ({
  data,
  isLoading
}: {
  data?: DashboardSummaryResponse;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!data && !isLoading) return null;

  if (isLoading) return <LoadingContainer />;

  const rows: TableRow<DashboardSummary>[] = [
    {
      column: 'type',
      columnName: 'Type',
      className: 'w-[300px] overflow-ellipsis',
      render: (row) => (
        <span
          className="capitalize font-semibold flex items-center gap-1 cursor-pointer"
          onClick={() => {
            switch (row.type) {
              case 'camping':
              case 'hotel':
                navigate('/dashboard/accommodations');
                break;
              case 'flight-camping':
              case 'flight-hotel':
              case 'bus-camping':
              case 'bus-hotel':
              case 'bus':
              case 'flight':
                navigate('/dashboard/journeys');
                break;
              default:
                navigate('/dashboard/tickets');
                break;
            }
          }}>
          {t(`dashboard.summary.packagesTypes.${row.type}`)}{' '}
          <FontAwesomeIcon
            className="text-indigo-600 w-2"
            aria-hidden="true"
            icon={faArrowUpRightFromSquare}
          />
        </span>
      )
    },
    {
      column: 'availability',
      columnName: 'Availability'
    },
    {
      column: 'sold',
      columnName: 'Sold'
    },
    {
      column: 'onHold',
      columnName: 'On Hold'
    },
    {
      columnName: 'Available',
      render: (row) => row.availability - row.sold - row.onHold
    },
    {
      column: 'pax',
      columnName: 'PAX'
    }
    // {
    //   column: 'revenue',
    //   columnName: (
    //     <div>
    //       Total Sales{' '}
    //       <Tooltip title="Total of sales for the specific package summing prices for accommodation, journey and ticket">
    //         <FontAwesomeIcon className="text-gray-500" icon={faCircleQuestion} />
    //       </Tooltip>
    //     </div>
    //   ),
    //   render: (row) => priceToCurrency(row.revenue, 'BRL')
    // }
  ];

  return (
    <div className="">
      <div className="">
        <h2 className="text-lg font-bold flex gap-2 items-center"># Packages</h2>
        <Table<DashboardSummary>
          className="table-fixed"
          data={[...(data?.accommodation ?? []), ...(data?.journey ?? [])]}
          rows={rows}
        />
      </div>
      <div className="pt-8">
        <h2
          className="text-lg font-bold flex gap-2 items-center cursor-pointer"
          onClick={() => navigate('/dashboard/tickets')}>
          # Tickets
        </h2>
        <Table<DashboardSummary> className="table-fixed" data={data?.ticket || []} rows={rows} />
      </div>
    </div>
  );
};
