import { Input, InputProps } from 'src/@design-system/form/input';
import { Select, SelectProps } from 'src/@design-system/form/select';
import { DefaultValues, FieldValues, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { ReactNode, useCallback } from 'react';
import { Combobox, ComboboxProps } from 'src/@design-system/form/combobox';
import { Drawer } from 'src/@design-system/drawer';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import cn from 'classnames';

type FormContainerProps<T extends FieldValues> = {
  title?: string;
  description?: string;
  children: ReactNode | JSX.Element;
  onSaved: (data: T, dirtyFields: string[]) => void;
  onCancel?: () => void;
  onClose: () => void;
  open?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  form: UseFormReturn<T>;
};

const FormDrawerContainer = <T extends FieldValues>({
  children,
  title,
  description,
  onSaved,
  form,
  open,
  onClose,
  isLoading,
  disabled,
  className = 'grid grid-cols-1 gap-6 sm:grid-cols-6 pb-8'
}: FormContainerProps<T>) => {
  const handleOnSave = useCallback(
    (data: T) => {
      onSaved(data, Object.keys(form.formState.dirtyFields));
    },
    [form.formState.dirtyFields, onSaved]
  );

  const handleOnClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  return (
    <FormProvider {...form}>
      <Drawer
        disableSave={!form.formState.isDirty}
        disabled={disabled}
        isLoading={isLoading}
        title={title}
        description={description}
        onClose={handleOnClose}
        open={open}
        onSave={form.handleSubmit(handleOnSave)}>
        <div className={cn('px-8 py-6', className)}>{children}</div>
      </Drawer>
    </FormProvider>
  );
};

export const FormDrawer = <T extends FieldValues>({
  defaultProps
}: {
  defaultProps?: CreateFormDrawerProps<T>;
}) =>
  Object.assign(FormDrawerContainer, {
    Input: (props: InputProps<T>) =>
      Input<T>({
        ...props,
        disabled: defaultProps?.disabled,
        rules: props.rules || defaultProps?.rules
      }),
    Select: (props: SelectProps<T>) =>
      Select<T>({
        ...props,
        disabled: defaultProps?.disabled,
        rules: props.rules || defaultProps?.rules
      }),
    Combobox: (props: ComboboxProps<T>) =>
      Combobox<T>({
        ...props,
        disabled: defaultProps?.disabled,
        rules: props.rules || defaultProps?.rules
      })
  });

type CreateFormDrawerProps<T extends FieldValues> = {
  disabled?: boolean;
  rules?: RegisterOptions;
  defaultValues?: DefaultValues<T>;
};

export const useFormDrawer = <T extends FieldValues>(defaultProps?: CreateFormDrawerProps<T>) => {
  const form = useForm<T>({ defaultValues: defaultProps?.defaultValues });

  // Create an object to hold FormComponent and Input
  return {
    form,
    FormDrawer: FormDrawer<T>({ defaultProps })
  };
};
