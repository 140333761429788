import React, { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import cn from 'classnames';
import { sanitize } from 'isomorphic-dompurify';

type MxEditorProps = {
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
};

export const ReadOnly = ({ description }: { description: string }) => {
  const sanitazedData = useMemo(
    () => ({
      __html: description ? sanitize(description.replaceAll('<p><br></p>', '')) : ''
    }),
    [description]
  );

  return (
    <div className="p-4 bg-gray-100">
      <article className={cn('prose')} dangerouslySetInnerHTML={sanitazedData} />
    </div>
  );
};

const MxEditor: React.FC<MxEditorProps> = (props) => {
  return (
    <div>
      {props.disabled ? (
        <ReadOnly description={props.value} />
      ) : (
        <ReactQuill
          value={props.value}
          onChange={(value, delta, source, editor) => {
            if (source === 'user') {
              props.onChange(value);
            }
          }}
          theme="snow" // You can choose different themes
          preserveWhitespace={false}
          modules={{
            clipboard: {
              matchVisual: false
            },
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'image'],
              ['clean']
            ]
          }}
        />
      )}
    </div>
  );
};

export default MxEditor;
