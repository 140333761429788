import { Dialog, DialogTitle } from 'src/@design-system/dialog';

export type OrderActionModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export const OrderActionModal = ({ isOpen, onClose, children, title }: OrderActionModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} size="md">
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      {children}
    </Dialog>
  );
};
