import React from 'react';
import { AccommodationCategoryTypes } from 'src/constants';
import { AccommodationCategoryType } from 'types/accommodation';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const colors: { [key in keyof typeof AccommodationCategoryTypes]: string } = {
  [AccommodationCategoryTypes.Bronze]: '#b26500',
  [AccommodationCategoryTypes.Silver]: '#939ed5',
  [AccommodationCategoryTypes.Gold]: '#b2a300',
  [AccommodationCategoryTypes.Thematic]: '#f50057',
  [AccommodationCategoryTypes.EasyTents]: '#5e35b1'
};

export const AccommodationCategoryChips = (props: { category: AccommodationCategoryType }) => {
  const { t } = useTranslation();

  return (
    <Chip
      variant="outlined"
      size="small"
      sx={{ color: colors[props.category] }}
      label={t(`accommodation.categories.${props.category}`)}
    />
  );
};
