import { track } from 'src/@common/analytics/use-analytics';
import debounce from 'debounce';

export const trackTopNavClick = (tab?: string) => {
  track('top-nav-click', 'track', { tab });
};

export const trackLeftNavClick = (tab?: string) => {
  track('left-nav-click', 'track', { tab });
};

export const trackFilterAppliedFunc = (
  filter: Record<string, string | string[]>,
  location: string,
  page: string
) => {
  track('filter-applied', 'track', { filter, page, location });
};

export const trackFilterApplied = debounce(trackFilterAppliedFunc, 1000);
