import type { FC, ReactNode } from 'react';
import { NoDataEmptyState } from 'src/components/dropdown/no-data-empty-state';
import { Table, TableRow } from 'src/@common/components/table';
import { ShippingOption } from 'types/shipping';
import { priceToCurrency } from 'src/utils/price-to-currency';
import { Country, State } from 'country-state-city';
import { faUps } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'src/@design-system/feedback/badge';

type Props = {
  data: ShippingOption[];
};

const CourrierIcons: Record<string, ReactNode> = {
  UPS: <FontAwesomeIcon className="h-5 w-5 shrink-0" aria-hidden="true" icon={faUps} />
};

export const ShippingOptionsTable: FC<Props> = (props) => {
  if (props.data.length === 0) {
    return <NoDataEmptyState type={'Shipping'} />;
  }

  const columns: TableRow<ShippingOption>[] = [
    {
      column: 'country',
      columnName: 'ISO'
    },
    {
      columnName: 'Country',
      render: (row) => {
        return (
          <div className="flex gap-1 items-center font-semibold">
            <span>{Country.getCountryByCode(row.country)?.name || row.country}</span>
          </div>
        );
      }
    },
    {
      columnName: 'State',
      render: (row) =>
        row.state ? (
          State.getStateByCodeAndCountry(row.state, row.country)?.name
        ) : (
          <span className="text-gray-400">—</span>
        )
    },
    {
      columnName: 'Price',
      render: (row) => priceToCurrency(row.price, 'BRL')
    },
    {
      columnName: 'Type',
      render: (row) => {
        return (
          <Badge
            className="capitalize"
            color={row.type === 'international' ? 'indigo' : 'pink'}
            value={row.type}
            size="sm"
          />
        );
      }
    },
    {
      columnName: 'Courier',
      className: 'flex justify-center',
      render: (row) => {
        return (
          <div className="flex gap-1 items-center font-semibold">
            {CourrierIcons[row.courier] || row.courier}
          </div>
        );
      }
    }
  ];

  return <Table<ShippingOption> className="mt-8" data={props.data || []} rows={columns} />;
};
