import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import { Autocomplete, TextField } from '@mui/material';
import * as React from 'react';
import { useId, useMemo } from 'react';

type TimezoneSelectProps = {
  onChange: (tz: string) => void;
  value?: string;
  className?: string;
  disabled?: boolean;
};

export const TimezoneSelect = (props: TimezoneSelectProps) => {
  const id = useId();
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle: 'original',
    timezones: allTimezones
  });

  const valueToTZ = useMemo(
    () => options.find((opt) => opt.value === props.value)?.value || null,
    [options, props.value]
  );

  return (
    <Autocomplete
      disabled={props.disabled}
      defaultValue=""
      id={id}
      className={props.className}
      fullWidth
      options={options as any}
      onChange={(e, v) => v && props.onChange(parseTimezone(v).value)}
      value={valueToTZ}
      getOptionLabel={(option) => parseTimezone(option).label}
      renderInput={(params) => <TextField {...params} fullWidth label="Timezone" />}
    />
  );
};
