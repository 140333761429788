import { orderStatusBadge } from 'src/pages/transactions/common/helpers';
import {
  CalendarDaysIcon,
  CreditCardIcon,
  FlagIcon,
  PhoneIcon,
  UserCircleIcon
} from '@heroicons/react/20/solid';
import { formatDate } from '@fullcalendar/core';
import { OrderDetails } from 'types/order-detail';
import { useState } from 'react';
import { BuyerDetailsForm } from 'src/pages/transactions/orders/details/edit/buyer/buyer-details';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'src/@design-system/feedback/badge';
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons';
import { Country } from 'country-state-city';

type OrderSummaryProps = {
  orderDetails: OrderDetails;
  noPaymentInfo?: boolean;
};

export const OrderSummary = ({ orderDetails }: OrderSummaryProps) => {
  const [openEditBuyer, setOpenEditBuyer] = useState(false);

  return (
    <section>
      {orderDetails ? (
        <BuyerDetailsForm
          orderId={orderDetails.id}
          customerDetails={orderDetails.customer}
          open={openEditBuyer}
          onClose={() => setOpenEditBuyer(false)}
        />
      ) : null}
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 pb-6">
        <dl className="flex flex-wrap">
          <div className="flex justify-between items-center bg-gray-50 p-6 w-full">
            <div>
              <div className="text-sm font-medium leading-6 text-gray-900 flex items-center gap-1">
                {orderDetails.confirmationCode}
                <Badge
                  color={orderStatusBadge[orderDetails.status]}
                  value={orderDetails.status}
                  size="sm"
                />
              </div>
              <div className="text-xs text-gray-600 pt-1">{orderDetails.id}</div>
            </div>
            <div className="cursor-pointer" onClick={() => setOpenEditBuyer(true)}>
              <FontAwesomeIcon
                className="h-5 w-5 text-indigo-600"
                aria-hidden="true"
                icon={faPenToSquare}
              />
            </div>
          </div>
          <div className="flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <dt className="flex-none">
              <span className="sr-only">Client</span>
              <UserCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm font-medium leading-6 text-gray-900">
              <div className="flex gap-1 items-center">
                {orderDetails.customer.firstName} {orderDetails.customer.lastName}
              </div>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Status</span>
              <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">{orderDetails.customer.email}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              <time dateTime="2023-01-31">
                {formatDate(orderDetails.customer.birthDate, { locale: 'pt-BR' })}
              </time>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <PhoneIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              <span>{orderDetails.customer.phoneNumber}</span>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Nationality</span>
              <FlagIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              <span>{Country.getCountryByCode(orderDetails.customer.nationality)?.name}</span>
            </dd>
          </div>
        </dl>
      </div>
    </section>
  );
};
