import { useAuth } from '../hooks/use-auth';
import { User } from 'types/template/user';
import { ReactNode } from 'react';
import { EmptyState } from '../components/empty-state';
import { Card } from '@mui/material';

export const ROLES = {
  Admin: 'admin',
  Viewer: 'viwer'
} as const;

interface RoleBasedGuardProps {
  children: ReactNode;
  permissions: User['role'];
  showIllustration?: boolean;
}

export const RoleBasedGuard = (props: RoleBasedGuardProps) => {
  const { children, permissions } = props;
  const { user } = useAuth();

  // Here check the user permissions
  const canView = user?.role === permissions || user?.role === ROLES.Admin;

  if (!canView) {
    if (props.showIllustration) {
      return (
        <Card>
          <EmptyState
            img="/assets/illustrations/no-access.svg"
            header={"Sorry, you can't see it!"}
            description={
              "You can't see if because you have no access permission, contact your account admin."
            }
          />
        </Card>
      );
    }
    return null;
  }

  return <>{children}</>;
};

export default Object.assign(RoleBasedGuard, {
  Roles: ROLES
}) as typeof RoleBasedGuard & {
  Roles: typeof ROLES;
};
