import { IconButton } from '@mui/material';
import { SimpleLocalizeUrl } from 'src/constants';
import Translate01 from '@untitled-ui/icons-react/build/esm/Translate01';

export const LocalizeButton = (props: { search: string }) => {
  return (
    <IconButton
      size="small"
      color="primary"
      href={`${SimpleLocalizeUrl}&tab=translations&search=${props.search}`}
      target="_blank">
      <Translate01 />
    </IconButton>
  );
};
