export const en = {
  priceTypes: {
    per_person: 'Per person',
    total: 'Total'
  },
  accommodation: {
    mealPlans: {
      none: 'Not included',
      breakfast: 'Breakfast',
      half_board: 'Half board',
      full_board: 'Full board'
    },
    perks: {
      private_bathroom: 'Private bathroom',
      luggage_storage: 'Luggage storage',
      hair_dryer: 'Hair dryer',
      pool: 'Pool',
      air_conditioner: 'Air conditioning',
      reception_24h: '24h Reception',
      market_place: 'Market place'
    },
    type: {
      hotel: 'Hotel',
      camping: 'Camping'
    },
    categories: {
      bronze: 'Bronze',
      silver: 'Silver',
      gold: 'Gold',
      thematic: 'Thematic',
      easy_tents: 'Easy Tents',
      magnificent_greens: 'Magnificent Greens'
    },
    roomCategories: {
      superior_double_room: 'Superior Double Room',
      twin_room: 'Twin Room',
      superior_twin: 'Superior Twin',
      suite_master: 'Suite Master',
      superior: 'Superior',
      executive_suite: 'Executive Suite',
      master_suite: 'Master Suite',
      family_suite: 'Family Suite',
      triple_room: 'Triple Room',
      king_room: 'King Room',
      superior_pne: 'Superior PNE',
      duplex_suite: 'Duplex Suite',
      double_room: 'Double Room',
      single_room: 'Single Room',
      quadruple_room: 'Quadruple Room',
      suite_family: 'Suite Family'
    }
  },
  order: {
    orderStatus: {
      confirmed: 'Confirmed',
      pending: 'Pending',
      expired: 'Expired',
      cancelled: 'Cancelled'
    },
    checkoutStatus: {
      pending: 'Pending',
      pending_payment: 'Pending payment',
      available: 'Available',
      fulfilled: 'Fulfilled',
      unavailable: 'Unavailable',
      expired: 'Expired'
    },
    paymentStatus: {
      pending: 'Pending',
      paid: 'Paid',
      refunded: 'Refunded',
      cancelled: 'Cancelled',
      expired: 'Expired'
    }
  },
  dashboard: {
    summary: {
      packagesTypes: {
        hotel: 'Hotel',
        camping: 'DreamVille',
        'flight-hotel': 'Flight & Hotel',
        'flight-camping': 'Flight & DreamVille',
        'bus-hotel': 'Bus & Hotel',
        'bus-camping': 'Bus & DreamVille',
        'Full Madness Pass': 'Full Madness Pass',
        'Full Madness Comfort Pass': 'Full Madness Comfort Pass',
        'Full Madness Comfort (VIP) Pass': 'Full Madness Comfort Pass',
        bus: 'Bus',
        flight: 'Flights'
      }
    }
  },
  orderActions: {
    cancelOrder: {
      title: 'Cancel order "{{confirmationCode}}"',
      confirmCta: 'Yes, cancel order',
      message:
        'Are you sure you want to cancel this order? The items will go back to the inventory and this action cannot be undone.'
    }
  },
  formFields: {
    customer: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      gender: 'Gender',
      birthDate: 'Birth Date',
      nationality: 'Nationality',
      documentType: 'Document type',
      documentValue: 'Document value'
    },
    address: {
      address1: 'Address 1',
      address2: 'Address 2',
      city: 'City',
      state: 'State',
      postalCode: 'Postal code',
      country: 'Country'
    }
  }
};
