interface NestedObject {
  [key: string]: any;
}

export function getPropertyByString(obj: NestedObject, propertyName: string): any {
  // Split the string by dot
  const properties = propertyName.split('.');

  // Traverse the object using each part of the split string
  let result: any = obj;
  for (const property of properties) {
    if (result && result.hasOwnProperty(property)) {
      result = result[property];
    } else {
      // Property doesn't exist
      result = undefined;
      break;
    }
  }

  return result;
}
