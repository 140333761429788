import PageContainer from 'src/components/page-container';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { useParams } from 'react-router';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAssetsStore } from 'src/pages/inventory/assets/hooks/use-assets-store';
import { AssetType } from 'types/assets';
import { INVENTORY_PATHS } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';
import { ROLES } from 'src/guards/role-based-guard';

export type AssetRequest = Exclude<AssetType, ''>;

const AddNew = () => {
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid?: string }>();
  const { user } = useAuth();

  const canEdit = user?.role === ROLES.Admin;

  /**
   * Load accomm. room by uuid
   */
  const { isLoading: isFetching, data } = useQuery<AssetType>(
    ['AssetsDetails', uuid],
    () => {
      return apiFetch(API_ENDPOINT.Inventory.Assets).get({ uuid: String(uuid) });
    },
    { enabled: Boolean(uuid) }
  );

  const { isLoading, onSubmit } = useAssetsStore((state) => ({
    onSubmit: state.onSubmit,
    isLoading: state.isLoading
  }));

  const form = useForm<AssetType>({
    values: data,
    defaultValues: {
      uuid: crypto.randomUUID()
    }
  });

  const handleSubmit = useCallback(
    async (data: AssetType) => {
      await onSubmit(data, uuid);
      navigate(INVENTORY_PATHS.assets.list);
    },
    [navigate, onSubmit, uuid]
  );

  return (
    <PageContainer
      breadcrumbs={[
        { title: 'Inventory' },
        { title: 'Assets', href: INVENTORY_PATHS.assets.list },
        { title: uuid ? 'Edit' : 'Add new' }
      ]}
      isLoading={isLoading || isFetching}
      seo="Inventory > Assets"
      title={uuid ? 'Edit asset' : 'New asset'}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={4}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                  <Typography variant="h6">Basic details</Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack spacing={3}>
                    <Controller
                      name="name"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          disabled={!canEdit}
                          label="Name"
                          InputLabelProps={{ shrink: !!field.value }}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="description"
                      control={form.control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          disabled={!canEdit}
                          multiline
                          label="Description"
                          InputLabelProps={{ shrink: !!field.value }}
                          {...field}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Stack>
        <div className="pt-6 flex justify-end">
          <Button variant="contained" type="submit" disabled={!canEdit}>
            Submit
          </Button>
        </div>
      </form>
    </PageContainer>
  );
};

export default AddNew;
