import type { FC } from 'react';
import { NoDataEmptyState } from 'src/components/dropdown/no-data-empty-state';
import { Button, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TicketType } from 'types/ticket';
import { priceToCurrency } from 'src/utils/price-to-currency';
import { LocalizeButton } from 'src/components/localize-button';
import { useTranslation } from 'react-i18next';
import NumbersIcon from '@mui/icons-material/Numbers';
import { Table, TableRow } from 'src/@common/components/table';

type Props = {
  data: TicketType[];
};

const TicketsTable: FC<Props> = (props) => {
  const { t } = useTranslation();
  const naviage = useNavigate();

  if (props.data.length === 0) {
    return <NoDataEmptyState type={'Rooms'} />;
  }

  const columns: TableRow<TicketType>[] = [
    {
      columnName: 'Name',
      render: (row) => {
        return (
          <div className="flex gap-1 items-center font-semibold">
            <span>{row.name}</span>
            <LocalizeButton search={row.uuid} />
          </div>
        );
      }
    },
    {
      column: 'description',
      columnName: 'Description'
    },
    {
      columnName: 'Price',
      render: (row) => {
        return priceToCurrency(row.price.price, 'BRL');
      }
    },
    {
      columnName: 'Price Type',
      render: (row) => {
        return t(`priceTypes.${row.priceType}`);
      }
    },
    {
      columnName: 'Availability',
      render: (row) => {
        return row.availability ? (
          <Chip icon={<NumbersIcon />} size="small" label={row.availability} />
        ) : (
          '—'
        );
      }
    },
    {
      columnName: 'Actions',
      render: (row) => {
        return (
          <div className="flex gap-1">
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={() => naviage(`/inventory/tickets/edit/${row.uuid}`)}>
              View
            </Button>
            {/*<Button variant="text" color="error" size="small" onClick={console.log}>*/}
            {/*  Delete*/}
            {/*</Button>*/}
          </div>
        );
      }
    }
  ];

  return <Table<TicketType> className="mt-8" data={props.data || []} rows={columns} />;
};

export default TicketsTable;
