import { OrderDetails } from 'types/order-detail';
import { getOrderAmount } from 'src/@common/order/get-item-amout';
import { Country, State } from 'country-state-city';

type ExportItem = { header: string; value: string | number | undefined };

export const NormalizeExport = ({ header, value }: ExportItem) => ({
  [header]: value
});

export const getExportConsolidatedColumns = (order: OrderDetails[], isAdmin: boolean = false) => {
  return order.map((data) => {
    const exportData: Record<string, string | number> = {};

    const isShippingCollect = data.shipping.method === 'collect';

    const handleShippingFields = (field: string) => {
      if (isShippingCollect) {
        return '';
      }
      return field;
    };

    const columnConfig: {
      header: string;
      value: (data: OrderDetails) => string | number;
      disabled: boolean;
    }[] = [
      { header: 'Order ID', value: (data) => data.id, disabled: false },
      {
        header: 'Status',
        value: (data) => data.status,
        disabled: false
      },
      { header: 'Confirmation Code', value: (data) => data.confirmationCode, disabled: false },
      { header: 'Payment Gateway', value: (data) => data.payment?.gateway || '', disabled: false },
      {
        header: 'Payment Installments',
        value: (data) => data.payment?.installments || 1,
        disabled: false
      },
      {
        header: 'Buyer',
        value: (data) => `${data.customer.firstName} ${data.customer.lastName}`,
        disabled: false
      },
      { header: 'Email', value: (data) => data.customer.email, disabled: false },
      { header: 'Phone Number', value: (data) => data.customer.phoneNumber, disabled: false },
      { header: 'Document Type', value: (data) => data.customer.documentType, disabled: false },
      { header: 'Document Value', value: (data) => data.customer.documentValue, disabled: false },
      {
        header: 'Total Pax',
        value: (data) =>
          data.items?.reduce((acc, item) => acc + item.accommodation?.room?.capacity, 0),
        disabled: false
      },
      { header: 'Total Items', value: (data) => data.items.length, disabled: false },
      {
        header: 'Type',
        value: (data) => (data.items[0].journey ? data.items[0].journey.type : 'hotel'),
        disabled: false
      },
      {
        header: 'Journey',
        value: (data) => data.items[0].journey?.segments?.[0]?.departure?.name ?? 'N/A',
        disabled: false
      },
      {
        header: 'Accommodation',
        value: (data) => data.items[0].accommodation?.name,
        disabled: false
      },
      {
        header: 'Check-in',
        value: (data) => data.items[0].accommodation?.checkIn,
        disabled: false
      },
      {
        header: 'Check-out',
        value: (data) => data.items[0].accommodation?.checkOut,
        disabled: false
      },
      { header: 'Shipping Method', value: (data) => data.shipping.method, disabled: false },
      {
        header: 'Shipping Address',
        value: (data) =>
          handleShippingFields(
            `${data.shipping.address.address1}, ${data.shipping.address.address2}`
          ),
        disabled: false
      },
      {
        header: 'Shipping City',
        value: (data) => handleShippingFields(data.shipping.address.city),
        disabled: false
      },
      {
        header: 'Shipping State',
        value: (data) =>
          handleShippingFields(
            State.getStateByCodeAndCountry(
              data.shipping.address.state,
              data.shipping.address.country
            )?.name || data.shipping.address.state
          ),
        disabled: false
      },
      {
        header: 'Shipping Country',
        value: (data) =>
          handleShippingFields(
            Country.getCountryByCode(data.shipping.address.country)?.name ||
              data.shipping.address.country
          ),
        disabled: false
      },
      {
        header: 'Shipping Price (R$)',
        value: (data) => data.shipping.option?.price || 0,
        disabled: false
      },
      {
        header: 'Package Price (R$)',
        value: (data) => data.items.reduce((acc, item) => acc + getOrderAmount(item), 0),
        disabled: false
      },
      { header: 'Discount (R$)', value: (data) => data.payment?.discount || 0, disabled: false },
      {
        header: 'Amount Charged (R$)',
        value: (data) => data.payment?.amount || 0,
        disabled: !isAdmin
      },
      { header: 'Fee (R$)', value: (data) => data.payment?.fee || 0, disabled: !isAdmin },
      {
        header: 'Net (R$)',
        value: (data) => (data.payment?.amount || 0) - (data.payment?.fee || 0),
        disabled: !isAdmin
      },
      {
        header: 'Created At',
        value: (data) => new Date(data.createdAt).toLocaleString(),
        disabled: false
      }
    ];

    columnConfig.forEach(({ header, value, disabled }) => {
      if (!disabled) {
        exportData[header] = value(data);
      }
    });

    return exportData;
  });
};
