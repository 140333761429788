import { TableRow } from 'src/@common/components/table';
import { GuestsFromList } from 'types/guests-list';
import cx from 'classnames';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as faCircleCheckLight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import { Link } from '@mui/material';

export const useTransactionsGuestsColumns = ({
  isLoading,
  onCheckIn
}: {
  isLoading?: boolean;
  onCheckIn: (guest: GuestsFromList) => void;
}) => {
  const columns: TableRow<GuestsFromList>[] = [
    {
      columnName: 'Actions',
      column: 'journeyName',
      render: (d) => (
        <>
          <LoadingButton
            disabled={isLoading}
            loading={isLoading}
            onClick={() => onCheckIn(d)}
            variant={d.checkedIn ? 'contained' : 'outlined'}
            size="small"
            color={d.checkedIn ? 'success' : 'secondary'}>
            {d.checkedIn ? 'Checked in' : 'Check in'}
          </LoadingButton>
        </>
      )
    },
    {
      columnName: 'Order',
      column: 'confirmationCode',
      render: (d) => (
        <Link href={`/transactions/orders/details/${d.orderId}`}>{d.confirmationCode}</Link>
      )
    },
    {
      columnName: 'Guest Name',
      render: (d) => (
        <div>
          <FontAwesomeIcon
            className={cx(d.checkedIn ? 'text-green-400' : '')}
            icon={d.checkedIn ? faCircleCheck : faCircleCheckLight}
          />
          <span className="pl-2">
            {d.firstName} {d.lastName}
          </span>
        </div>
      )
    },
    {
      columnName: 'Email',
      column: 'email'
    },
    {
      columnName: 'Main Buyer',
      column: 'customerName'
    },
    {
      columnName: 'Ticket',
      column: 'ticketName'
    },
    {
      columnName: 'Accommodation',
      column: 'accommodationName'
    },
    {
      columnName: 'Room',
      column: 'roomName'
    },
    {
      columnName: 'Journey',
      column: 'journeyName'
    }
  ];

  return { columns };
};
