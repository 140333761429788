import { Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import { TenantProvider } from 'src/layouts/dashboard/tenant-switch/tenant-provider';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';

// elements
import TicketsList from 'src/pages/inventory/tickets';
import AddNewTickets from 'src/pages/inventory/tickets/add-new';
import Assets from 'src/pages/inventory/assets';
import AssetsAddNew from 'src/pages/inventory/assets/add-new';
import JourneysList from 'src/pages/inventory/journeys';
import JourneysAddNew from 'src/pages/inventory/journeys/add-new';
import HotelsList from 'src/pages/inventory/accommodations';
import AddAccommodation from 'src/pages/inventory/accommodations/add-new';
import AccommodatioRoomsList from 'src/pages/inventory/accommodation-rooms';
import AddAccommodationRoom from 'src/pages/inventory/accommodation-rooms/add-new';
import { INVENTORY_PATHS } from 'src/paths';
import { ShippingListPage } from 'src/pages/inventory/shipping';

export const inventoryRoutes: RouteObject[] = [
  {
    path: 'inventory',
    element: (
      <TenantProvider>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </TenantProvider>
    ),
    children: [
      {
        path: '/inventory',
        element: <Navigate to={INVENTORY_PATHS.accommodation.list} replace />
      },
      {
        path: 'accommodations',
        children: [
          {
            path: INVENTORY_PATHS.accommodation.list,
            element: <HotelsList />
          },
          {
            path: INVENTORY_PATHS.accommodation.new,
            element: <AddAccommodation />
          },
          {
            path: INVENTORY_PATHS.accommodation.edit,
            element: <AddAccommodation />
          }
        ]
      },
      {
        path: 'accommodation-rooms',
        children: [
          {
            path: INVENTORY_PATHS.accommodation_rooms.list,
            element: <AccommodatioRoomsList />
          },
          {
            path: INVENTORY_PATHS.accommodation_rooms.new,
            element: <AddAccommodationRoom />
          },
          {
            path: INVENTORY_PATHS.accommodation_rooms.edit,
            element: <AddAccommodationRoom />
          }
        ]
      },
      {
        path: 'tickets',
        children: [
          {
            path: INVENTORY_PATHS.tickets.list,
            element: <TicketsList />
          },
          {
            path: INVENTORY_PATHS.tickets.new,
            element: <AddNewTickets />
          },
          {
            path: INVENTORY_PATHS.tickets.edit,
            element: <AddNewTickets />
          }
        ]
      },
      {
        path: 'journeys',
        children: [
          {
            path: INVENTORY_PATHS.journeys.list,
            element: <JourneysList />
          },
          {
            path: INVENTORY_PATHS.journeys.new,
            element: <JourneysAddNew />
          },
          {
            path: INVENTORY_PATHS.journeys.edit,
            element: <JourneysAddNew />
          }
        ]
      },
      {
        path: 'assets',
        children: [
          {
            path: INVENTORY_PATHS.assets.list,
            element: <Assets />
          },
          {
            path: INVENTORY_PATHS.assets.new,
            element: <AssetsAddNew />
          },
          {
            path: INVENTORY_PATHS.assets.edit,
            element: <AssetsAddNew />
          }
        ]
      },
      {
        path: 'shipping',
        children: [
          {
            path: INVENTORY_PATHS.shipping.list,
            element: <ShippingListPage />
          }
        ]
      }
    ]
  }
];
