import classNames from 'classnames';
import { getOrderLogText } from 'src/@common/logs/get-order-log-text';
import { formatDate } from '@fullcalendar/core';
import { useTranslation } from 'react-i18next';
import { OrderLog } from 'src/@common/types';
import { formatDateOptions } from 'src/pages/transactions/orders/details/order-detail';
import { forwardRef, HTMLAttributes, ReactNode, useMemo, useState } from 'react';

const ACTIVITY_LIMIT = 6;

const LogListItem = forwardRef<HTMLLIElement, HTMLAttributes<HTMLLIElement>>((props, ref) => (
  <li ref={ref} className="relative flex gap-x-4 items-baseline" {...props} />
));

const LogItem = forwardRef<
  HTMLDivElement,
  {
    log: OrderLog;
    showAll: boolean;
    logIndex: number;
    totalLogs: number;
    text: ReactNode;
  }
>(function ({ log, showAll, logIndex, totalLogs, text }, ref) {
  return (
    <>
      <div
        ref={ref}
        className={classNames('absolute left-0 top-0 flex w-6 justify-center -bottom-6', {
          hidden: !showAll && logIndex === totalLogs
        })}>
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>
      <div className="flex-auto text-xs text-gray-500 overflow-hidden">{text}</div>
      <time dateTime={log.timestamp} className="flex-none py-0.5 text-xs  text-gray-500">
        {formatDate(log.timestamp, formatDateOptions)}
      </time>
    </>
  );
});

export const OrderLogs = ({ logs }: { logs: OrderLog[] }) => {
  const { t } = useTranslation();
  const [sellAll, setSellAll] = useState(false);

  const validLogs = useMemo(() => {
    const list: Array<{ log: OrderLog; text: ReactNode }> = [];

    logs.forEach((log) => {
      const text = getOrderLogText(log, t);
      if (text) {
        list.push({ log, text });
      }
    });

    return list;
  }, [logs, t]);

  const visibleLogs = sellAll ? validLogs : validLogs?.slice(0, ACTIVITY_LIMIT);

  const showAll = validLogs.length > ACTIVITY_LIMIT;

  return (
    <div className="py-6 ">
      {/* Activity feed */}
      <h2 className="text-sm font-semibold leading-4 text-gray-900 flex items-center justify-between">
        Latest Activity
      </h2>
      <hr className="mt-4" />
      <ul className="my-6 space-y-6">
        {visibleLogs?.map(({ log, text }, logIndex) => {
          return (
            <LogListItem key={`${log.logType}_${log.timestamp}`}>
              <LogItem
                logIndex={logIndex}
                key={`log_${log.orderId}_${logIndex}`}
                log={log}
                showAll={showAll}
                totalLogs={visibleLogs.length - 1}
                text={text}
              />
            </LogListItem>
          );
        })}
        {showAll ? (
          <li className="relative flex gap-x-4 items-baseline">
            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
            </div>
            <div
              className="flex-auto overflow-hidden text-xs text-indigo-600 cursor-pointer font-semibold"
              onClick={() => setSellAll(!sellAll)}>
              {sellAll ? 'See less activity' : `See all activity (${validLogs.length})`}
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
};
