import * as React from 'react';
import { FC, useEffect, useId } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useQuery } from 'react-query';
import { AssetType } from 'types/assets';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';

type AccommParksProps = {
  disabled?: boolean;
  onChange: (perks: string[]) => void;
  selectItems?: string[];
};

export const AssetsSelector: FC<AccommParksProps> = ({ onChange, selectItems, disabled }) => {
  const id = useId();
  const [asset, setAsset] = React.useState<string[]>([]);

  const { isFetching: isFetchingAssets, data: assets } = useQuery<AssetType[]>(
    ['AssetsList'],
    () => {
      return apiFetch(API_ENDPOINT.Inventory.Assets).get();
    },
    { refetchOnWindowFocus: false }
  );

  const handleChange = (event: SelectChangeEvent<typeof asset>) => {
    const {
      target: { value }
    } = event;
    const list = typeof value === 'string' ? value.split(',') : value;
    onChange(list);
    setAsset(list);
  };

  useEffect(() => {
    setAsset(selectItems || []);
  }, [selectItems, setAsset]);

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel htmlFor={id}>Assets</InputLabel>
        <Select
          disabled={disabled}
          id={id}
          multiple
          value={asset}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: string) => {
                const assetItem = assets?.find((asset) => asset.uuid === value);
                return <Chip key={value} label={assetItem?.name} />;
              })}
            </Box>
          )}>
          {assets?.map((asset) => (
            <MenuItem key={asset.uuid} value={asset.uuid}>
              {asset.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
