export const CheckoutStatus = {
  // The checkout is created and is pending processing
  PENDING: 'pending',

  // The checkout is pending payment
  PENDING_PAYMENT: 'pending_payment',

  // The checkout is available to proceed
  AVAILABLE: 'available',

  // The checkout is fulfilled, now status is managed by the order itself
  FULFILED: 'fulfilled',

  // The checkout is unavailable to proceed
  UNAVAILABLE: 'unavailable',

  // The checkout has expired
  EXPIRED: 'expired'
} as const;

export const LogTypesEnum = {
  CheckoutCreated: 'CheckoutCreated',
  CheckoutStatusChanged: 'CheckoutStatusChanged',
  OrderStatusChanged: 'OrderStatusChanged',
  OrderCreated: 'OrderCreated',
  PaymentStatusChanged: 'PaymentStatusChanged',
  PaymentLinkCreated: 'PaymentLinkCreated',
  OrderConfirmationEmailSent: 'OrderConfirmationEmailSent'
} as const;

export const SHOP_URLS_BY_ENV = {
  dev: 'http://localhost:3000',
  staging: 'https://staging.d3ekde8vq96a7g.amplifyapp.com/',
  main: 'https://www.shop-globaljourneybrasil.insidemusicland.com/'
} as Record<string, string>;

export const SHOP_URL = SHOP_URLS_BY_ENV[process.env.ENV || 'main'];
