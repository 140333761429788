import CsvDownloadButton from 'react-json-to-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { ShippingOption } from 'types/shipping';
import { Country, State } from 'country-state-city';

export const ExportShippingButton = ({ data }: { data: ShippingOption[] }) => {
  return (
    <CsvDownloadButton
      filename={`inventory-shipping_${new Date().toISOString()}.csv`}
      data={data?.map((option) => ({
        CountryISO: option.country,
        Country: Country.getCountryByCode(option.country)?.name || option.country,
        State: option.state
          ? State.getStateByCodeAndCountry(option.state, option.country)?.name
          : 'N/A',
        Type: option.type,
        'Price (R$)': option.price,
        Courier: option.courier
      }))}
      style={{ all: 'unset' }}>
      <div
        className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 items-center"
        onClick={() => null}>
        Export table
        <FontAwesomeIcon icon={faArrowDownToLine} />
      </div>
    </CsvDownloadButton>
  );
};
