import { SegmentsSummaryWidget } from './sales-detailed';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { apiFetch } from 'src/api/helper/fetch';
import { useEffect, useMemo, useState } from 'react';
import { SalesSummaryWidget } from 'src/pages/dashboard/common/summary-widget';
import { DashboardTicket } from 'types/dashboard/ticket';
import { TableFilter } from 'src/@common/components/table-filter';
import { Page } from 'src/components/page';
import { trackFilterApplied } from 'src/@common/analytics/events';

const DASHBOARD_REFETCH_INTERVAL = 30000;

const DashboardTickets = () => {
  const [filters, setFilters] = useState<{ ticket?: string[]; search?: string }>({});

  const {
    data: detailedData,
    isLoading: isLoadingDetailed,
    isFetching: isFetchingDetailed,
    refetch: refetchTicketsDetailed
  } = useQuery<DashboardTicket[]>(
    ['DashboardTicketDetailed'],
    async () => {
      return apiFetch('/dashboard/tickets').get();
    },
    {
      refetchInterval: DASHBOARD_REFETCH_INTERVAL
    }
  );

  useEffect(() => {
    trackFilterApplied(filters, 'dashboard', 'tickets');
  }, [filters]);

  const filteredData = detailedData?.filter((data) => {
    if (filters.ticket && filters.ticket.length > 0) {
      return filters.ticket.includes(data.id);
    }

    if (filters.search) {
      const search = filters.search.toLowerCase();
      return data.ticketName.toLowerCase().includes(search);
    }

    return true;
  });

  const filers = useMemo(() => {
    return [
      {
        id: 'ticket',
        name: 'Ticket',
        options: detailedData?.map((d) => ({ label: d.ticketName, value: d.id })) || []
      }
    ];
  }, [detailedData]);

  const summary = filteredData?.reduce(
    (acc, journey) => {
      return {
        totalSold: acc.totalSold + Number(journey.sold),
        totalOnHold: acc.totalOnHold + Number(journey.onHold),
        totalAvailable: acc.totalAvailable + Number(journey.available),
        salesGrossRevenue: acc.salesGrossRevenue + Number(journey.grossRevenue || 0),
        totalCharges: 0
      };
    },
    {
      totalSold: 0,
      totalOnHold: 0,
      totalAvailable: 0,
      totalCharges: 0,
      salesGrossRevenue: 0
    }
  );

  return (
    <Page
      title="Tickets"
      cta={
        <div role="button" onClick={() => refetchTicketsDetailed()}>
          <FontAwesomeIcon
            className={cn('text-indigo-600', {
              'animate-spin': isFetchingDetailed
            })}
            icon={faArrowsRotate}
          />
        </div>
      }>
      <TableFilter
        onFilterChange={setFilters}
        filters={filers}
        disabled={isLoadingDetailed}
        onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
      />
      <SalesSummaryWidget data={summary} isLoading={isLoadingDetailed} />
      <SegmentsSummaryWidget data={filteredData} isLoading={isLoadingDetailed} />
    </Page>
  );
};

export default DashboardTickets;
