import React, { ReactNode } from 'react';
import cx from 'classnames'; // Utility type for extracting valid keys (including nested) from an object

// Utility type for extracting valid keys (including nested) from an object
type DotNotation<T> = T extends object
  ? {
      [K in keyof T & string]: T[K] extends object ? `${K}` | `${K}.${DotNotation<T[K]>}` : `${K}`;
    }[keyof T & string]
  : never;

export type TableRow<T> = {
  column?: DotNotation<T>;
  columnName?: string | ReactNode;
  className?: string;
  render?: (data: T) => ReactNode;
};

export type TableProps<T> = {
  data: T[];
  rows: TableRow<T>[];
  className?: string;
  sticky?: boolean;
};

// Utility function to access nested properties with some type safety
const getNestedProperty = <T,>(obj: T, path: DotNotation<T>): any => {
  return path.split('.').reduce((acc: any, part: string) => acc && acc[part], obj);
};

export function Table<T>({ data, rows, className, sticky = true }: TableProps<T>) {
  return (
    <table
      className={cx(
        'block overflow-auto whitespace-nowrap min-w-full divide-y divide-gray-200 w-full',
        className
      )}>
      <thead
        className={cx('z-10 bg-white', {
          sticky: sticky
        })}>
        <tr className="">
          {rows.map((row) => (
            <th
              key={row.column}
              scope="col"
              className={cx('py-3.5 text-left text-sm font-semibold text-gray-900', row.className)}>
              {row.columnName ?? null}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {data.map((rowData, rowIndex) => (
          <tr key={`table_row_${rowIndex}`}>
            {rows.map((cell, cellIndex) => (
              <td
                key={`${cell.columnName}-${cellIndex}`}
                className={cx('py-2 pr-4 text-sm text-gray-900', cell.className)}>
                {cell.render
                  ? cell.render(rowData)
                  : cell.column
                    ? getNestedProperty(rowData, cell.column) ?? ''
                    : ''}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
