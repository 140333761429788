import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { File, FileDropzone } from 'src/components/file-dropzone';
import { FC, useCallback, useState } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import { ImageList, ImageListItem } from '@mui/material';

type FileWithUrl = { file: File; url: string };

type PageImgsUploadProps = {
  disabled?: boolean;
  files: File[];
  defaultImages?: string[];
  onFileDrop: (files: File[]) => void;
};

const PageImgsUpload: FC<PageImgsUploadProps> = ({ onFileDrop, disabled, ...props }) => {
  const [paths, setPaths] = useState<FileWithUrl[]>([]);

  const handleOnDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      const newFiles = acceptedFiles.map((file) => ({
        file,
        url: URL.createObjectURL(file)
      }));
      onFileDrop(acceptedFiles);
      setPaths(newFiles);
    },
    [onFileDrop]
  );

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <Stack spacing={1}>
          <Typography variant="h6">Images</Typography>
          <Typography color="text.secondary" variant="body2">
            Images will appear in the store front of the package.
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={8}>
        <ImageList variant="masonry" cols={3} gap={8}>
          {props.defaultImages?.map((item) => (
            <ImageListItem key={item}>
              <img alt="accommodation image" src={`${item}?tr=w-200`} loading="lazy" />
            </ImageListItem>
          ))}
          {paths.map((item) => (
            <ImageListItem key={item.url}>
              <img srcSet={item.url} src={item.url} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
        {disabled ? null : (
          <FileDropzone
            accept={{ 'image/*': [] }}
            caption="(SVG, JPG, PNG, or gif maximum 900x400)"
            onDrop={handleOnDrop}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PageImgsUpload;
