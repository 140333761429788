import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { OrderDetails } from 'types/order-detail';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { useState } from 'react';

export const useOrderDetails = () => {
  const [editAddress, setEditAddress] = useState(false);
  const [editShipping, setEditShipping] = useState(false);
  const [editGuests, setEditGuests] = useState(false);
  const { uuid } = useParams<{ uuid?: string }>();
  const { isLoading, data, refetch } = useQuery<OrderDetails[]>(['OrderDetailsById', uuid], () => {
    return apiFetch(`${API_ENDPOINT.Transactions.Orders}`).get({ uuid: String(uuid) });
  });

  const orderDetials = data?.[0];

  return {
    isLoading,
    data,
    refetch,
    orderDetials,
    forms: {
      address: {
        editAddress,
        setEditAddress
      },
      shipping: {
        editShipping,
        setEditShipping
      },
      guests: {
        editGuests,
        setEditGuests
      }
    }
  };
};
