import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';

export type IPagination = {
  total: number;
  page: number;
  perPage: number;
  onPageChange: (page: number) => void;
  onPrevious: () => void;
  onNext: () => void;
};

export const Pagination: React.FC<IPagination> = ({
  total,
  page,
  perPage,
  onPageChange,
  onPrevious,
  onNext
}) => {
  const totalPages = Math.ceil(total / perPage);
  const startPage = page > 2 ? page - 1 : 1;
  const endPage = page + 2 < totalPages ? page + 2 : totalPages;
  const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          disabled={page === 1}
          onClick={onPrevious}
          className="inline-flex disabled:cursor-not-allowed items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map((p) => (
          <button
            key={p}
            onClick={() => onPageChange(p)}
            className={`inline-flex items-center border-t-2 ${
              p === page
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } px-4 pt-4 text-sm font-medium`}>
            {p}
          </button>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          disabled={page === totalPages}
          onClick={onNext}
          className="inline-flex disabled:cursor-not-allowed items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
          Next
          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
};
