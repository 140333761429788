import PageContainer from 'src/components/page-container';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, FormControl, InputAdornment, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { useParams } from 'react-router';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePriceStore } from 'src/pages/inventory/accommodation-rooms/hooks/use-price-store';
import { TicketType } from 'types/ticket';
import { useTicketsStore } from 'src/pages/inventory/tickets/hooks/use-tickets-store';
import { PriceTypeOptionsSelector } from 'src/pages/inventory/tickets/components/price-type-options';
import { PriceTypeOptions } from 'src/constants';
import { INVENTORY_PATHS } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';
import { ROLES } from 'src/guards/role-based-guard';

export type TicketRequest = Exclude<TicketType, ''>;

const AddNew = () => {
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid?: string }>();
  const { resetPrices } = usePriceStore();
  const { user } = useAuth();

  const canEdit = user?.role === ROLES.Admin;

  /**
   * Load accomm. room by uuid
   */
  const { isLoading: isFetching, data } = useQuery<TicketType>(
    ['TicketDetails', uuid],
    () => {
      return apiFetch(API_ENDPOINT.Inventory.Tickets).get({ uuid: String(uuid) });
    },
    { enabled: Boolean(uuid) }
  );

  const { isLoading, onSubmit } = useTicketsStore((state) => ({
    onSubmit: state.onSubmit,
    isLoading: state.isLoading
  }));

  const form = useForm<TicketRequest>({
    values: data,
    defaultValues: {
      uuid: crypto.randomUUID(),
      price: { price: undefined, live: true, id: crypto.randomUUID() }
    }
  });

  const handleSubmit = useCallback(
    async (data: TicketType) => {
      await onSubmit(data, uuid);
      resetPrices();
      navigate(INVENTORY_PATHS.tickets.list);
    },
    [navigate, onSubmit, resetPrices, uuid]
  );

  return (
    <PageContainer
      breadcrumbs={[
        { title: 'Inventory' },
        { title: 'Tickets', href: INVENTORY_PATHS.accommodation_rooms.list },
        { title: uuid ? 'Edit' : 'Add new' }
      ]}
      isLoading={isLoading || isFetching}
      seo="Inventory > Tickets"
      title={uuid ? 'Edit ticket' : 'New ticket'}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={4}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                  <Typography variant="h6">Basic details</Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack spacing={3}>
                    <Controller
                      name="name"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          disabled={!canEdit}
                          label="Name"
                          InputLabelProps={{ shrink: !!field.value }}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="description"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          disabled={!canEdit}
                          multiline
                          label="Description"
                          InputLabelProps={{ shrink: !!field.value }}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="price.price"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl>
                          <TextField
                            disabled={!canEdit}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }}
                            label="Price"
                            value={field.value}
                            onChange={field.onChange}
                            type="number"
                          />
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="priceType"
                      control={form.control}
                      rules={{ required: true }}
                      defaultValue={PriceTypeOptions.PerPerson}
                      render={({ field }) => (
                        <PriceTypeOptionsSelector
                          disabled={!canEdit}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                  <Typography variant="h6">Available tickets</Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack spacing={1}>
                    <Alert severity="warning">
                      Availability will determine the number of tickets that can be sold
                    </Alert>
                    <FormControl>
                      <Controller
                        name="availability"
                        control={form.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            disabled={!canEdit}
                            color="secondary"
                            variant="outlined"
                            label="Quantity"
                            InputLabelProps={{ shrink: !!field.value }}
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Stack>
        <div className="pt-6 flex justify-end">
          <Button variant="contained" type="submit" disabled={!canEdit}>
            Submit
          </Button>
        </div>
      </form>
    </PageContainer>
  );
};

export default AddNew;
