import { Page } from 'src/components/page';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { ShippingOption } from 'types/shipping';
import { useMemo, useState } from 'react';
import { TableFilter } from 'src/@common/components/table-filter';
import { LoadingContainer } from 'src/components/loading';
import { ShippingOptionsTable } from 'src/pages/inventory/shipping/table';
import { ExportShippingButton } from 'src/pages/inventory/shipping/components/export-shipping';
import { Country, State } from 'country-state-city';

export const ShippingListPage = () => {
  const [filters, setFilters] = useState<{
    search?: string;
  }>({});

  const { isLoading, data } = useQuery<ShippingOption[]>([API_ENDPOINT.Inventory.Shipping], () => {
    return apiFetch(API_ENDPOINT.Inventory.Shipping).get();
  });

  const filteredData = useMemo(
    () =>
      data?.filter((option) => {
        if (filters.search) {
          const search = filters.search.toLowerCase();

          const countryName = Country.getCountryByCode(option.country)?.name || option.country;
          const stateName = option.state
            ? State.getStateByCodeAndCountry(option.state, option.country)?.name
            : option.state;

          return (
            countryName.toLowerCase().includes(search) || stateName?.toLowerCase().includes(search)
          );
        }

        return true;
      }),
    [filters.search, data]
  );

  return (
    <Page title="Shipping" description="" cta={<ExportShippingButton data={filteredData || []} />}>
      <TableFilter
        onFilterChange={setFilters}
        filters={[]}
        onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
      />
      {isLoading ? <LoadingContainer /> : <ShippingOptionsTable data={filteredData || []} />}
    </Page>
  );
};
