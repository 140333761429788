/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "storefrontAdminApi",
            "endpoint": "https://io1y8765ze.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:62e7f684-8d4b-4b92-820d-9e6f9c3afee0",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_7Rj64AOBn",
    "aws_user_pools_web_client_id": "23sl059umg0rq8o89lea4rohoh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "storefront-admin-images223016-main",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
