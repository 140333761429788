import { useFormDrawer } from 'src/@design-system/form/container';
import { OrderDetails, OrderDetailsCustomer } from 'types/order-detail';
import { countryList } from 'src/@common/country-state';
import { useCallback, useState } from 'react';
import { apiFetch } from 'src/api/helper/fetch';
import toast from 'react-hot-toast';
import { useOrderDetails } from 'src/pages/transactions/orders/hooks/use-order-details';
import { useAuth } from 'src/hooks/use-auth';

type OrderEditGuestsFormProps = {
  orderDetails: OrderDetails;
  open: boolean;
  orderId: string;
  onClose: () => void;
};

export const OrderEditGuestsForm = ({
  orderId,
  orderDetails,
  open,
  onClose
}: OrderEditGuestsFormProps) => {
  const { customer: customerDetails } = orderDetails;
  const { user } = useAuth();
  const { refetch } = useOrderDetails();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: Omit<OrderDetailsCustomer, 'address'>, dirtyFields: string[]) => {
      setIsLoading(true);

      try {
        await apiFetch(`/order/customer/${orderId}/${customerDetails.id}`).put(
          {
            customer: data,
            dirtyFields,
            updatedFields: Object.keys(data)
          },
          {
            type: 'customer'
          }
        );
        onClose();
        toast.success('Main buyer details has been saved!');
        await refetch();
      } catch (error) {
        toast.error('An error occurred while saving your changes. Please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [customerDetails.id, onClose, orderId, refetch]
  );

  const { FormDrawer, form } = useFormDrawer<Omit<OrderDetailsCustomer, 'address'>>({
    defaultValues: customerDetails,
    disabled: isLoading || !user?.isAdmin,
    rules: {
      required: true
    }
  });

  return (
    <FormDrawer
      form={form}
      disabled={isLoading || !user?.isAdmin}
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      title="Main Buyer"
      description="Edit details of the main buyer"
      onSaved={onSubmit}>
      <FormDrawer.Input label="First Name" name="firstName" />
      <FormDrawer.Input label="Last Name" name="lastName" />
      <FormDrawer.Input
        label="Email"
        name="email"
        type="email"
        rules={{
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address'
          }
        }}
      />
      <FormDrawer.Input label="Birth Date" name="birthDate" type="date" />
      <FormDrawer.Combobox label="Nationality" name="nationality" options={countryList} />
      <FormDrawer.Select label="Gender" name="gender">
        <option value="1">Male</option>
        <option value="2">Female</option>
        <option value="9">Other</option>
      </FormDrawer.Select>
      <FormDrawer.Select label="Document Type" name="documentType">
        <option value="cpf">CPF</option>
        <option value="passport">Passport</option>
        <option value="dni">DNI</option>
      </FormDrawer.Select>
      <FormDrawer.Input label="Document Value" name="documentValue" />
    </FormDrawer>
  );
};
