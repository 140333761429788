import type { FC } from "react";
import PropTypes from "prop-types";
import type { DropzoneOptions, FileWithPath } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import Upload01Icon from "@untitled-ui/icons-react/build/esm/Upload01";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { bytesToSize } from "src/utils/bytes-to-size";

export type File = FileWithPath;

interface FileDropzoneProps extends DropzoneOptions {
  caption?: string;
  files?: File[];
  onRemove?: (file: File) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
}

export const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const {
    caption,
    files = [],
    onRemove,
    onRemoveAll,
    onUpload,
    maxFiles,
    ...other
  } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...other,
  });

  const hasAnyFiles = files.length > 0;
  const disabled = files?.length >= (maxFiles ?? 99);

  return (
    <div>
      {!disabled ? (
        <Box
          sx={{
            alignItems: "center",
            border: 1,
            borderRadius: 1,
            borderStyle: "dashed",
            borderColor: "divider",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            outline: "none",
            p: 2,
            ...(isDragActive && {
              backgroundColor: "action.active",
              opacity: 0.5,
            }),
            "&:hover": {
              backgroundColor: "action.hover",
              cursor: "pointer",
              opacity: 0.5,
            },
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Stack alignItems="center" direction="row" spacing={2}>
            <Avatar
              sx={{
                height: 64,
                width: 64,
              }}
            >
              <SvgIcon>
                <Upload01Icon />
              </SvgIcon>
            </Avatar>
            <Stack spacing={1}>
              <Typography
                sx={{
                  "& span": {
                    textDecoration: "underline",
                  },
                }}
                variant="h6"
              >
                <span>Click to upload</span> or drag and drop
              </Typography>
              {caption && (
                <Typography color="text.secondary" variant="body2">
                  {caption}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
      ) : null}
      {hasAnyFiles && (
        <Box sx={{ mt: 2 }}>
          <List>
            {files.map((file) => {
              const extension = file.name.split(".").pop();

              return (
                <ListItem
                  key={file.path}
                  sx={{
                    border: 1,
                    borderColor: "divider",
                    borderRadius: 1,
                    "& + &": {
                      mt: 1,
                    },
                  }}
                >
                  <ListItemIcon>
                    {/*<img src={file.url} width={200} height={200} />*/}
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: "subtitle2" }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title="Remove">
                    <IconButton edge="end" onClick={() => onRemove?.(file)}>
                      <SvgIcon>
                        <XIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </ListItem>
              );
            })}
          </List>
          {/*<Stack*/}
          {/*  alignItems="center"*/}
          {/*  direction="row"*/}
          {/*  justifyContent="flex-end"*/}
          {/*  spacing={2}*/}
          {/*  sx={{ mt: 2 }}*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    color="inherit"*/}
          {/*    onClick={onRemoveAll}*/}
          {/*    size="small"*/}
          {/*    type="button"*/}
          {/*  >*/}
          {/*    Remove All*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    onClick={onUpload}*/}
          {/*    size="small"*/}
          {/*    type="button"*/}
          {/*    variant="contained"*/}
          {/*  >*/}
          {/*    Upload*/}
          {/*  </Button>*/}
          {/*</Stack>*/}
        </Box>
      )}
    </div>
  );
};

FileDropzone.propTypes = {
  caption: PropTypes.string,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  // From Dropzone
  accept: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  ),
  disabled: PropTypes.bool,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  preventDropOnDocument: PropTypes.bool,
};
