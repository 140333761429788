import { Dialog } from 'src/@design-system/dialog';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faDownload } from '@fortawesome/pro-light-svg-icons';
import { faEye } from '@fortawesome/pro-duotone-svg-icons';
import toast from 'react-hot-toast';

export const ConfirmationEmailViwer = ({ html }: { html: string }) => {
  const [open, setOpen] = useState(false);

  const onCopyToClipboard = async () => {
    await navigator.clipboard.writeText(html);
    toast.success('Email HTML copied to clipboard');
  };

  const onDownloadAsHTML = () => {
    const blob = new Blob([html], { type: 'text/html' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');

    a.href = url;
    a.download = 'confirmation-email.html';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
    toast.success('Email HTML downloaded');
  };

  return (
    <>
      <div role="button" className="font-medium text-gray-900 pr-1" onClick={() => setOpen(true)}>
        Confirmation email sent,{' '}
        <span className="text-indigo-600 font-bold">
          view <FontAwesomeIcon className="h-3 w-3 shrink-0" aria-hidden="true" icon={faEye} />
        </span>
      </div>
      <Dialog size="lg" open={open} onClose={() => setOpen(false)} title="Confirmation Email">
        <div>
          <div className="relative pb-2 flex justify-between text-sm font-bold">
            <button
              onClick={onDownloadAsHTML}
              className="text-indigo-600 hover:text-indigo-800 flex gap-1 items-center">
              Download
              <FontAwesomeIcon className="h-3 w-3 shrink-0" aria-hidden="true" icon={faDownload} />
            </button>
            <button
              onClick={onCopyToClipboard}
              className="text-indigo-600 hover:text-indigo-800 flex gap-1 items-center">
              Copy HTML
              <FontAwesomeIcon className="h-3 w-3 shrink-0" aria-hidden="true" icon={faCopy} />
            </button>
          </div>
          <iframe
            className=" rounded-md w-[600px] h-[600px]"
            srcDoc={html}
            title="email confirmation"
          />
        </div>
      </Dialog>
    </>
  );
};
