import { Controller, UseFormReturn } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Journey } from 'types/journeys';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { JourneyTypeSelectInput } from 'src/pages/inventory/journeys/components/journey-type-select-input';
import { LocationCountrySelector } from 'src/components/location-country-selector';
import { LocationStateSelector } from 'src/components/location-state-selector';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material';
import { JourneyDetailForm } from 'src/pages/inventory/journeys/components/journey-detail-form';

export const AddNewForm = ({
  form,
  data,
  disabled
}: {
  form: UseFormReturn<Journey>;
  data?: Journey;
  disabled?: boolean;
}) => {
  const [returnJourney, setReturnJourney] = useState(false);

  useEffect(() => {
    if (!returnJourney && data && !data?.return) {
      form.setValue('return', undefined);
    }
  }, [data, data?.return, form, returnJourney]);

  useEffect(() => {
    if (data?.return) {
      setReturnJourney(true);
    }
  }, [data?.return]);

  const selectedCountry = form.watch('origin.country');

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <Typography variant="h6">Basic details</Typography>
            </Grid>
            <Grid xs={12} md={8}>
              <Stack spacing={3}>
                <>
                  <Controller
                    name="type"
                    control={form.control}
                    render={({ field }) => (
                      <JourneyTypeSelectInput disabled={disabled} {...field} />
                    )}
                  />

                  <div className="flex gap-4">
                    <Controller
                      name="origin.country"
                      control={form.control}
                      render={({ field }) => (
                        <LocationCountrySelector disabled={disabled} {...field} />
                      )}
                    />

                    <Controller
                      name="origin.state"
                      control={form.control}
                      render={({ field }) => (
                        <LocationStateSelector
                          disabled={disabled}
                          countryCode={selectedCountry}
                          {...field}
                        />
                      )}
                    />
                  </div>

                  <Controller
                    name="price.price"
                    control={form.control}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                        <OutlinedInput
                          disabled={disabled}
                          startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                          label="Price"
                          value={field.value}
                          onChange={field.onChange}
                          type="number"
                        />
                      </FormControl>
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        checked={returnJourney}
                        onChange={(v) => {
                          setReturnJourney(v.target.checked);
                        }}
                        value={returnJourney}
                      />
                    }
                    label="Add return journey"
                  />
                </>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <Typography variant="h6">Available journeys</Typography>
            </Grid>
            <Grid xs={12} md={8}>
              <Stack spacing={1}>
                <Alert severity="warning">
                  Availability will determine the number of journeys that can be sold
                </Alert>
                <FormControl>
                  <Controller
                    name="availability"
                    control={form.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        disabled={disabled}
                        color="secondary"
                        variant="outlined"
                        label="Quantity"
                        InputLabelProps={{ shrink: !!field.value }}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <Typography variant="h6">Outbound Details</Typography>
            </Grid>
            <Grid xs={12} md={8}>
              <Stack spacing={1}>
                <JourneyDetailForm
                  disabled={disabled}
                  title="Outbound Details"
                  name="outbound"
                  form={form}
                />
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {returnJourney ? (
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid xs={12} md={4}>
                <Typography variant="h6">Return Details</Typography>
              </Grid>
              <Grid xs={12} md={8}>
                <Stack spacing={1}>
                  <JourneyDetailForm
                    disabled={disabled}
                    title="Return Details"
                    name="return"
                    form={form}
                  />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : null}
    </>
  );
};
