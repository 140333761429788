import { Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import { TenantProvider } from 'src/layouts/dashboard/tenant-switch/tenant-provider';
import DashboardAccommodation from 'src/pages/dashboard/accommodations';
import DashboardJourneys from 'src/pages/dashboard/journeys';
import DashboardTickets from 'src/pages/dashboard/tickets';
import { DashboardSummary } from 'src/pages/dashboard/summary';
import { DASHBOARD_PATHS } from 'src/paths';

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    element: (
      <TenantProvider>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </TenantProvider>
    ),
    children: [
      {
        path: '/dashboard',
        element: <Navigate to={DASHBOARD_PATHS.summary.index} replace />
      },
      {
        path: DASHBOARD_PATHS.summary.index,
        element: <DashboardSummary />
      },
      {
        path: DASHBOARD_PATHS.accommodation.index,
        element: <DashboardAccommodation />
      },
      {
        path: DASHBOARD_PATHS.journeys.index,
        element: <DashboardJourneys />
      },
      {
        path: DASHBOARD_PATHS.tickets.index,
        element: <DashboardTickets />
      }
    ]
  }
];
