import { create } from 'zustand';
import { AccommodationRequest } from 'src/pages/inventory/accommodations/add-new';
import { produce } from 'immer';
import uploadImageUtils from 'src/utils/upload-image';
import { Enumerable } from 'types/legacy/common';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import toast from 'react-hot-toast';

export const ImageType = {
  Image: 'image',
  Background: 'background'
} as const;

type usePackagesStoreProps = {
  isLoading: boolean;
  images: { type: Enumerable<typeof ImageType>; image: File }[];
  setImage: (files: File[], type: Enumerable<typeof ImageType>) => void;
  onSubmit: (data: AccommodationRequest, uuid?: string) => Promise<void>;
  setLoading: (isLoading: boolean) => void;
  uploadImage: (files: File, fileName: string, folder: string) => Promise<string>;
  cleanState: () => void;
};

export const useAccommodationStore = create<usePackagesStoreProps>((set, get) => ({
  isLoading: false,
  images: [],
  cleanState: () =>
    set(
      produce<usePackagesStoreProps>((state) => {
        state.isLoading = false;
        state.images = [];
      })
    ),
  setLoading: (isLoading) =>
    set(
      produce<usePackagesStoreProps>((state) => {
        state.isLoading = isLoading;
      })
    ),
  setImage: (files, type) => {
    set(
      produce<usePackagesStoreProps>((state) => {
        for (const file of files) {
          state.images.push({ type, image: file });
        }
      })
    );
  },
  uploadImage: async (file, fileName: string, folder: string) => {
    return await uploadImageUtils(file, fileName, folder);
  },
  onSubmit: async (data, uuid) => {
    const { setLoading, uploadImage, images } = get();
    setLoading(true);

    const uploadSingleImage = async (img: File, suffix: string, folder: string) => {
      return await uploadImage(img, `${suffix}`, `${folder}/${data.uuid}/images`);
    };

    try {
      /**
       * Upload background image
       */
      const bgImage = images.find((img) => img.type === ImageType.Background);
      const mainBgImage = bgImage
        ? await uploadSingleImage(bgImage.image, 'background', 'accommodations')
        : undefined;

      /**
       * Upload other images
       */
      const otherImagesPromises = images
        .filter((img) => img.type === ImageType.Image)
        .map((img, index) => uploadSingleImage(img.image, `image_${index}`, 'accommodations'));

      const otherImages = await Promise.all(otherImagesPromises);

      /**
       * Send data to the API
       */
      const verb = uuid ? 'put' : 'post';
      await apiFetch<AccommodationRequest>(API_ENDPOINT.Inventory.Accommodation)[verb]({
        ...data,
        mainImage: mainBgImage || data.mainImage,
        images: otherImages.length > 0 ? otherImages : data.images
      });
    } catch (error) {
      console.error('Error submitting accommodation request:', error);
      toast.error('Ops! An error ocurred, try again!');
    } finally {
      get().cleanState();
      toast.success(
        uuid ? 'Accommodation updated sucessfully' : 'Accommodation created sucessfully'
      );
    }
  }
}));
