import { ReactNode } from 'react';
import cn from 'classnames';

const badgeOptions = {
  neutral: {
    bgColor: 'bg-gray-50',
    textColor: 'text-gray-600',
    ringColor: 'ring-gray-500/10'
  },
  danger: {
    bgColor: 'bg-red-50',
    textColor: 'text-red-700',
    ringColor: 'ring-red-600/10'
  },
  warning: {
    label: 'Inverted',
    bgColor: 'bg-yellow-50',
    textColor: 'text-yellow-900',
    ringColor: 'ring-yellow-600/20'
  },
  success: {
    bgColor: 'bg-green-50',
    textColor: 'text-green-700',
    ringColor: 'ring-green-600/20'
  },
  blue: {
    bgColor: 'bg-blue-50',
    textColor: 'text-blue-700',
    ringColor: 'ring-blue-700/10'
  },
  indigo: {
    bgColor: 'bg-indigo-50',
    textColor: 'text-indigo-700',
    ringColor: 'ring-indigo-700/10'
  },
  purple: {
    bgColor: 'bg-purple-50',
    textColor: 'text-purple-700',
    ringColor: 'ring-purple-700/10'
  },
  pink: {
    bgColor: 'bg-pink-50',
    textColor: 'text-pink-800',
    ringColor: 'ring-pink-700/10'
  }
};

export type BadgeColor = keyof typeof badgeOptions;

export type BadgeProps = {
  value: string;
  className?: string;
  color: BadgeColor;
  icon?: ReactNode;
  size?: 'md' | 'sm';
  variant?: 'solid' | 'outlined' | 'inverted';
};

export const Badge = ({ value, color, icon, className, size = 'md' }: BadgeProps) => {
  const option = badgeOptions[color];
  return (
    <span
      className={cn(
        {
          [`w-fit gap-1 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${option.bgColor} ${option.textColor} ring-1 ring-inset ${option.ringColor}`]:
            size === 'md',
          [`w-fit inline-flex items-center gap-x-1.5 rounded-full bg-gray-100 px-1.5 py-0.5 text-xs font-medium ${option.bgColor} ${option.textColor} ${option.ringColor}`]:
            size === 'sm'
        },
        className
      )}>
      {icon}
      {value}
    </span>
  );
};
