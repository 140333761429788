import { LoadingContainer } from 'src/components/loading';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const Page = ({
  title,
  children,
  cta,
  isLoading
}: {
  title: string;
  isLoading?: boolean;
  description?: string;
  children: React.ReactNode;
  cta?: React.ReactNode;
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="mb-24">
      <h1 className="text-2xl font-bold p-8 flex justify-between items-center">
        {title}
        {cta ?? null}
      </h1>
      <div className="px-8">{isLoading ? <LoadingContainer /> : children}</div>
    </div>
  );
};
