import { Page as PageType } from 'types/template/page';
import { LoadingContainer } from 'src/components/loading';
import Table from 'src/pages/inventory/accommodations/table';
import { Page } from 'src/components/page';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { Accommodation } from 'types/accommodation';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_PATHS } from 'src/paths';
import { RoleBasedGuard, ROLES } from 'src/guards/role-based-guard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { TableFilter } from 'src/@common/components/table-filter';
import { useEffect, useMemo, useState } from 'react';
import { trackFilterApplied } from 'src/@common/analytics/events';

const HotelsList: PageType = () => {
  const [filters, setFilters] = useState<{
    search?: string;
  }>({});
  const navigate = useNavigate();
  const { isLoading, data: hotelList } = useQuery<Accommodation[]>(['Accommodations'], () => {
    return apiFetch(API_ENDPOINT.Inventory.Accommodation).get();
  });

  const onAddNew = () => navigate(INVENTORY_PATHS.accommodation.new);

  useEffect(() => {
    trackFilterApplied(filters, 'inventory', 'accommodation');
  }, [filters]);

  const filteredData = useMemo(() => {
    return hotelList?.filter((data) => {
      if (filters.search) {
        const search = filters.search.toLowerCase();
        return (
          data.location.address.toLowerCase().includes(search) ||
          data.name.toLowerCase().includes(search) ||
          data.category.toLowerCase().includes(search)
        );
      }

      return true;
    });
  }, [hotelList, filters.search]);

  return (
    <>
      <Page
        title="Accommodations"
        description=""
        cta={
          <RoleBasedGuard permissions={ROLES.Admin}>
            <button
              className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 items-center"
              onClick={onAddNew}>
              Add new accommodation
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </RoleBasedGuard>
        }>
        <TableFilter
          onFilterChange={setFilters}
          filters={[]}
          onSearch={(search) => setFilters((prev) => ({ ...prev, search }))}
        />
        {isLoading ? <LoadingContainer /> : <Table data={filteredData || []} />}
      </Page>
    </>
  );
};

export default HotelsList;
