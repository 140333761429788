import {
  AccommodationCategoryType,
  AccommodationPerksType,
  AccommodationType,
  MealPlanType,
  RoomCategoryType
} from 'types/accommodation';

const isProd = process.env?.NODE_ENV === 'production';

export const SimpleLocalizeUrl = isProd
  ? 'https://simplelocalize.io/dashboard/projects/?hash=895da1169aff4765a46441df7a526dea'
  : 'https://simplelocalize.io/dashboard/projects/?hash=a08f94cf8f094a78aff8889ffff0a6b0';
export const IMAGEKIT_URL = 'https://ik.imagekit.io/insidemusicland';

export const MealPlanTypes = {
  None: 'none',
  Breakfast: 'breakfast',
  HalfBoard: 'half_board',
  FullBoard: 'full_board'
} as Record<string, MealPlanType>;

export const AccommodationTypes = {
  Hotel: 'hotel',
  Camping: 'camping'
} as Record<string, AccommodationType>;

export const AccommodationCategoryTypes = {
  Bronze: 'bronze',
  Silver: 'silver',
  Gold: 'gold',
  Thematic: 'thematic',
  EasyTents: 'easy_tents',
  MG: 'magnificent_greens'
} as Record<string, AccommodationCategoryType>;

export const AccommodationPerksTypes = {
  Reception24h: 'reception_24h',
  AirConditioner: 'air_conditioner',
  Pool: 'pool',
  HairDryer: 'hair_dryer',
  LuggageStorage: 'luggage_storage',
  PrivateBathroom: 'private_bathroom'
} as Record<string, AccommodationPerksType>;

export const PriceTypeOptions = {
  PerPerson: 'per_person',
  Total: 'total'
} as const;

export const RoomCategory = {
  superiorDoubleRoom: 'superior_double_room',
  twinRoom: 'twin_room',
  superiorTwin: 'superior_twin',
  superior: 'superior',
  executiveSuite: 'executive_suite',
  masterSuite: 'master_suite',
  familySuite: 'family_suite',
  tripleRoom: 'triple_room',
  kingRoom: 'king_room',
  superiorPNE: 'superior_pne',
  duplexSuite: 'duplex_suite',
  doubleRoom: 'double_room',
  singleRoom: 'single_room',
  quadrupleRoom: 'quadruple_room'
} as typeof RoomCategoryType;
