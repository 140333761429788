import { priceToCurrency } from 'src/utils/price-to-currency';
import CsvDownloadButton from 'react-json-to-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { Table } from 'src/@common/components/table';
import { DashboardTicket } from 'types/dashboard/ticket';
import { LoadingContainer } from 'src/components/loading';
import { StatusLabel, StatusLabelWidget } from 'src/pages/dashboard/common/status-label-widget';
import { Tooltip } from '@mui/material';

export const SegmentsSummaryWidget = ({
  data,
  isLoading
}: {
  data?: DashboardTicket[];
  isLoading: boolean;
}) => {
  return (
    <div className="">
      <div className="px-2 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Summary</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of tickets and their respective inventory, sold, available, and total sale gross.
            amount.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <CsvDownloadButton
            filename={`tickets-summary_${new Date().toISOString()}.csv`}
            data={data?.map((d) => {
              return {
                'Ticket Name': d.ticketName,
                Inventory: d.inventory,
                Sold: d.sold,
                Available: d.available,
                'On Hold': d.onHold,
                'Gross Sale (R$)': d.grossRevenue || 0
              };
            })}
            style={{ all: 'unset' }}>
            <div
              role="button"
              className="text-indigo-500 font-bold text-sm flex items-center gap-2">
              Export data
              <FontAwesomeIcon icon={faArrowDownToLine} />
            </div>
          </CsvDownloadButton>
        </div>
      </div>
      <div className="mt-4">
        <StatusLabelWidget />
      </div>

      {isLoading ? (
        <LoadingContainer />
      ) : (
        <Table<DashboardTicket>
          className="mt-6"
          data={data || []}
          rows={[
            {
              column: 'ticketName',
              columnName: 'Origin',
              className: 'font-semibold',
              render: (data) => {
                const isSoldOut = data.inventory - data.sold <= 0;
                const isOnHold = !isSoldOut && data.available <= 0;
                return (
                  <div className="flex gap-1 font-semibold items-center">
                    {data.ticketName}
                    <StatusLabel isOnHold={isOnHold} isSoldOut={isSoldOut} />
                  </div>
                );
              }
            },
            {
              column: 'inventory',
              columnName: 'Inventory'
            },
            {
              column: 'sold',
              columnName: 'Sold'
            },
            {
              column: 'available',
              columnName: 'Available'
            },
            {
              column: 'onHold',
              columnName: 'On Hold'
            },
            {
              column: 'grossRevenue',
              columnName: (
                <div className="flex gap-1 items-center">
                  Total Sales{' '}
                  <Tooltip title="Total of sales of tickets, journeys and accommodation not included in the total value">
                    <FontAwesomeIcon className="text-gray-500" icon={faCircleQuestion} />
                  </Tooltip>
                </div>
              ),
              render: (data) => priceToCurrency(data.grossRevenue, 'BRL')
            },
            {
              column: 'id',
              columnName: 'Actions',
              className: 'flex justify-end',
              render: (data) => (
                <div className="flex justify-end">
                  <a href="#" className="text-indigo-600 hover:text-indigo-900 font-semibold">
                    View<span className="sr-only">, {data.id}</span>
                  </a>
                </div>
              )
            }
          ]}
        />
      )}
    </div>
  );
};
