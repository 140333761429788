import { useMemo, useState } from 'react';

export const usePagination = <T>({ data }: { data: T[]; rowsPerPage?: number }) => {
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });

  const setPaginationPage = (page: number) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const onNextPage = () => {
    setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const onPreviousPage = () => {
    setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
  };

  const paginatedData = useMemo(
    () =>
      data?.slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage),
    [data, pagination.page, pagination.perPage]
  );

  return {
    paginatedData,
    onNextPage,
    onPreviousPage,
    setPaginationPage,
    pagination
  };
};
