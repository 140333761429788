import { IPaymentStatus } from 'types/payment';
import { BadgeColor } from 'src/@design-system/feedback/badge';
import { OrderStatusType } from 'types/order';

export const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

export const paymewntStatusBadge: Record<IPaymentStatus, BadgeColor> = {
  refunded: 'danger',
  failed: 'danger',
  canceled: 'danger',
  paid: 'success',
  pending: 'warning'
};

export const orderStatusBadge: Record<OrderStatusType, BadgeColor> = {
  cancelled: 'danger',
  confirmed: 'success',
  expired: 'warning',
  pending: 'indigo'
};
