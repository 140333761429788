import classNames from 'classnames';
import cn from 'classnames';
import { ReactNode, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from 'src/hooks/use-auth';

export const ItemSkeleton = () => (
  <div className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 animate-pulse w-52">
    <div className="h-2 bg-slate-200 rounded"></div>
  </div>
);

export const priceToCurrency = (price: number, currency: string, locale: string = 'en') =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 2
  }).format(price);

type SummaryItem = { key: string; name: string | ReactNode; value: string | ReactNode };

export const SalesSummaryWidget = ({
  data,
  isLoading
}: {
  data?: {
    totalSold: number;
    totalOnHold: number;
    totalAvailable: number;
    totalCharges: number;
    salesGrossRevenue: number;
  };
  isLoading: boolean;
}) => {
  const { user } = useAuth();
  const stats = useMemo(() => {
    const list = [
      {
        key: 'totalSold',
        name: 'Total Sold',
        value: isLoading ? <ItemSkeleton /> : data?.totalSold
      },
      {
        key: 'totalOnHold',
        name: 'Total On Hold',
        value: isLoading ? <ItemSkeleton /> : data?.totalOnHold
      },
      {
        key: 'totalAvailable',
        name: 'Total Available',
        value: isLoading ? <ItemSkeleton /> : data?.totalAvailable
      },
      {
        key: 'totalSaleGross',
        name: (
          <div className="flex gap-1 items-center">
            Total Sales
            <Tooltip title="Total of sales of all items">
              <FontAwesomeIcon className="text-gray-500" icon={faCircleQuestion} />
            </Tooltip>
          </div>
        ),
        value: isLoading ? <ItemSkeleton /> : priceToCurrency(data?.salesGrossRevenue || 0, 'BRL')
      }
    ] as SummaryItem[];

    return list;
  }, [
    data?.salesGrossRevenue,
    data?.totalAvailable,
    data?.totalCharges,
    data?.totalOnHold,
    data?.totalSold,
    isLoading,
    user?.isAdmin
  ]);

  return (
    <dl className={cn(`px-2 mx-auto grid gap-px divide-x divide-gray-200 pb-4 grid-cols-4`)}>
      {stats.map((stat) => (
        <div
          key={stat.key}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white py-10 pl-4 first:pl-0 ">
          <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
          <dd className={classNames('text-gray-700', 'text-xs font-medium')}></dd>
          <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
            {stat.value}
          </dd>
        </div>
      ))}
    </dl>
  );
};
