import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { Accommodation, AccommodationRoomType } from 'types/accommodation';
import { Alert, Button, FormControl, MenuItem, TextField } from '@mui/material';
import { useAccommRoomsStore } from 'src/pages/inventory/accommodation-rooms/hooks/use-accomm-rooms';
import { useQuery } from 'react-query';
import { API_ENDPOINT, apiFetch } from 'src/api/helper/fetch';
import { AddAccommPrice } from 'src/pages/inventory/accommodation-rooms/components/add-accomm-price/add-accomm-price';
import { useParams } from 'react-router';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoomCategory } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/use-auth';
import { ROLES } from 'src/guards/role-based-guard';
import { Page } from 'src/components/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { INVENTORY_PATHS } from 'src/paths';
import { PricesTable } from 'src/pages/inventory/accommodation-rooms/components/add-accomm-price/prices-table';

export type AccommRoomRequest = Exclude<AccommodationRoomType, 'accommodationName'>;

const AddNew = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid?: string }>();

  const canEdit = user?.role === ROLES.Admin;

  /**
   * Load accomm. room by uuid
   */
  const { isLoading: isFetching, data } = useQuery<AccommodationRoomType>(
    ['AccommodationRoomDetails', uuid],
    () => {
      return apiFetch(API_ENDPOINT.Inventory.AccommodationRooms).get({ uuid: String(uuid) });
    },
    { enabled: Boolean(uuid) }
  );

  const { data: accommList = [] } = useQuery<Accommodation[]>(['Accommodations'], () => {
    return apiFetch(API_ENDPOINT.Inventory.Accommodation).get();
  });

  const { isLoading, onSubmit } = useAccommRoomsStore((state) => ({
    onSubmit: state.onSubmit,
    isLoading: state.isLoading
  }));

  const form = useForm<AccommRoomRequest>({
    values: data,
    defaultValues: {
      uuid: crypto.randomUUID()
    }
  });

  const prices = form.watch('prices');

  const handleSubmit = useCallback(
    async (data: AccommodationRoomType) => {
      await onSubmit(data, uuid);
    },
    [onSubmit, uuid]
  );

  return (
    <Page
      description=""
      title="Add new accommodation room"
      isLoading={isLoading}
      cta={
        <button
          className="text-indigo-700 hover:text-indigo-500 text-sm flex gap-1 items-center"
          onClick={() => navigate(INVENTORY_PATHS.accommodation_rooms.list)}>
          <FontAwesomeIcon icon={faArrowLeft} />
          Go back to list
        </button>
      }>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={4}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                  <Typography variant="h6">Basic details</Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack spacing={3}>
                    <Controller
                      name="accommodationUUID"
                      control={form.control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <TextField disabled={!canEdit} select {...field} label="Accommodation">
                            <MenuItem key="none" value="" disabled>
                              Select an accommodation
                            </MenuItem>
                            {accommList.map((accomm) => (
                              <MenuItem key={accomm.uuid} value={accomm.uuid}>
                                {accomm.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      )}
                    />

                    <Controller
                      name="name"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          disabled={!canEdit}
                          variant="outlined"
                          label="Name"
                          InputLabelProps={{ shrink: !!field.value }}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="category"
                      control={form.control}
                      defaultValue={RoomCategory.singleRoom}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <TextField disabled={!canEdit} select {...field} label="Room Category">
                            {Object.values(RoomCategory)
                              .sort((a, b) => a.localeCompare(b))
                              .map((type) => (
                                <MenuItem key={type} value={type}>
                                  {t(`accommodation.roomCategories.${type}`)}
                                </MenuItem>
                              ))}
                          </TextField>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="capacity"
                      control={form.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          disabled={!canEdit}
                          type="number"
                          label="Room Capacity"
                          InputLabelProps={{ shrink: !!field.value }}
                          {...field}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                  <Typography variant="h6">Available rooms</Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack spacing={1}>
                    <Alert severity="warning">
                      Availability will determine the number of rooms that can be sold
                    </Alert>
                    <FormControl>
                      <Controller
                        name="availability"
                        control={form.control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            disabled={!canEdit}
                            color="secondary"
                            variant="outlined"
                            label="Quantity"
                            InputLabelProps={{ shrink: !!field.value }}
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                  <Typography variant="h6">Prices</Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack spacing={3}>
                    <Controller
                      name="prices"
                      control={form.control}
                      render={({ field }) => (
                        <AddAccommPrice
                          disabled={!canEdit}
                          onChange={(prices) => {
                            console.log('prices', prices);
                            field.onChange(prices);
                          }}
                          prices={data?.prices || []}
                        />
                      )}
                    />
                    <PricesTable
                      prices={prices || []}
                      onDeletePrice={(priceId) => {
                        form.setValue(
                          'prices',
                          prices.filter((p) => p.uuid !== priceId)
                        );
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Stack>
        <div className="pt-6 flex justify-end">
          <Button variant="contained" type="submit" disabled={!canEdit}>
            Submit
          </Button>
        </div>
      </form>
    </Page>
  );
};

export default AddNew;
