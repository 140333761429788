import * as React from 'react';
import { ChangeEvent, FC, useId } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { PriceTypeOptions } from 'src/constants';

type AccTypeSelecInputProps = {
  disabled?: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
};

export const PriceTypeOptionsSelector: FC<AccTypeSelecInputProps> = ({
  onChange,
  value,
  disabled
}) => {
  const id = useId();
  return (
    <FormControl>
      <FormLabel id={id}>Price Type</FormLabel>
      <RadioGroup row value={value} onChange={onChange} aria-labelledby={id}>
        <FormControlLabel
          disabled={disabled}
          value={PriceTypeOptions.PerPerson}
          control={<Radio size="small" />}
          label="Per person"
        />
        <FormControlLabel
          disabled={disabled}
          value={PriceTypeOptions.Total}
          control={<Radio size="small" />}
          label="Total"
        />
      </RadioGroup>
    </FormControl>
  );
};
